import React from 'react';
import { Form, Input, Icon } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';

class RegistrationForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // console.log("not err")
        // console.log(values)
        // console.log("props:")
        // console.log(this.props)
        // console.log('Received values of form: ', values);
        this.props.onAuth(
          values.userName,
          values.email,
          values.password,
          values.confirm
        );

        //console.log(this.props)
        //this.dealWithResponse()

      }
      else {
        //console.log("there is err")
        //console.log(err)
      }
    });
  };

  dealWithResponse(newProps) {
    if (newProps.loading === false) {
      //console.log("current error")
      //console.log(newProps.error)

      {/*
      if(newProps.error === undefined || newProps.error === null) {
        //console.log("push")
        this.props.history.push('/');
      }
      else {
        //console.log("have error, dont push")
      }
      */}
    }
    else {
       //console.log("dont push")
       //console.log(newProps.error)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    //console.log("received props")
    //console.log(newProps)

    this.dealWithResponse(newProps)
  }

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    const psw = form.getFieldValue('password')
    if (value && value !== psw ) {
      callback('Passwords are not the same!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };


  render() {
      const { getFieldDecorator } = this.props.form;
      //console.log("rendering signup")
      let errorMessage = null;
      if (this.props.error) {
        //console.log(this.props.error)
        //console.log("type:")
        //console.log(Object.prototype.toString.call(this.props.error))
        //console.log("keys:")
        for (var key in this.props.error) {
            //console.log(key)
            //console.log("error data:")
            //console.log(this.props.error[key])
            let msg = "Error. Try a different name."
            errorMessage = (
                <p>{msg}</p>
            );
        }

        if (this.props.error.response) {
            //console.log("error response data")
            //console.log(this.props.error.response.data);
            //console.log("items:")
            let msg = ""
            for (var key1 in this.props.error.response.data) {
                //console.log(this.props.error.response.data[key])
                msg = msg + this.props.error.response.data[key1]
            }
            //console.log("msg")
            //console.log(msg)
            errorMessage = (
                <p>{msg}</p>
            );
        }
        else {
          errorMessage = (
              <p>{this.props.error.message}</p>
          );
        }


      }

      //console.log("error message")
      //console.log(errorMessage)

      return (

        <div
        >


        <Form onSubmit={this.handleSubmit}
          style={{
            width:"50%",
            position: 'absolute', left: '50%',
            transform: 'translate(-50%)'
          }}
        >
        <br/>
        <br/>
        <h1>Ny bruger</h1>
        <br/>

        {errorMessage}

          <Form.Item>
              {getFieldDecorator('userName', {
                  rules: [{ required: true, message: 'Please input your username!' }],
              })(
                  <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Brugernavn" />
              )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{
                type: 'email', message: 'The input is not valid E-mail!',
              }, {
                required: false, message: 'Please input your E-mail!',
              }],
            })(
              <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email (optional)" />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{
                required: true, message: 'Please input your password!',
              }, {
                validator: this.validateToNextPassword,
              }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Adgangskode" />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('confirm', {
              rules: [{
                required: true, message: 'Please confirm your password!',
              }, {
                validator: this.compareToFirstPassword,
              }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Adgangskode" onBlur={this.handleConfirmBlur} />
            )}
          </Form.Item>

          <Form.Item className="horizontal-center3"
          style={{

          }}
          >


          <button
            type="button"
            style={{
              marginRight: 15,
              background: "#eaca79",
              color: "#384151",
            }}
            onClick={this.props.toggleShowSignup}
            className="btn"
          >
            Luk
          </button>

          <button
            type="submit"
            style={{
              background: "#384151",
              color: "white",
            }}
            className="btn"
          >
            Ny bruger
          </button>

          <br/>
          <br/>

          </Form.Item>

        </Form>

        </div>
      );
  }

}

const WrappedRegistrationForm = Form.create()(RegistrationForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, email, password1, password2) => dispatch(actions.authSignup(username, email, password1, password2))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);
