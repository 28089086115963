import React from 'react';
import { Form, Input, Icon, Button } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import * as actionTypes from '../store/actions/actionTypes';
import axios from 'axios';
import TalerumTopImage from './TalerumTopImage'

class ChangePasswordForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],

  };

  constructor(props) {
    super(props);
    this.state = {
      alreadyCheckedProfile: false,
      myToken: props.token,
      errorMessage: "",
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const oldpassword = values.oldpassword
        const new_password1 = values.password
        const new_password2 = values.confirm
        this.sendFormData(this.props.token, oldpassword, new_password1, new_password2)
      }
    });
  };

    sendFormData = (token, oldpassword, new_password1, new_password2) => {
      this.setState({
        errorMessage: "",
      })
        axios.defaults.headers = {
           "Accept": "application/json",
           "Content-Type": "application/json",
           'Authorization': "Token " + token
        }
        axios.post(actionTypes.API_URL + 'rest-auth/password/change/', {
          old_password: oldpassword,
          new_password1: new_password1,
          new_password2: new_password2,
        })
        .then(res => {
          //console.log(res);
          //console.log(res.data.detail)
          //console.log(res.status)
          if(res.status === 200) {
            //console.log("got 200")
            if(this.props.toggleClosePopup) {
              this.props.toggleShow()
            }
            else {
              this.props.history.push("/")
            }
          }
          else {
            this.setState({
              errorMessage: res.data.detail,
            })
          }
        })
        .catch(error => {
          //console.log("error")
          //console.log(error.response.data);
          let msg = ""
          for (var key in error.response.data) {
              //console.log(this.props.error.response.data[key])
              msg = msg + ' ' + error.response.data[key] + '\n\n'
          }
          msg = msg.replace(",", " ");
          //console.log("msg")
          //console.log(msg)
          this.setState({
            errorMessage: msg,
          })
        })

    }

  componentDidMount() {
    //console.log("ChangePasswordForm componentDidMount")
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    //console.log("receive props profile update form")
    //console.log(newProps.token)
    this.props = newProps
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    const psw = form.getFieldValue('password')
    if (value && value !== psw  ) {
      callback('Passwords are not the same!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  cancelPasswordChange = () => {
    if(this.props.toggleClosePopup) {
      this.props.toggleShow()
    }
    else {
      this.props.history.push("/")
    }
  }


  render() {
      const { getFieldDecorator } = this.props.form;


      return (
        <div>

    { this.props.isAuthenticated ?
    <div>

        <h1> Skift Adgangskode </h1>
        <p>
        {this.state.errorMessage}
        </p>
          <Form onSubmit={this.handleSubmit}
            style={{
              width:"60%",
              position: 'absolute', left: '50%',
              transform: 'translate(-50%)',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >

          <Form.Item>
            {getFieldDecorator('oldpassword', {
              rules: [{ required: true, message: 'Please input your current Password!' }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Current Password" />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{
                required: true, message: 'Please input your password!',
              }, {
                validator: this.validateToNextPassword,
              }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="New Password" />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('confirm', {
              rules: [{
                required: true, message: 'Please confirm your password!',
              }, {
                validator: this.compareToFirstPassword,
              }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="New Password Again" onBlur={this.handleConfirmBlur} />
            )}
          </Form.Item>

          <Form.Item className="horizontal-center3">

            <button
              type="button"
              style={{
                marginRight: 15,
                background: "#eaca79",
                color: "#384151",
              }}
              onClick={this.cancelPasswordChange}
              className="btn"
            >
              Luk
            </button>

            <button
              type="submit"
              className="btn"
              style={{
                background: "#384151",
                color: "white",
              }}
            >
              Gem
            </button>


          </Form.Item>



          </Form>


</div>

  :

    (null)
}
        </div>
      );
  }
}

const WrappedRegistrationForm = Form.create()(ChangePasswordForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        token: state.token === null ? null : state.token,
        isAuthenticated: state.token !== null,
    }
}

const mapDispatchToProps = dispatch => {
  //console.log("scene mapDispatchToProps:")
  return {
    hasProfile: () => dispatch(actions.hasProfile())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);
