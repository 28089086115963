import React from 'react';
import { Form, Input, Icon, Button } from 'antd';
import { connect } from 'react-redux';

import * as actions from '../store/actions/auth';
import * as actionTypes from '../store/actions/actionTypes';
import axios from 'axios';
import Select from 'antd/lib/select';
import TalerumTopImage from './TalerumTopImage'

class ProfileForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],

  };

  constructor(props) {
    super(props);
    //console.log("constructor props Profile:")
    //console.log(props)
    this.state = {
      valueGender: '',
      valueNiveau: '',
      valueHjemsted: '',
      alreadyCheckedProfile: false,
      genderList: [],
      niveauList: [],
      hjemstedList: [],
      myToken: props.token,

    }
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.handleChangeNiveau = this.handleChangeNiveau.bind(this);
    this.handleChangeHjemsted = this.handleChangeHjemsted.bind(this);

  }




  downloadData = (token) => {
    //console.log("downloadData0")
    //console.log(token)
        //console.log("newProps.token:")
        //console.log(token)

        //get minord lista
        //get mycategories lista
        axios.all(
          [
            axios({
              method: 'get',
              url: actionTypes.API_URL + 'api/gender',
              withCredentials: true,
              headers: {
                "Authorization": 'Token ' + token,
              }
            }),
            axios({
              method: 'get',
              url: actionTypes.API_URL + 'api/niveau',
              withCredentials: true,
              headers: {
                "Authorization": 'Token ' + token,
              }
            }),
            axios({
              method: 'get',
              url: actionTypes.API_URL + 'api/hjemsted',
              withCredentials: true,
              headers: {
                "Authorization": 'Token ' + token,
              }
            }),
            axios({
              method: 'get',
              url: actionTypes.API_URL + 'api/profileInfo',
              withCredentials: true,
              headers: {
                "Authorization": 'Token ' + token,
              }
            }),
          ]
        )
        .then(axios.spread((genderOptions, niveauOptions, hjemstedOptions, profileInfo) => {
          //console.log("results options")
          //console.log(genderOptions.data)
          //console.log(niveauOptions.data)
          //console.log(hjemstedOptions.data)
          //console.log("profileInfo:")
          //console.log(profileInfo.data)
          //console.log(profileInfo.data.genderId)
          //console.log(profileInfo.data.niveauId)
          //console.log(profileInfo.data.hjemstedId)
          //console.log(this.state.valueGender)
          //console.log(this.state.valueHjemsted)
          //console.log(this.state.valueNiveau)

          //console.log("profileInfo.data.gender:")
          //console.log(profileInfo.data.gender)

          this.setState({
            genderList: genderOptions.data,
            niveauList: niveauOptions.data,
            hjemstedList: hjemstedOptions.data,
            alreadyCheckedProfile: true,
            initialName: profileInfo.data.name,
            initialAge: profileInfo.data.age,
            initialGender: profileInfo.data.gender,
            initialNiveau: profileInfo.data.niveau,
            initialHjemsted: profileInfo.data.hjemsted,
            valueGender: profileInfo.data.genderId,
            valueHjemsted: profileInfo.data.hjemstedId,
            valueNiveau: profileInfo.data.niveauId,
          });
        }))
    }


    handleChangeGender = (value) => {
      //console.log("changed gender")
      //console.log(value)
      this.setState({ valueGender: value })
    }

    handleChangeNiveau = (value) => {
      //console.log("changed niveau")
      //console.log(value)
      this.setState({ valueNiveau: value })
    }

    handleChangeHjemsted = (value) => {
      //console.log("changed hjemsted")
      //console.log(value)
      this.setState({ valueHjemsted: value })
    }


    handleSubmit = e => {
      e.preventDefault();

      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          //console.log("this.state.valueGender")
          //console.log(this.state.valueGender)
          const g = this.state.genderList[this.state.valueGender-1].name
          const n = this.state.niveauList[this.state.valueNiveau-1].name
          const h = this.state.hjemstedList[this.state.valueHjemsted-1].name
          this.sendFormData(this.props.token, values.name, values.age, g, n, h)
        }
      });
    };

    sendFormData = (token, name, age, gender, niveau, hjemsted) => {
      //console.log("send form data")
      //console.log("gnh")
      //console.log(name)
      //console.log(age)
      //console.log(gender)
      //console.log(niveau)
      //console.log(hjemsted)

      axios.defaults.headers = {
         "Accept": "application/json",
         "Content-Type": "application/json",
         'Authorization': "Token " + token
      }

      const params = {name: name, age: age, gender: gender, niveau: niveau, hjemsted: hjemsted}
      //axios.put('http://127.0.0.1:8000/api/updateProfile', {params: {name: 'name2'}}).then(res => {

      axios.put(actionTypes.API_URL + 'api/updateProfile',{
        data: params
      }).then(res => {
        //console.log("response profile created: ")
        //console.log(res.data)
        this.props.hasProfile()
        //this.props.getLanguage()

        this.props.getProfileCountry()

        if(this.props.toggleClosePopup) {
          this.props.toggleShow()
        }
        else {
          this.props.history.push('/');
        }
      })
    }

  componentDidMount() {
    console.log("toggleClosePopup")
    console.log(this.props.toggleClosePopup)
    //this.checkIfProfileExists()
    //console.log("ProfileForm componentDidMount")

    //this must be done, otherwise data will be overridden by the information coming from the downloadData method
    //we only want to get the current profile information once
    if (this.state.alreadyCheckedProfile) {
      //console.log("alreadyCheckedProfile")
    }
    else {
      //console.log("going to get choices profile form")
      if(this.state.myToken === null || this.state.myToken === undefined) {

      }
      else {
        //console.log("will get choices for gender, niveau and hjemsted")
        //console.log("calling downloaddata didmount")
        this.downloadData(this.props.token)
      }
    }
  }



  UNSAFE_componentWillReceiveProps(newProps) {

    //console.log("receive props profile update form")
    //console.log(newProps.token)
    this.props = newProps

    //this must be done, otherwise data will be overridden by the information coming from the downloadData method
    //we only want to get the current profile information once
    if (this.state.alreadyCheckedProfile) {

    }
    else {
      this.downloadData(newProps.token)
    }
  }


  cancelProfile = () => {
    if(this.props.toggleClosePopup) {
      //ProfileForm is being shown inside the game
      //and is being updated
      this.props.toggleShow()
    }
    else {
      //ProfileForm is being shown upon registration
      this.props.history.push("/")
    }
  }


  render() {
      const { getFieldDecorator } = this.props.form;

      //console.log("this.state.initialName")
      //console.log(this.state.initialName)
      // let buttonText = "Create Profile"
      // if (this.state.initialName !== "") {
      //   buttonText = "Save Profile"
      // }

      //console.log("this.state.genderList")
      //console.log(this.state.genderList)
      //console.log(this.props)

      return (
        <div>


  { this.props.isAuthenticated ?

    <div style={{
      position: "absolute",
      width:"100%",
      height:"100%",
    }}>

    { this.props.toggleClosePopup == false ?
      <TalerumTopImage/>
      :
      <h1> Profil </h1>
    }

          <Form onSubmit={this.handleSubmit}
            style={{
              width:"60%",
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%)',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >

            <Form.Item hasFeedback
            >
                {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Skriv dit navn!' }],
                    initialValue: this.state.initialName,

                })(

                    <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Navn" />
                )}


            </Form.Item>

            <Form.Item hasFeedback>
                {getFieldDecorator('age', {
                    rules: [{ required: true, message: 'Skriv din alder!' }],
                    initialValue: this.state.initialAge,

                })(
                    <Input type="number" min="1" step="1" prefix={<Icon type="number" min="0" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Alder" />
                )}
            </Form.Item>




            <Form.Item
              hasFeedback
            >
              {getFieldDecorator('select-1', {
                rules: [{ required: true, message: 'Vælg dit køn!' }],
                initialValue: this.state.initialGender,

              })(
              <Select
                placeholder="Vælg dit køn"
                onChange={this.handleChangeGender}
              >
                {this.state.genderList.map(item => (
                  <Select.Option
                      key={item.pk}
                      value={item.pk} >{item.name}</Select.Option>
                ))}

              </Select>,
              )}
            </Form.Item>



            <Form.Item hasFeedback>
              {getFieldDecorator('select-2', {
                rules: [{ required: true, message: 'Vælg dit niveau!' }],
                initialValue: this.state.initialNiveau,
              })(
              <Select placeholder="Vælg dit niveau"
                onChange={this.handleChangeNiveau}
              >

                {this.state.niveauList.map(item => (
                  <Select.Option key={item.pk} value={item.pk} >{item.name}</Select.Option>
                ))}

              </Select>,
              )}
            </Form.Item>

            <Form.Item hasFeedback>
              {getFieldDecorator('select-3', {
                rules: [{ required: true, message: 'Vælg dit hjemland!' }],
                initialValue: this.state.initialHjemsted,
              })(
              <Select placeholder="Vælg dit hjemland"
                onChange={this.handleChangeHjemsted}
              >
                {this.state.hjemstedList.map(item => (
                  <Select.Option key={item.pk} value={item.pk} >{item.name}</Select.Option>
                ))}

              </Select>,
              )}
            </Form.Item>

            <Form.Item className="horizontal-center3">





              { this.state.initialName === "" || this.state.initialName === undefined ?

                <button
                  type="submit"
                  className="btn"
                  style={{
                    background: "#384151",
                    color: "white",
                  }}
                >
                  Gem
                </button>


            :

                <div>


                <button
                  type="button"
                  style={{
                    marginRight: 15,
                    background: "#eaca79",
                    color: "#384151",
                  }}
                  onClick={this.cancelProfile}
                  className="btn"
                >
                  Luk
                </button>

                <button
                  type="submit"
                  className="btn"
                  style={{
                    background: "#384151",
                    color: "white",
                  }}
                >
                  Gem
                </button>

                </div>

              }



            </Form.Item>


          </Form>

</div>

  :

    (null)

}


        </div>
      );

  }

}

/*
  <Button type="primary" htmlType="submit">
    Create Profile
  </Button>
style={{
  position: 'absolute', left: '50%',
  transform: 'translate(-50%)',
  alignItems: 'center',
  justifyContent: 'center',

}}
*/
//<Redirect to="/login/"/>
const WrappedRegistrationForm = Form.create()(ProfileForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        token: state.token === null ? null : state.token,
        isAuthenticated: state.token !== null,
        profileCountry: state.profileCountry,

    }
}



const mapDispatchToProps = dispatch => {
  //console.log("scene mapDispatchToProps:")
  return {
    getProfileCountry: () => dispatch(actions.updateProfileCountry()),
    hasProfile: () => dispatch(actions.hasProfile()),
    //getLanguage: () => dispatch(actions.getLanguage())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);
