import React from 'react';
// import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
// import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';

export default function ForgotPasswordNavLink() {

  return (
    <NavLink
        style={{fontSize: "18px", }}
        to='/'> Log ind
    </NavLink>

  );
}
