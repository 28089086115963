import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import '../css/myCss.css'
import axios from 'axios';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import * as actionTypes from '../store/actions/actionTypes';
import Table from 'react-bootstrap/Table'

class Statistics extends React.Component {

  state = {
    finalList: [],
  }

  getFinishedInstancesForUser = (token) => {
    //console.log("token getFinishedInstancesForUser: ")
    //console.log(token)

    if(token === null || token === undefined) {

    }
    else {
      //console.log("sending0..")
      //console.log(this.props.token)
      axios.defaults.headers = {
         "Accept": "application/json",
         "Content-Type": "application/json",
         'Authorization': "Token " + this.props.token
      }

     axios.get(actionTypes.API_URL + 'api/finishedInstancesForUser').then(res => {
         let data = res.data
         console.log("data finishedInstancesForUser:")
         console.log(data)

         this.setState({
           finalList: data,
         })
         // if(isMounted) {
         //   this.setState({
         //     beginningText: bt
         //   })
         // }
     })
    }
  }


  componentDidMount(){
    //console.log("Statistics componentDidMount")
    //console.log(this.props)
    //console.log(this.props.currentScene.id)
    if(this.props.isAuthenticated) {
      this.getFinishedInstancesForUser(this.props.token)
    }

  }
  componentWillUnmount() {
    // console.log("Statistics componentWillUnMount")
  }






  render() {
    return (
     <div style={{
       width:"100%",
       height:"100%",
     }}>

     { this.state.finalList.length === 0 ?
       <div>
       <h4>Du er ikke færdig med Talerum</h4>
       </div>

       :

       <Table striped bordered hover>
         <thead>
           <tr>
             <th>Username</th>
             <th>Time</th>
             <th>Score</th>
           </tr>
         </thead>
         <tbody>
           {this.state.finalList.map(item => (
             <tr key={item.pk}>
               <td>{item.username}</td>
               <td>{item.timestamp}</td>
               <td>{item.score}</td>
             </tr>
           ))}
         </tbody>
       </Table>

     }

     </div>



      );

  }
}


const mapStateToProps = state => {
  //console.log("scene mapStateToProps")
  //let st = state.token === null ? null : state.token
  //console.log(st)
  return {
    isAuthenticated: state.token !== null,
    token: state.token === null ? null : state.token,
  }
}

const mapDispatchToProps = dispatch => {
  //console.log("scene mapDispatchToProps:")
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
