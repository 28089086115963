import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';

import MenuLoginScreenLeftPart from './MenuLoginScreenLeftPart';
import MenuLoginScreenRightPart from './MenuLoginScreenRightPart';

import '../css/blur.css'
import Login from './Login'
import ForsidaInformationTabs from './ForsidaInformationTabs'
import OmTalerumTabs from './OmTalerumTabs'
import Signup from './Signup'
import ResetPassword from './ResetPassword'

import flagTalerum from '../img/icons/iconsButtons/flagTalerum3.png'
import startButton from '../img/icons/iconsButtons/startButton.png'
import settings from '../img/icons/iconsButtons/iconSettings.png'

import mainMapImage from '../img/map130819/mapUpphaf.png'

//import danmark from '../img/denmark.png'
//import faroeIslands from '../img/faroeIslands.png'
//import greenland from '../img/greenland.png'
//import island from '../img/iceland.png'

import danmark from '../img/languages/DK.png'
import faroeIslands from '../img/languages/FO.png'
import greenland from '../img/languages/GL.png'
import island from '../img/languages/IS.png'

class LoginScreen extends React.Component {

	state = {
	   userHasProfile: null,
		 userTrophies: [],
		 showInformation: false,
     showLogin: false,
     countryId: 1,
		 showOmTalerum: false,
	};

  componentWillUnmount() {

  }

	componentDidMount() {

	}

  countryClicked = (id) => {
    this.setState({
      countryId: id,
    })
  }


  UNSAFE_componentWillReceiveProps(newProps) {
    /*this.setState({
      userTrophies: newProps.trophies,
    })*/
	}

  toLogin = () => {

    this.setState({
			showOmTalerum: false,
			showInformation: false,
      showLogin: true,
    })
  }

	toOmTalerum = () => {
		this.setState({
			showOmTalerum: true,
			showInformation: false,
      showLogin: false,
		})
	}


	toInfo = () => {
		this.setState({
			showOmTalerum: false,
			showInformation: true,
      showLogin: false,
		})
	}

  toggleShowSignup = () => {
		let t = this.state.showSignup

		this.setState({
			showSignup: !t,
		})

    this.toggleShowLogin()

  }

  toggleShowForgotPassword = () => {
    let t = this.state.showForgotPassword
    this.setState({
      showForgotPassword: !t,
    })

    this.toggleShowLogin()
  }

  toggleShowLogin = () => {
		let t = this.state.showLogin
		this.setState({
			showLogin: !t,
		})
	}

  toggleShowInformation = () => {
		let t = this.state.showInformation
		this.setState({
			showInformation: !t,
		})
	}

	toggleShowOmTalerum = () => {
		let t = this.state.showOmTalerum
		this.setState({
			showOmTalerum: !t
		})
	}


	toProfile = () => {
		this.props.history.push("/profile/")
	}

  renderShowLogin(item) {
		if (!item) {
			return null;
		}

		return(


			<div
				className="all-center3"
				style={{
					position:"absolute",
					backgroundColor:"white",
					height:"70%",
					width:"90%",
					overflow: "auto",
					overflowY: "scroll",
					zIndex:10052,
					cursor: "auto",
					marginTop: "10px",
					marginBottom: "10px",

				}}
				>

				<div
					style={{
						cursor: "pointer",
					}}
				>

        <Login
          toggleShowLogin={this.toggleShowLogin}
          toggleShowSignup={this.toggleShowSignup}
          toggleShowForgotPassword={this.toggleShowForgotPassword}
        />

				</div>
              <br/>
              <br/>
			</div>
		)
	}


  renderShowSignup(item) {
    if (!item) {
      return null;
    }

    return(


      <div
        className="all-center3"
        style={{
          position:"absolute",
          backgroundColor:"white",
          height:"70%",
          width:"90%",
          overflow: "auto",
          overflowY: "scroll",
          zIndex:10052,
          cursor: "auto",
          marginTop: "10px",
          marginBottom: "10px",

        }}
        >

        <div
        style={{
          cursor: "pointer",
        }}

        >

        <Signup
          toggleShowSignup={this.toggleShowSignup}
        />
        </div>


      </div>
    )
  }


    renderShowForgotPassword(item) {
      if (!item) {
        return null;
      }

      return(


        <div
          className="all-center3"
          style={{
            position:"absolute",
            backgroundColor:"white",
            height:"70%",
            width:"90%",
            overflow: "auto",
            overflowY: "scroll",
            zIndex:10052,
            cursor: "auto",
            marginTop: "10px",
            marginBottom: "10px",

          }}
          >

          <div
          style={{
            cursor: "pointer",
          }}

          >

          <ResetPassword
            toggleShowForgotPassword={this.toggleShowForgotPassword}
          />
          </div>


        </div>
      )
    }



	renderShowInformation(item) {
		if (!item) {
			return null;
		}

		return(

			<div
				className="all-center3"
				style={{
					position:"absolute",
					backgroundColor:"white",
					width:"90%",
					overflow: "auto",
					overflowY: "scroll",
					zIndex:10052,
					cursor: "pointer",
					marginTop: "10px",
					marginBottom: "10px",

				}}
				>


		      <img onClick={() => this.countryClicked(1)}
								style={{
									marginLeft:"10px",
									marginRight:"10px",
									opacity: this.state.countryId === 1 ? 0.4 : 1.0
								}}

								alt=""
								src={danmark}
								width="40px"
								height= "30px"
								/>

					<img onClick={() => this.countryClicked(4)}
						   style={{
								 marginLeft:"10px",
								 marginRight:"10px",
								 opacity: this.state.countryId === 4 ? 0.4 : 1.0
							 }}
							 alt="" src={island} width="40px" height= "30px"/>

					<img onClick={() => this.countryClicked(2)}
							 style={{
								 marginLeft:"10px",
								 marginRight:"10px",
								 opacity: this.state.countryId === 2 ? 0.4 : 1.0
							 }}
							 alt="" src={faroeIslands} width="40px" height= "30px"/>

					<img onClick={() => this.countryClicked(3)}
							 style={{
								 marginLeft:"10px",
								 marginRight:"10px",
								 opacity: this.state.countryId === 3 ? 0.4 : 1.0
							 }} alt="" src={greenland} width="40px" height= "30px"/>


				<div
				style={{
					cursor: "pointer",
				}}

				onClick={this.toggleShowInformation}
				>
				<ForsidaInformationTabs
					countryId={this.state.countryId}
				/>
				</div>
			</div>
		)
	}



		renderShowOmTalerum(item) {
			if (!item) {
				return null;
			}

			return(

				<div
					className="all-center3"
					style={{
						position:"absolute",
						backgroundColor:"white",
						height:"70%",
						width:"90%",
						overflow: "auto",
						overflowY: "scroll",
						zIndex:10052,
						marginTop: "10px",
						marginBottom: "10px",

					}}
					>


	      <img onClick={() => this.countryClicked(1)}
							style={{
								marginLeft:"10px",
								marginRight:"10px",
								opacity: this.state.countryId === 1 ? 0.4 : 1.0
							}}

							alt=""
							src={danmark}
							width="40px"
							height= "30px"
							/>

				<img onClick={() => this.countryClicked(4)}
					   style={{
							 marginLeft:"10px",
							 marginRight:"10px",
							 opacity: this.state.countryId === 4 ? 0.4 : 1.0
						 }}
						 alt="" src={island} width="40px" height= "30px"/>

				<img onClick={() => this.countryClicked(2)}
						 style={{
							 marginLeft:"10px",
							 marginRight:"10px",
							 opacity: this.state.countryId === 2 ? 0.4 : 1.0
						 }}
						 alt="" src={faroeIslands} width="40px" height= "30px"/>

				<img onClick={() => this.countryClicked(3)}
						 style={{
							 marginLeft:"10px",
							 marginRight:"10px",
							 opacity: this.state.countryId === 3 ? 0.4 : 1.0
						 }} alt="" src={greenland} width="40px" height= "30px"/>


					<div
					style={{
						width: "100%",
						height: "100%",
						cursor: "pointer",
					}}

					onClick={this.toggleShowOmTalerum}
					>

					<OmTalerumTabs
						countryId={this.state.countryId}
					/>
					</div>
				</div>
			)
		}

	render() {

    return (
      <div style={{
       width:"100%",
       height:"100%",
       display: "flex",
       flexFlow: "column",
       backgroundColor:"#333942",
      }}>
      <br/>
      {/* div height 65 */}
      <div
       style={{
        height:"65px",
        width:"100%",
        backgroundColor:"#333942",
       }}
      >
        {/* element1 */}
        <div
           style={{
             width: "200px",
             height:"100%",
             float:"right",
             display: "inlineBlock",
             margin:"0",
             padding:"0",
             verticalAlign: "top",
            }}
            >

						<MenuLoginScreenRightPart
							props={this.props}
							toOmTalerum={this.toOmTalerum}
						/>

        </div>
        {/* element1 ended */}

        {/* element2 */}
        <div style={{
          width: "200px",
          height:"100%",
          float: "left",
          display: "inlineBlock",
          margin:"0",
          padding:"0",
          verticalAlign: "top",
        }}
        >

					<MenuLoginScreenLeftPart
						props={this.props}
						toLogin={this.toLogin}
						toInfo={this.toInfo}
					/>

          </div>
          {/* todo: gameResetButton={this.props.props.gameInstanceFinished} element2 ended */}

          {/* element3 */}
          <div style={{
            width: 'calc(100% - 400px)',
            height:"100%",
            float: "left",
            display: "inlineBlock",
            margin:"0",
            padding:"0",
            verticalAlign: "top",
          }}
          >
					<p className="h1"
            style={{
              textAlign:"center",
              width:"100%",
              float:"left",
              display: "inlineBlock",
              color:"white",
            }}
          >
					Computerspillet Talerum
					</p>

          </div>
          {/* element3 ended */}

      </div>
      {/* div height 65px of total ended */}




      {/*div height rest of total */}

       <div  className="bg-image"
       style={{
         width:"100%",
         flex: 1,
         overflow: "hidden",
         backgroundImage: `url(${mainMapImage})`,
         backgroundRepeat: "no-repeat",
         backgroundSize: "100% 100%",
       }}>

       {/*{this.renderDirections(this.state.showDirections)}*/}

       </div>

	 			 <div
	 					style={{
	 						position:"absolute",
							left: 'calc(50% - 273px)',
	 						bottom: 'calc(50% + 168px)',
	 						//transform: "translate(-50%, -50%)",
	 					}}
	 			 >

	 			 <div className="imageWrapper"
	 			 style={{
	 			 	position: "absolute",
	 			 	width: "546px",
	 			 	height: "337px",
	 			 }}>
	 			 	<img
						src={flagTalerum}
						alt=""
	 			 		style={{
	 			 			position: "relative",
	 			 			zIndex: 1,
	 			 			width: "546px",
	 			 			height: "337px",
	 			 		}}
	 			 	 />

	 				 <button
					 	className="btn"
	 			 		style={{
	 						width:"50px",
	 						height:"50px",
	 						position: "absolute",
	 						left: "248px",
	 						bottom: "50px",
	 						zIndex: 10,
	 						padding: 0,
	 			 			//display: "inline",
	     				backgroundImage: 'url(' + startButton + ')',
	 						backgroundSize: "100%",
	 						backgroundSize: "50px auto",
	 						backgroundRepeat: "no-repeat",
	 			 		}}
	 			 		onClick={() => this.toLogin()}
	 			 	>

	 				</button>

	 			 </div>
	 			</div>


       {this.renderShowSignup(this.state.showSignup)}
       {this.renderShowLogin(this.state.showLogin)}
       {this.renderShowForgotPassword(this.state.showForgotPassword)}

       {this.renderShowInformation(this.state.showInformation)}
			 {this.renderShowOmTalerum(this.state.showOmTalerum)}




      </div>

    );
	}
}

/*


<div style={{position: "absolute",}}>
 <img src={flagTalerum} alt=""
	style={{
		position: "absolute",
		zIndex: 5,
		width: "545px",
		height: "337px",
	}}
	/>
	<button className="btn btn-lg btn-success"
		style={{
			position: "absolute",
			zIndex: 6,
			width: "300px",
			height: "100px",
			display: "inline",

		}}
		onClick={() => this.toLogin()}
	>

		<img src={startButton} alt=""
			style={{
				position: "relative",
				zIndex: 10,
				width: "200px",
				height: "200px",
			}}
		 />

	</button>
</div>



			 <div
			 		style={{
						position:"absolute",
						bottom:"10%",
						//backgroundColor:"white",

						left: "50%",
						transform: "translate(-50%, -50%)",
						color: "white",
						//padding: "10px 10px 10px 10px",
						//borderRadius: "5px",
						textAlign: "center",

					}}
			 >

				 <img src={flagTalerum} alt=""
				 	style={{
				 		position: "absolute",
				 		zIndex: 5,
				 		width: "545px",
				 		height: "337px",
				 	}}
				  />


					<button className="btn btn-lg btn-success"
						style={{
							position: "absolute",
							zIndex: 6,
							width: "300px",
							height: "100px",
							display: "inline",
						}}
						onClick={() => this.toLogin()}
					>

						<img src={startButton} alt=""
							style={{
								position: "relative",
								zIndex: 10,
								width: "200px",
								height: "200px",
							}}
						 />

					</button>


			 </div>
*/
/*

<h1
style={{
	whiteSpace: "nowrap",
	color: "white",
	position: "absolute",
	width: "300px",
	height: "100px",
	//top: "50%",
	//left: "50%",
	//transform: "translate(-50%, -50%)",
}}
> Kom i gang </h1>

 <div class="imageWrapper" style={{
	 position: "relative",
	 width: "195px",
	 height: "195px",
 }}>
   <img src={flagTalerum} alt=""
		 style={{
			 position: "relative",
			 zIndex: 1,
			 width: "200px",
			 height: "200px",
		 }}
	  />
   <img src={iconInfo} alt=""
	 			style={{
					width:"100px",
					height:"100px",
					position: "absolute",
					left: "80px",
					top: "80px",
					zIndex: 10,
				}}
				/>
 </div>


 <div
 		style={{
			position:"absolute",
			bottom:"10%",
			backgroundColor:"white",

			left: "50%",
			transform: "translate(-50%, -50%)",
			color: "white",
			padding: "10px 10px 10px 10px",
			borderRadius: "5px",
			textAlign: "center",

		}}
 >
	<button className="btn btn-lg btn-success"
		style={{
			width: "300px",
			height: "100px",
			display: "inline",
		}}
		onClick={() => this.toLogin()}
	>
		<h1
		style={{
			whiteSpace: "nowrap",
			color: "white",
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
		}}
		> Kom i gang </h1>
	</button>
 </div>*/

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password) => dispatch(actions.authLogin(username, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
