import React from 'react';

const vejledning1 = [
]

const vejledning2 = [
  "Læg mærke til, at Talerum fungerer bedst, når du bruger Chrome som din browser.",
  "Gev gætur, at Talerum riggar best, tá ið tú brúkar Chrome sum kaga.",
  "Chrome browserigalugu Taleumip ingerlanera ajunnginnerusarpoq.",
  "Athugaðu að tölvuleikurinn Talerum virkar best þegar þú notar Chrome-vafrann.",
]

const vejledning3 = [
  "Computerspillet Talerum begynder med, at du ankommer til Kastrup lufthavn i København, hvor din værtsfamilie tager imod dig. Mange vil gerne kommunikere med dig og stiller dig derfor nogle spørgsmål, som du skal besvare på dansk. Spillet går ud på at klare alle situationerne i Talerum med så mange point som muligt. Du kan få hjælp via online-ordbøger og en særlig hjælpefunktion. Du kan spille Talerum flere gange og se, om du kan opnå  flere point. Spillet er aldrig ens, og der er mange forskellige måder, du kan svare på. Du kan altid tage en pause og begynde igen, hvor du var.",

  "Spælið byrjar við, at tú kemur til floghavnina í Keypmannahavn, har vertsfólkini taka ímóti tær. Tey og onnur vilja fegin práta við teg og seta tær tí nakrar spurningar, sum tú skalt svara á donskum. Svara helst við heilum setningum, ikki bara ja og nei. Dugir tú ikki at svara, kanst tú fáa hjálp. Ætlanin við spælinum er at loysa allar støður í Talerum við so mongum stigum sum gjørligt. Tú kanst spæla Talerum fleiri ferðir og fáa fleiri stig, tað er ongantíð eins, og tú kanst svara ógviliga ymist. Tú kanst fáa tær steðg og byrja aftur, haðan tú vart komin.",

  "Spilip aallartinnerani Københavnimi mittarfimmut tikissaatit, tassanilu ilaqutarsiassatit naapissavatit. Ilaqutarsiavit allallu oqaloqatigerusuppaatsit taamaattumillu apeqquteqaqattaarput, apeqqutillu taakku qallunaatut akissavatit. Spilimi anguniagaavoq Talerumimi suliassat naammassinerini pointeqarnerpaajuniarnissaq. Nittartakkani ordbogit ikiortiffissarlu atorlugit ikiortissinnaavutit. Pointitit amerlisinnaanersut paasiniarlugu Talerum arlaleriarlutit spilersinnaavat. Spilili allanngorartarpoq, akissutigisinnaasatillu assigiinngitsorpassuupput. Unikkallartarsinnaavutit killiffinniillu aallarteqqittarsinnaallutit.",

  "Tölvuleikurinn Talerum hefst þegar þú hefur lent á Kastrupflugvelli í Kaupmannahöfn, en þar tekur danska fjölskyldan, sem þú átt að búa hjá, á móti þér. Margir vilja ræða við þig og spyrja þig spurninga. Þú svarar á dönsku og helst með heilum setningum. Leikurinn gengur út á að komast í gegnum allar stöðvarnar með sem flest stig. Þú getur fengið hjálp með því að slá upp í orðabók á milli íslensku og dönsku eða með því að nýta þér hjálpartakkann. Þú getur spilað Talerum aftur og aftur og náð þér í stöðugt fleiri stig. Leikurinn er aldrei eins og þú getur svarað á mismunandi hátt. Þú getur alltaf gert hlé og hafið leikinn að nýju, þar sem þú hættir síðast."
]

export default function SimpleTabs(props) {

  return (
    <div className=""
    style={{
      //backgroundColor: "darkgray",
      width: "100%",
      height: "100%",
    }}
    >

      <h2 style={{fontWeight:"bold"}}> {vejledning2[props.countryId-1]} </h2>

      <h3>
        {vejledning3[props.countryId-1]}
      </h3>


    </div>
  );
}
