import React from 'react';
import { Form, Icon, Spin } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import TalerumTopImage from './TalerumTopImage'
import axios from 'axios'
import * as actionTypes from '../store/actions/actionTypes';
import LoginNavLink from './LoginNavLink'
import SignupNavLink from './SignupNavLink'

const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;


class ConfirmPasswordForm extends React.Component {

  state = {
    message: "",
  }

  handleSubmit = e => {
    let key = window.location.href
    let verify = "verify-email/"
    var n = key.indexOf(verify);
    //console.log("n")
    //console.log(n)
    var res = key.substring(n + verify.length, key.length);
    //console.log("res")
    //console.log(res)
    let url = actionTypes.API_URL + verify +  res
    //console.log("verify-email/")
    //let lastChar = key.substring(key.length-1, key.length)
    //if(lastChar != "/") {
    //  url += "/"
    //}
    //console.log("lastChar")
    //console.log(lastChar)

    //console.log(url)
    axios.defaults.headers = {
       "Accept": "application/json",
       "Content-Type": "application/json",
    }

//       'X-CSRF-TOKEN': this.state.token
//       'Authorization': "Token " + this.state.token
//        axios.post(actionTypes.API_URL + 'rest-auth/password/change/', {

    /*
    axios.post(url, {
      key: res,
    })
    */
    axios.get(url)
    .then(response => {
      //console.log("response:")
      //console.log(response)
      this.setState({
        message: "Your email is now verified!",
      })
    }).catch((err) => {
      //console.log("err reset")
      //console.log(err.response)
      this.setState({
        message: "Not able to verify your email!",
      })
    });

  };


  componentDidMount() {
    this.setState({
      message: "",
    })
    this.handleSubmit()
  }



  render() {

    //const { getFieldDecorator } = this.props.form;
    return (
        <div
        style={{
          width:"100%",
          height:"100%",
        }}
        >
            {
                this.props.loading ?

                <Spin indicator={antIcon} />

                :

<div
style={{
  width:"100%",
  height:"100%",
}}
>

<TalerumTopImage/>

                <Form onSubmit={this.handleSubmit} className="login-form"

                  style={{
                    width:"50%",
                    position: 'absolute', left: '50%',
                    transform: 'translate(-50%)'
                  }}
                >

                    <FormItem className="horizontal-center3"
                    style={{

                    }}
                    >

                    {this.state.message}

                    <br/>
                    <br/>

                    <LoginNavLink/>
                    <br/>
                    <SignupNavLink/>

                    </FormItem>



                </Form>
        </div>


            }
      </div>
    );
  }
}

const WrappedConfirmPasswordForm = Form.create()(ConfirmPasswordForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        token: state.token,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password) => dispatch(actions.authLogin(username, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedConfirmPasswordForm);
