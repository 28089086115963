
import React from 'react';
import { Form, Icon, Input, Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import TalerumTopImage from './TalerumTopImage'
import axios from 'axios'
import * as actionTypes from '../store/actions/actionTypes';

const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;


class PasswordRecoveryForm extends React.Component {

  state = {
    errorMessage: "",
    uid: null,
    userToken: null,
    message: "",
  }

  componentDidMount() {
    // console.log("recovery did mount")
    // console.log(this.props)
    // console.log("params:")
    // console.log(this.props.match.params)

    if("uid" in this.props.match.params && "token" in this.props.match.params) {
      // console.log("uid, token:")
      // console.log(this.props.match.params["uid"])
      // console.log(this.props.match.params["token"])

      this.setState({
          uid: this.props.match.params["uid"],
          token: this.props.match.params["token"],
          message: "",
      })

    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // console.log("recovery receive props")
    // console.log(newProps)

  }


    handleSubmit = e => {
      e.preventDefault();

      const uid = this.state.uid
      const token = this.state.token

      if(uid == null || token == null) {

      }
      else {

        this.props.form.validateFieldsAndScroll((err, values) => {
          if(!err) {
            const new_password1 = values.password
            const new_password2 = values.confirm
            this.sendFormData(uid, token, new_password1, new_password2)
          }

        });
      }

    };


  sendFormData = (uid, token, new_password1, new_password2) => {


    axios.post(actionTypes.API_URL + 'rest-auth/password/reset/confirm/', {
      uid: uid,
      token: token,
      new_password1: new_password1,
      new_password2: new_password2,
    })
    .then(response => {
      // console.log("response:")
      // console.log(response)

      this.setState({
        message: "A new password has been set. Please go to the Login page."
      })


    }).catch((err) => {
      // console.log("err reset")
      // console.log(err.response)

      this.setState({
        message: "Unable to set new password"
      })

    });

  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    const psw = form.getFieldValue('password')
    if (value && value !== psw ) {
      callback('Passwords are not the same!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };


  render() {

    const { getFieldDecorator } = this.props.form;
    return (
        <div
        style={{
          width:"100%",
          height:"100%",
        }}
        >
            {
                this.props.loading ?

                <Spin indicator={antIcon} />

                :

<div
style={{
  width:"100%",
  height:"100%",
}}
>

<TalerumTopImage/>

                <Form onSubmit={this.handleSubmit} className="login-form"

                  style={{
                    width:"50%",
                    position: 'absolute', left: '50%',
                    transform: 'translate(-50%)'
                  }}
                >


                <p>Please choose a new password.</p>



                    <Form.Item>
                      {getFieldDecorator('password', {
                        rules: [{
                          required: true, message: 'Please input your password!',
                        }, {
                          validator: this.validateToNextPassword,
                        }],
                      })(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                      )}
                    </Form.Item>

                    <Form.Item>
                      {getFieldDecorator('confirm', {
                        rules: [{
                          required: true, message: 'Please confirm your password!',
                        }, {
                          validator: this.compareToFirstPassword,
                        }],
                      })(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password Again" onBlur={this.handleConfirmBlur} />
                      )}
                    </Form.Item>


                    <FormItem className="horizontal-center3"
                    style={{

                    }}
                    >

                    {this.state.message}

                    <Button  type="primary" htmlType="submit" style={{marginRight: 0,}}>
                        Submit
                    </Button>


                    <br/>
                    <br/>
                    <NavLink
                        style={{marginRight: '10px'}}
                        to='/'> Login
                    </NavLink>

                    Or

                    <NavLink
                        style={{marginRight: '10px'}}
                        to='/signup/'> signup
                    </NavLink>


                    </FormItem>



                </Form>
        </div>


            }
      </div>
    );
  }
}

const WrappedPasswordRecoveryForm = Form.create()(PasswordRecoveryForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password) => dispatch(actions.authLogin(username, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedPasswordRecoveryForm);
