import React from 'react';
//import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import tipButton from '../img/tipYellow.png'
import trophyButton from '../img/trophyGold.png'
import microphoneButton from '../img/microphoneYellow.png'
import highlighterButton from '../img/marker1.png'
import menuButton from '../img/menuYellow3.png'
//import settingsButton from '../img/wheel.png'
import settingsButton from '../img/icons/iconsButtons/iconSettings.png'
import dictionaryButton from '../img/icons/iconsButtons/dictionaryButton.png'
import emptyImage from '../img/icons/iconsButtons/emptyImage.png'

const vejledning1 = [
  "1. Spillets gang",
  "1. Gongdin í spælinum",
  "1. Spilip ingerlanera",
  "1. Gangur leiksins"
]

const vejledning2 = [
  "N.B. Talerum fungerer bedst, når du bruger Chrome som din browser.",
  "Gev gætur: Talerum riggar best, tá ið tú brúkar Chrome sum kaga.",
  "Chrome browserigalugu Talerumip ingerlanera ajunnginnerusarpoq.",
  "Athugaðu að Talerum virkar best þegar þú notar Chrome-vafrann.",
]

const vejledning3 = [
  "Du kan både navigere via kortet på forsiden og via  knapperne i hver scene. Når du er færdig med en scene, får du et trofæ. I menuen øverst til venstre kan du se, hvilke scener du er færdig med, og hvilke du endnu har til gode. Spillet slutter, når du har fået alle trofæer.",
  "Tú kanst bæði stýra eftir kortinum  og eftir valmyndini ovast vinstrumegin. Tá ið ein leikmynd er liðug, fært tú steyp. Í ovara høgra horni sært tú, hvørjar leikmyndir tú hevur klárað, og hvørjar eru eftir. Spælið endar, tá ið tú hevur fingið øll steypini.",
  "Nunap assinga atorlugu toortagarluunniit atorlugu sumiissusersisinnaavutit. Talerumimi spilimik naammassisaqaraangavit ajugaanermik uppernarsaammik pisassaatit. Saamiup tungaani suut naammassisimanerlugit suullu suli naammassisimannginnerlugit takusinnaavatit. Ajugaanermik uppernarsaatit tamakkerlugit katersorukkit spili naammassissaaq.",
  "Þú stjórnar ferðinni með aðstoð kortsins á forsíðunni og með því að klikka á valmöguleikana í hverri senu. Þú færð bikar þegar þú hefur lokið við hverja senu. Á yfirlitinu, efst til vinstri á skjámyndinni, getur þú fylgst með hvaða senum þú hefur lokið og hvaða senur þú átt eftir. Leiknum lýkur þegar þú hefur fengið alla bikarana.",
]


const vejledning4 = []
/*  "og her kan du se, hvilke scener du er færdig med og hvilke du endnu har til gode. Spillet slutter, når du har fået alle trofæer.",
  "",
  "tamakkerlugit katersorukkit spili naammassissaaq.",
  "og hér getur þú fyglst með því, hvaða stöðvar þú hefur lokið við. Leiknum lýkur, þegar þú hefur fengið alla bikarana.",
]*/

const hjælp1 = [
  "2. Hjælp",
  "2. Hjálp",
  "2. Ikiortinneq",
  "2. Hjálp",
]

const hjælp2 = [
  "Hvis du ikke ved, hvad du skal svare, eller hvis du mangler danske ord, kan du trykke på hjælpeknappen, og du vil få forslag til, hvordan du kan svare. NB! Du mister point, hvis du bare skriver præcis de samme forslag, som hjælpen giver dig. Så tænk dig om, og skriv med dine egne ord, og helst hele sætninger.",
  "Veitst tú ikki, hvat tú skalt svara, ella vanta donsk orð, kanst tú trýsta á hjálparknappin GG! Tú missir stig, um tú bara skrivar júst tey somu uppskotini, sum hjálpin gevur tær. So hugsa teg væl um, skriva við tínum egnu orðum og skriva helst heilar setningar.",
  "Qanoq akissanerlutit nalugukku, qallunaatulluunniit oqaatsinik amigaateqaruit, ikiortinniarlutit toortagaq toorsinnaavat, qanoq akisinnaanersutit siunnersuutinik taava tunisissaatit. Mianersuussassaq! Siunnersuutigineqartut ilaaqqinnaarlugit allakkukkit pointinik annaasaqassaatit. Taamaattumik eqqarsaatigilluaqqaaruk, oqaaseqatigiit ilinnut tulluussarlugit allagit.",
  "Ef þú ert í vandræðum með að svara, eða þig vantar danskt orð, getur þú ýtt á hjálpartakkann og þú færð tillögur um hvernig hægt er að svara. Athugaðu að þú missir stig ef þú skrifar nákvæmlega það sama og hjálpin kemur með. Hugsaðu þig því um og veldu að skrifa svörin með þínum eigin orðum og helst heilum setningum.",
]

const ordabok1 = [
  "3. Ordbog",
  "3. Orðabók",
  "3. Ordbogi",
  "3. Orðabók",
]

const ordabok2 = [
  "Slå gerne op i ordbogen, hvis du ikke kan forstå eller komme i tanke om et dansk ord. Du mister ikke point, når du bruger ordbogen.",
  "Slá upp í orðabókini, um tú ikki skilir ella kemur fram á eitt danskt orð. Tú missir ikki stig, tá ið tú brúkar orðabók. ",
  "Qallunaatut oqaatsinik nalornileruit ordbogi atorsinnaavat. Ordbogi atorukku pointinik annaasaqarnavianngilatit.",
  "Sláðu upp í orðabókinni ef þú ekki skilur eða manst ekki eftir réttu orði á dönsku. Þú missir ekki stig þótt þú notir orðabók.",
]

const point1 = [
  "4. Dine point",
  "4. Úrslitini",
  "4. Angusatit",
  "4. Stigin þín"
]

const point2 = [
  "Her kan du se dine point, når du har gennemført spillet. Du kan også ændre dit kodeord eller starte spillet helt forfra.",
  "Her kanst tú hava eftirlit við, hvussu nógv stig, tú hevur fingið, broyta loyniorðið  ella fara undir spælið av nýggjum.",
  "Uani pointitit takusinnaavatit. Passwordit allanngortissinnaavat spililuunniit aallaqqaataaneersinnaavat.",
  "Hér getur þú fylgst með stigunum þínum. Þú getur líka breytt lykilorðinu þínu eða hafið leikinn alveg upp á nýtt."
]


const hor1 = [
  "5. Hør hvordan din tekst lyder på dansk",
  "5. Hoyr, hvussu teksturin ljóðar á donskum",
  "5. Qallunaatut allatat qanoq nipeqarnersoq tusaajuk",
  "5. Heyrðu hvernig textinn þinn hljómar á dönsku",
]

const hor2 = [
  "Hvis du markerer et ord eller en tekst, kan du høre teksten med Talerums stemme. Den lyder ikke altid 100 % perfekt, men er oftest ganske tæt på dansk udtale.",
  "Um tú frámerkir eitt orð ella ein tekst, verður tað lisið upp við røddini á Talerum. Hon ljóðar ikki altíð 100 % fullkomin, men er oftast rættiliga nær donskum framburði.",
  "Oqaaseq oqaaseqatigiilluunniit markererukkit Talerummip nipaanik atuarneqassapput. Ajunngeqqissaartumik nipeqartuaannanngilaq, qallunaatulli oqartarnermut qanittumik oqartarluni.",
  "Ef þú auðkennir orð eða texta, heyrir þú hann með rödd Talerums. Svarið hljómar ekki 100% eins og Dani væri að tala, en er þó oftast ansi nálægt dönskum framburði."
]


const udtal1 = [
  "6. Tal selv og lyt til din udtale",
  "6. Lurta eftir tínum egna framburði ",
  "6. Illit oqalunnerit tusarnaaruk",
  "6. Talaðu og hlustaðu á framburð þinn"
]

const udtal2 = [
  "Hvis du vil høre, hvordan du selv taler dansk, kan du trykke på mikrofonen i øverste højre hjørne. Tryk på den røde prik for at optage. Tryk på den sorte firkant for at stoppe. Den sorte linje bevæger sig, når du begynder at tale. Tryk på > for at lytte til din optagelse.",
  "Vilt tú hoyra, hvussu tú sjálv/ur tosar danskt, kanst tú trýsta á mikrofonina í ovara høgra horni. Trýst á reyða prikkin fyri at taka upp. Trýst á svarta fýrakantin fyri at steðga. Svarta linjan flytur seg, tá ið tú byrjar at tosa. Trýst á> fyri at lurta eftir upptøkuni.",
  "Qallunaatut oqalukkaangavit qanoq nipeqarnerlutit tusaarusukkukku teqeqqumi talerperlermi qullermi mikrofoni toorsinnaavat. Igalaasap qulaani toorneq aappalaartoq toorlugu immiussisinnaavutit. Immiussineq unitsinniarlugu kipparissoq qernertoq tooruk. Oqalullutit aallartikkuit titarneq qernertoq aallassaaq. Immiussat tusaaniarlugu > tooruk.",
  "Ef þú vilt heyra hvernig þú talar dönsku getur þú ýtt á upptökutækið í horninu efst til hægri. Ýttu á rauða hringinn til að taka upp og svarta ferninginn til að stoppa. Rautt strik birtist efst í glugganum þegar upptaka er í gangi. Svarta línan hreyfist þegar þú byrjar að tala. Ýttu á > til að hlusta á upptökuna.",
]

const udtal3 = [
  "Vær opmærksom på, at du skal give Chrome adgang til din mikrofon for at kunne bruge optagelsesfunktionen.",
  "Gev gætur, at tú mást geva Chrome atgongd til mikrofonina fyri at kunna brúka upptøkufunkuna.",
  "Maluginiaruk immiussisinnaassaguit Chromip mikrofonimut atuisinnaanera akuerissagakku. Illit oqalunnerit tusarnaaruk. ",
  "Athugaðu að þú verður að gefa Chrome aðgang að upptökutæknu til þess að geta tekið upp.",
]

export default function SimpleTabs(props) {
  //const classes = useStyles();
  /*
  const countryClicked = (id) => {

    props.countryClicked(id)
  }
  <div
   style={{
   flexShrink: 0,
  }}
  >
  */

  return (
    <div
      className=""
      style={{
        //backgroundColor: "darkgray",
      }}
    >

    <div
     style={{
       display: "flex",
     }}
    >

    <div style={{
      width: "120px",
      display: "flex",
    }}>
      <img style={{
        width: "50px",
        height: "50px",
        flexGrow: 1,
        marginRight: 10,
      }}
        alt=""
        src={menuButton}
      />

      <img style={{
        width: "50px",
        height: "50px",
        flexGrow: 1,
        marginRight: 10,
      }}
        alt=""
        visibility="hidden"
        src={trophyButton}
      />

    </div>


       <div
         style={{
           display: "inline",
           textAlign: "left",
         }}
       >
         <h3
           style={{fontWeight:"bold"}}
         > {vejledning1[props.countryId-1]}
         </h3>

         <h3>
           {vejledning2[props.countryId-1]}
         </h3>

         <h3>
           {vejledning3[props.countryId-1]}
         </h3>

         <h3>
           {vejledning4[props.countryId-1]}
         </h3>


       </div>

     </div>


     <div
      style={{
      display: "flex",
     }}
     >

     <div style={{
       width: "120px",
       display: "flex",
     }}>

       <img style={{
         width: "50px",
         height: "50px",
         flexGrow: 1,
         marginRight: 10,
       }}
         alt=""
         //visibility="hidden"
         src={emptyImage}
       />

       <img style={{
         width: "50px",
         height: "50px",
         flexGrow: 1,
         marginRight: 10,
       }}
         alt=""
         src={tipButton}
       />

     </div>



        <div
          style={{
            display: "inline",
            textAlign: "left",
          }}
        >
          <h3 style={{fontWeight:"bold"}}>
            {hjælp1[props.countryId-1]}
          </h3>

          <h3>
            {hjælp2[props.countryId-1]}
          </h3>

        </div>
      </div>

      //
      <div
        style={{
        display: "flex",
       }}
      >

        <div style={{
          width: "120px",
          display: "flex",
        }}>

          <img style={{
            width: "50px",
            height: "50px",
            flexGrow: 1,
            marginRight: 10,
          }}
            alt=""
            //visibility="hidden"
            src={emptyImage}
          />

          <img style={{
            width: "50px",
            height: "50px",
            flexGrow: 1,
            marginRight: 10,
          }}
            alt=""
            src={settingsButton}
          />

        </div>

        <div
          style={{
            display: "inline",
            textAlign: "left",
          }}
        >

        <h3 style={{fontWeight:"bold"}}>
          {point1[props.countryId-1]}
        </h3>

        <h3>
          {point2[props.countryId-1]}
        </h3>

        </div>
      </div>

      //
      <div
        style={{
        display: "flex",
       }}
      >

        <div style={{
          width: "120px",
          display: "flex",
        }}>

          <img style={{
            width: "50px",
            height: "50px",
            flexGrow: 1,
            marginRight: 10,
          }}
            alt=""
            //visibility="hidden"
            src={emptyImage}
          />

          <img style={{
            width: "50px",
            height: "50px",
            flexGrow: 1,
            marginRight: 10,
          }}
            alt=""
            src={dictionaryButton}
          />

        </div>

        <div
          style={{
            display: "inline",
            textAlign: "left",
          }}
        >

        <h3 style={{fontWeight:"bold"}}>
          {ordabok1[props.countryId-1]}
        </h3>

        <h3>
          {ordabok2[props.countryId-1]}
        </h3>

        </div>
      </div>


      //
      <div
        style={{
        display: "flex",
       }}
      >

      <div style={{
        width: "120px",
        display: "flex",
      }}>

        <img style={{
          width: "50px",
          height: "50px",
          flexGrow: 1,
          marginRight: 10,
        }}
          alt=""
          //visibility="hidden"
          src={emptyImage}
        />

        <img style={{
          width: "50px",
          height: "50px",
          flexGrow: 1,
          marginRight: 10,
        }}
          alt=""
          src={highlighterButton}
        />

      </div>



      <div
        style={{
          display: "inline",
          textAlign: "left",
        }}
      >
        <h3 style={{fontWeight:"bold"}}>
          {hor1[props.countryId-1]}
        </h3>

        <h3>
          {hor2[props.countryId-1]}
        </h3>

      </div>
    </div>


      //
      <div
        style={{
        display: "flex",
       }}
      >
        <div style={{
          width: "120px",
          display: "flex",
        }}>
          <img style={{
            width: "50px",
            height: "50px",
            flexGrow: 1,
            marginRight: 10,
          }}
            alt=""
            //visibility="hidden"
            src={emptyImage}
          />

          <img style={{
            width: "50px",
            height: "50px",
            flexGrow: 1,
            marginRight: 10,
          }}
            alt=""
            src={microphoneButton}
          />
        </div>


          <div
            style={{
              display: "inline",
              textAlign: "left",
            }}
          >

            <h3 style={{fontWeight:"bold"}}> {udtal1[props.countryId-1]}
            </h3>

            <h3>
              {udtal2[props.countryId-1]}
            </h3>

            <h3 style={{fontWeight:"bold"}}> {udtal3[props.countryId-1]}
            </h3>

          </div>
        </div>


    </div>
  );
}

/*
<img style={{marginLeft:"10px", marginRight:"10px"}} alt="" src={highlighterButton} width="40px" height= "40px"/>

<img style={{marginLeft:"10px", marginRight:"10px"}} alt="" src={tipButton} width="40px" height= "40px"/>
<img style={{marginLeft:"10px", marginRight:"10px"}} alt="" src={settingsButton} width="40px" height= "40px"/>
const eleven1 = [
  "1. Kom i gang",
  "1. Kom í gongd",
  "1. Aallartigit",
  "1. Að hefja leikinn"
]

const eleven2 = [
  "Før du begynder, skal du oprette et brugernavn og et password, og du skal taste nogle oplysninger ind i din profil. Derefter ser du et kort over København. Start spillet ved at trykke på Kastrup Lufthavn og gå derefter til familien Skov. Du kan til enhver tid afbryde spillet. Du kan altid genoptage spillet, hvor du stoppede, ved at indtaste dit brugernavn og password.",
  "Áðrenn tú byrjar, skalt tú stovna brúkaranavn og loyniorð, og tú skalt skriva nakrar upplýsingar inn í vangamyndina. Eftir tað sært tú eitt kort yvir Keypmannahavn. Far í gongd við spælið við at trýsta á Kastrup Lufthavn og far síðan til húskið Skov. Tú kanst, nær tú vilt, steðga spælið. Tú kanst altíð byrja spælið aftur, har tú steðgaði, við at skriva brúkaranavn og loyniorð.",
  "Aallartinnginninni brugernavnimik passwordimillu pilersitseqqaassaatit, profilinnilu paasissutissanik allattuillutit. Taamaalioreeruit København nunap assingani takussavat, tassani unittarfiit assigiinngitsut takusinnaavatit. Spili aallartiguk Kastrup Lufthavn toorlugu, tassanilu oqaloqatigiinneq naammassigukku ilaqutariit Skovikkut ornissavatit. Qaqugukkulluunniit spili unitsissinnaavat, uniffigisanniillu aallarteqqissinnaavat brugernavnit passwordillu atorlugit. ",
  "Áður en þú byrjar, þarftu að stofna notendanafn og ákveða lykilorð. Þú þarft einnig að setja inn fáeinar upplýsingar um þig. Þegar því er lokið birtist kort af Kaupmannahöfn með nokkrum stöðvum. Byrjaðu leikinn með því að klikka á Kastrup Lufthavn og eftir að hafa leyst verkefnin þar, ferðu á heimili Skov fjölskyldunnar. Þú getur alltaf gert hlé á leiknum og hafið hann að nýju, þar sem þú hættir síðast, með því að setja inn notendanafn þitt og lykilorð. ",

]

const komIGang1 = [
  "2. Navigation",
  "2. Stýring",
  "2. Sumiissusersiorneq",
  "2. Gangur leiksins"
]

const komIGang2 = [
  "Du kan både navigere via kortet og via menuen øverst til venstre. I øverste højre hjørne ",
  "Tú kanst bæði stýra eftir kortinum og eftir valmyndini ovast vinstrumegin. Í ovara høgra horni ",
  "Nunap assinga atorlugu menuluunniit saamiup tungaani qullerpaamiittoq atorlugu sumiissusersisinnaavutit. Talerpiup tungaani qullerpaami ",
  "Þú getur farið á milli stöðva með aðstoð kortsins eða með því að fara í valmyndina uppi í horninu til vinstri. Í horninu efst til hægri "
]

const komIGang3 = [
  " kan du se, hvilke scener du har gennemført . Spillet slutter, når du har fået alle trofæer.",
  " sært tú, hvørjar leikmyndir tú hevur klárað. Spælið endar, tá ið tú hevur fingið allar steyparnar.",
  " teqeqqumi suut naammassisimanerlugit takusinnaavatit. Ajugaanermi uppernarsaatit tamakkerlugit katersorukkit spili naammassissaaq.",
  " getur þú séð hvaða stöðvum þú hefur lokið. Leiknum lýkur þegar þú hefur fengið alla bikarana.  ",
]

const dialog1 = [
  "3. Dialog",
  "3. Samrøða",
  "3. Oqaloqatigiinneq",
  "3. Samtal"
]

const dialog2 = [
  "Når du får et spørgsmål, skriver du svaret i den hvide boks. Du kan enten trykke på send-knappen eller returtasten. Hvis programmet ikke kan forstå dit svar, vil det spørge igen. Hvis det stadig ikke forstår, vil det stille dig et lettere spørgsmål eller gå videre.",
  "Tá ið tú fært ein spurning, skrivar tú svarið í tann hvíta kassan. Tú kanst annaðhvørt trýsta á send-knøttin ella aftur-knøttin. Dugir forritið ikki at skilja svarið, spyr tað uppaftur. Skilur tað framvegis ikki, setur tað tær ein lættari spurning ella heldur fram.",
  "Aperineqaraangavit akissutit kipparissumut qaqortumut allattassavat. Toortagaq sendimik allaqqasoq toorlugu imaluunniit tastaturimi enter toorlugu akissutit nassiussinnaavat. Spilip akissutit paasisinnaanngikkuniuk apereqqissaaq. Akissutit suli paasinngikkuniuk apeqqut ajornannginninngorlugu aperinngikkuni ingerlaqqiinnassaaq.",
  "Þegar þú færð spurningu, skrifar þú svarið í hvíta kassann. Til að senda svarið ýtir þú annað hvort á takkann senda eða tilbaka. Ef Talerum skilur ekki svarið, færðu spurninguna aftur. Ef nýtt svar skilst ekki heldur, færðu léttari spurningu eða þér er leyft að halda áfram. "
]

const bogstaver1 = [
  "4. Danske bogstaver",
  "4. Danskir bókstavir",
  "4. Qallunaatut naqinnerit",
  "4. Danskir stafir"
]

const bogstaver2 = [
  "Du kan hurtigt komme til de danske bogstaver ved at bruge de tre knapper med æ, ø og å.",
  "Tú sleppur skjótt til teir donsku bókstavirnar við at nýta teir tríggjar knapparnar við æ, ø og å.",
  "Qallunaatut naqinnerit tastaturinniinngippata toortakkat æ, ø, å atorsinnaavatit.",
  "Þú getur auðveldlega náð í danska bókstafi með því að nota takkana fyrir æ, ø og å. "
]

const hjælp1 = [
  "5. Hjælp",
  "5. Hjálp",
  "5. Ikiortinneq",
  "5. Hjálp"
]

const hjælp2 = [
  "Hvis du ikke ved, hvad du skal svare, eller mangler danske ord, kan du trykke på hjælpeknappen ",
  "Veitst tú ikki, hvat tú skalt svara, ella vanta donsk orð, kant tú trýsta á hjálparknappin",
  "Qanoq akissanerlutit nalugukku, qallunaatulluunniit oqaatsinik amigaateqaruit, ikiortinniarlutit toortagaq toorsinnaavat",
  "Ef þú ert í vandræðum með að svara, eða þig vantar danskt orð, getur þú ýtt á hjálpartakkann"
]

const hjælp3 = [
  ". NB! Du mister point, hvis du bare skriver præcis de samme forslag som hjælpen giver dig. Så tænk dig om, og skriv med dine egne ord, og skriv helst hele sætninger.",
  ". GG! Tú missir stig, um tú bara skrivar júst tey somu uppskotini, sum hjálpin gevur tær. So hugsa teg væl um, skriva við tínum egnu orðum og skriva helst heilar setningar.",
  ". ",
  ". "
]

const indstillinger1 = [
  "6. Dine resultater",
  "6. Úrslitini",
  "6. Angusatit",
  "6. Stigin þín"
]

const indstillinger2 = [
  "I menuen ",
  "Á valmyndini ",
  "Menumi ",
  "Á yfirlitinu "
]

const indstillinger3 = [
  "kan du se din profil, overvåge dine point, ændre dit kodeord eller starte spillet helt forfra.",
  "sært tú tína vangamynd, her kanst tú hava eftirlit við, hvussu nógv stig, tú hevur fingið, broyta loyniorðið  ella fara undir spælið av nýggjum.",
  "profilit takusinnaavat, pointitit nakkutigisinnaavatit, passwordit allanngortissinnaavat spililuunniit aallaqqaataaneersinnaavat.",
  "getur þú séð hvernig þú ert skráður og fylgst með stigunum þínum. Þú getur líka breytt lykilorðinu þínu eða hafið leikinn alveg upp á nýtt."
]

const læst1 = [
  "7. Hør hvordan din tekst lyder på dansk",
  "7. Hoyr, hvussu tekstur ljóðar á donskum",
  "7. Qallunaatut allatat qanoq nipeqarnersoq tusaajuk",
  "7. Heyrðu hvernig textinn þinn hljómar á dönsku"
]

const læst2 = [
  "Hvis du markerer et ord eller en tekst, bliver det læst op med Talerums stemme. Den lyder ikke altid 100 % perfekt, men er oftest ganske tæt på dansk udtale. Ved hjælp af stemmen kan du blive bedre til at tale dansk.",
  "Um tú frámerkir eitt orð ella ein tekst, verður tað lisið upp við røddini á Talerum. Hon ljóðar ikki altíð 100 % fullkomin, men er oftast rættiliga nær donskum framburði. Við røddini til hjálpar kanst tú læra teg betur at tosa danskt.",
  "Oqaaseq oqaaseqatigiilluunniit markererukkit Talerummip nipaanik atuarneqassapput. Ajunngeqqissaartumik nipeqartuaannanngilaq, qallunaatulli oqartarnermut qanittumik oqartarluni. Nipi taanna iluaqutigalugu qallunaatut oqalunnermik ilikkariartorsinnaavutit.  ",
  "Ef þú auðkennir orð eða texta, verður hann lesinn upp með rödd Talerums. Svarið hljómar ekki 100% eins og Dani væri að tala, en er þó oftast mjög nálægt dönskum framburði. Ef þú nýtir þér þessa tækni verður þú smám saman betri í að tala dönsku. "
]

const lyt1 = [
  "8. Lyt til din udtale",
  "8. Lurta eftir tínum egna framburði ",
  "8. Illit oqalunnerit tusarnaaruk",
  "8. Hlustaðu á framburð þinn"
]

const lyt2 = [
  "Hvis du vil høre, hvordan du selv taler dansk, kan du trykke på ",
  "Vilt tú hoyra, hvussu tú sjálv/ur tosar danskt, kanst tú trýsta á ",
  "Qallunaatut oqalukkaangavit qanoq nipeqarnerlutit tusaarusukkukku talerpiup qulaani teqeqqumi ",
  "Ef þú vilt heyra hvernig þú talar dönsku, getur þú ýtt á ",
]

const lyt3 = [
  "i øverste højre hjørne. Tryk på mikrofonen til venstre for at lave en optagelse af din danske tekst. Du kan se en rød prik øverst i vinduet, når optagelsen er i gang. Tryk på den højre mikrofon for at stoppe og aflyt den derefter på afspilleren Du kan også downloade din optagelse og sende den til din lærer.",
  "í ovara høgra horni. Trýst á mikrofonina vinstrumegin at gera eina upptøku av tínum danska teksti. Tú sært ein reyðan prikk ovast í vindeyganum, tá ið upptøkan er í gongd. Trýst á høgru mikrofon at steðga og lurta síðan eftir henni í avspælaranum. Tú kanst eisini taka upptøkuna niður og senda læraranum hana.",
  "toorsinnaavat. Igalaasap qulaani toorneq aappalaartoq immiussinerpit nalaani takusinnaavat. Immiussineq unitsinniarlugu mikrofoni talerperleq tooruk, tusarnaarutitaatigullu tusarnaarlugu. Immiussat downloadersinnaavat ilinniartitsisunnullu nassiullugu.",
  "í horninu efst til hægri . Rautt strik birtist efst í glugganum þegar upptaka er í gangi. Ýttu á táknið fyrir upptökutæki til hægri til þess að hætta upptöku og hlustaðu síðan á upptökuna í spilaranum. Þú getur líka hlaðið niður upptökunni og sent kennaranum þínum. "
]

const about1 = [
  "Om Talerum",
  "Um Talerum",
  "Talerum pillugu",
  "Um Talerum"
]

const about2 = [
  "Talerum er et interaktivt computerspil, hvor elever med andre modersmål end dansk kan blive bedre til dansk ved at bruge det i hverdagssituationer.",

  "Talerum er eitt samvirkið telduspæl, har næmingar við øðrum móðurmáli enn donskum kunnu læra seg betur danskt við at nýta tað í gerandisstøðum." ,

  "Talerum computerspiliuvoq akissutinnut akisartoq atuartut qallunaatut oqaaseqanngitsut ulluinnarni pisartut aallaavigalugit qallunaatut ilinniarfigisinnaasaat.",

  "Talerum er gagnvirkur tölvuleikur sem gerir nemendum með annað móðurmál en dönsku auðveldara að nota málið í daglegum aðstæðum.",
]

const about3 = [
  "Programmet stiller spørgsmål og reagerer på elevernes svar, og det tilpasser sig deres niveau. Brugerne kan skrive fri tekst og formulere deres svar forholdsvis frit inden for Talerums univers. Spillet består af et kort over København, som indeholder en række steder og scener, hvor man skal kommunikere på dansk i dialog. Man kan bevæge sig rundt, som man vil, men man opdager hurtigt, at visse scener skal afsluttes, inden man kan blive færdig med andre.",

  "Forritið spyr spurningar og hoyrir svarini frá næmingunum, og tað lagar seg eftir teirra búningarstigi. Brúkararnir kunnu skriva frían tekst og orða svarið rættiliga frítt innan fyri heimin hjá Talerum. Spælið hevur eitt kort, sum vísir Keypmannahavn, og sum hevur mong støð og mangar leikmyndir, har prátað verður á donskum í samrøðu. Tú kanst flyta teg, sum tú vilt, men tú varnast skjótt, at ávísar leikmyndir skulu gerast lidnar, áðrenn tú kanst halda fram við øðrum.",

  "Programi apeqquteqartarpoq atuartullu akissutaannut qisuariartarluni, atuartullu pisinnaasaannut tulluussartarluni. Atuisut allakkusutaminnik allassinnaapput Talerumillu silarsuaani akissutigerusutatik aallaavigalugit allassinnaallutik. Spilimi sumiiffik tassaavoq København assigiinngitsunik ornitassartalik assigiinngitsunillu pisuusaartitanik misiliiffissartalik, taakkunanilu qallunaatut oqaloqatigiinneq allaqatigiinnikkut pisariaqarpoq. Ornikkusutat aallaavigalugit angalaartoqarsinnaavoq, sukkasuumilli paasilissavat pisuusaartitsinerit ilai naammasseqqaartinnagit allanik naammassisaqarsinnaaneq ajornartarmat.",

  "Talerum spyr spurninga og bregst við svörum nemenda og það lagar sig að getustigi þeirra. Spilararnir geta skrifað eiginn texta og orðað svör sín tiltölulega frjálst innan ramma Talerums. Sýnt er kort af Kaupmannahöfn þar sem er að finna nokkra staði og aðstæður, þar sem spilararnir þurfa að taka þátt í samtali á dönsku. Hægt er að spila leikinn í þeirri röð sem maður kýs, en spilarinn áttar sig fljótt á því, að ekki er hægt að klára ákveðnar stöðvar án þess að vera búinn að ljúka við aðrar.",
]

const about4 = [
  "Historien er, at eleven kommer til København og skal bo hos en værtsfamilie, familien Skov. Familien Skov sætter eleven til at løse en række opgaver, men eleven kan også selv finde frem til nye samtaler.",
  "Søgan er tann, at næmingurin/spælarin kemur til Keypmannahavnar og skal búgva hjá einum vertshúski, húskinum Skov. Húskið Skov setur næmingin/spælaran/gestin at loysa fleiri uppgávur, men næmingurin kann eisini sjálvur finna sær nýggjar samrøður.",
  "Pisuusaartitaq tassaavoq spilertoq Københavnimut pisoq ilaqutarsiaqartussaasorlu, ilaqutariit Skovikkut ilaqutarsiaralugit. Ilaqutariit Skovikkut spilertumut suliassiisassapput, spilertorli nammineq aamma oqaluuserisassanik nassaartorsinnaasassalluni.",
  "Atburðarásin er þannig, að spilarinn kemur til Kaupmannahafnar og á að búa á heimili gestgjafa sinna, Skov fjölskyldunnar. Gestgjafarnir fela spilaranum að leysa ýmis verkefni, en hann getur líka sjálfur bryddað upp á nýju samtali. ",
]

const about5 = [
  "Talerum tilbyder også talesyntese og lydoptagelser. Ved hjælp af talesyntese kan eleven få læst ord og sætninger op blot ved at markere dem. Man skal dog være opmærksom på, at talesyntese på dansk stadig har visse begrænsninger. Eleverne kan også indtale ord og sætninger for at høre, hvordan deres dansk lyder.",
  "Talerum hevur eisini talusyntesu og ljóðupptøkur. Við talusyntesu til hjálpar kann næmingurin fáa orð og setningar lisin upp bara við at frámerkja tey. Tó verður havt í huga, at talusyntesa á donskum framvegis hevur ávísar avmarkingar. Næmingarnir kunnu eisini tala orð og setningar inn fyri at hoyra, hvussu teirra danska ljóðar. ",
  "Talerumimi atuffassissuteqarpoq aamma nipinik immiussissuteqarluni. Atuartup oqaatsit oqaaseqatigiillu markererlugit atuffassissut atuartissinnaavaa. Ilisimaneqassaarli atuffassissutip qallunaatoorsinnaanera aamma killeqarmat. Atuartut qallunaatut qanoq oqalloritsiginertik tusaaniarlugu oqaatsinik oqaaseqatigiinnillu aamma immiussisinnaapput.",
  "Talerum býður upp á hljóðupptökur og notkun talgervils. Með því að auðkenna texta geta spilararnir látið talgervilinn lesa upp orð og setningar á dönsku. Hafa ber í huga að framburður talgervilsins er ekki í fullu samræmi við danskan framburð. Þá geta nemendur hugað að eigin framburði á dönsku með því að taka upp framburð sinn á orðum eða setningum.",
]

const ground1 = [
  "Programmets baggrund",

  "Grundin undir forritinum/spælinum",

  "Talerumip tunuliaqutaa",

  "Tilurð Talerums",
]


const ground2 = [
  "Spillet er udviklet for færøske, grønlandske og islandske elever og har til formål at fremme deres produktive brug af dansk. Programmet er udviklet på Islands Universitet i samarbejde med dansklærere og elever i  færøske, grønlandske og islandske skoler. Leder af projektet er professor Auður Hauksdóttir. Programmør på projektet er it-ingeniør Björn Þorvarðarson. Sabine Kirchmeier, fhv. direktør for  Dansk Sprognævn, er sprogteknologisk rådgiver. Samtlige billeder er designet af MA Sinéad McCarron. Talesyntesen er leveret af Responsive Voice under ikkekommerciel licens. Andre bidragsydere er folkeskolelærer Sine Dyrbye, cand.mag. Oskar Larsen og seniorforsker i Dansk Sprognævn Peter Juel Henrichsen. Projektet har modtaget støtte fra Nordplus Sprog og Kultur, det danske og det islandske undervisningsministerium (samarbejdsaftalen om danskundervisning) og Islands Universitets Forskningsfond.",

  "Spælið er gjørttil føroyskar, grønlendskar og íslendskar næmingar og hevur til endamáls at læra teir betur at tosa danskt. Forritið er ment á Háskóla Íslands í samstarvi við dansklærarar og næmingar í føroyskum, grønlendskum og íslendskum skúlum. Á odda fyri verkætlanini stendur Auður Hauksdóttir, professari. Forritari í verkætalini er Björn Þorvarðarson, kt-verkfrøðingur. Sabine Kirchmeier, fyrrv. stjóri í Dansk Sprognævn, er máltøkniligur ráðgevi. Sinéad McCarron, MA, hevur sniðgivið allar myndirnar. Talusyntesuna hevur Responsive Voice latið undir ikki-handilsligum loyvi. Aðrir stuðulsveitarar eru fólkaskúlalærarin Sine Dyrbye, Oskar Larsen, cand. mag., og Peter Juel Henrichsen, granskari í Dansk Sprognævn. Verkætlanin hevur fingið stuðul frá Nordplus Sprog og Kultur, danska og íslendska undirvísingarmálaráðnum (samstarvsavtalan um danskundirvísing) og Rannsóknasjóði Háskóla Íslands.",

  "Spili Savalimmiuni, Kalaallit Nunaanni Islandimilu atuartunut sanaajuvoq, atuartut qallunaat oqalussinnaanerat allassinnaanerallu pitsanngorsarniarlugu sanaajulluni. Programi Vigdís Finnbogadóttir Instituttet for Fremmedsprog ved Islands Universitetimi qallunaatut ilinniartitsisunik Savalimmiuni, Kalaallit Nunaanni Islandimilu atuarfinni atuartunik suleqateqarluni suliaavoq. Qallunaatut oqaatsinut professori Auður Hauksdóttir suliamik aqutsisuuvoq. IT-ingeniøri Björn Þorvarðarson suliami programmøriuvoq. Qarasaasiaq atorlugu oqaasilerinermi Sabine Kirchmeier, Dansk Sprognævnimi direktøriusimasoq, siunnersuisuuvoq. Assit tamarmik MA Sinéad McCarronimit suliarineqarput. Atuffassissut Responsive Voicemit ikkekommerciel licensilerlugu tunniunneqarpoq. Suliamut suleqataasut allat tassaapput, ilinniartitsisoq Sine Dyrbye, cand.mag. Oskar Larsen Dansk Sprognævnimilu siunnersortaaneq Peter Juel Henrichsen. Suliaq Nordplus Sprog og Kullturimit, Danmarkimi Islandimilu ilinniartitaanermut naalakkersuisoqarfimmit (qallunaatut oqaatsit pillugit suleqatigiinnissamik isumaqatigiissut) Islands Universitets Forskingsfondimiillu aningaasaliiffigineqarpoq.",

  "Leikurinn var gerður fyrir færeyska, grænlenska og íslenska nemendur með það að markmiði að þeir fái tækifæri til að tjá sig meira á dönsku. Leikurinn var þróaður innan Stofnunar Vigdísar Finnbogadóttur í erlendum tungumálum við Háskóla Íslands í samvinnu við færeyska, grænlenska og íslenska dönskukennara og nemendur þeirra. Auður Hauksdóttir, prófessor í dönsku, hefur leitt verkefnið. Björn Þorvarðarson tölvuverkfræðingur annaðist alla forritun, en Sabine Kirchmeier, fv. forstöðumaður Dönsku málnefndarinnar, veitti ráðgjöf við máltæknilegar lausnir. Allar myndir voru unnar af Sinéad McCarron, MA í hönnun. Þróun talgervilsins var í höndum Responsive Voice, sem góðfúslega heimilaði notkun hans, þar sem verkefnið er ekki unnið í hagnaðarskyni. Aðrir sem lagt hafa verkefninu lið eru Sine Dyrbye grunnskólakennari, Oskar Larsen cand. mag. og Peter Juel Henrichsen fræðimaður hjá Dönsku málnefndinni. Verkefnið hefur hlotið styrki frá Nordplus áætluninni fyrir tungumál og menningu, Rannsóknasjóði Háskóla Íslands og frá danska og íslenska menntamálaráðuneytinu (samstarfssamningur um stuðning við dönskukennslu). ",
]*/
