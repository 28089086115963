import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
//import microphoneYellow from '../img/microphoneYellow.png'
//import highlighterButton from '../img/marker1.png'
//import infoButton from '../img/information.png'

import microphoneYellow from '../img/icons/iconsButtons/iconMicrophone.png'
import highlighterButton from '../img/icons/iconsButtons/iconMarker.png'
import infoButton from '../img/icons/iconsButtons/iconInfo2.png'
import MyMicrophone from './MyMicrophone'

export default function SwipeableTemporaryDrawer(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };


  return (
    <div
    style={{
      backgroundColor: "clear",
      height:"100%",
      margin:"20",
      padding:"0",
    }}
    >


    <Button
    onClick={props.toggleShowInformation}
    style={{
      float:"right",
    }}
    >
      <img alt="" src={infoButton} width="40px" height= "40px"/>
    </Button>


    <Button
    onClick={toggleDrawer('left', true)}
    style={{
      float:"right",
    }}
    >
      <img alt="" src={microphoneYellow} width="40px" height= "40px"/>
    </Button>


        <Button
        onClick={props.toggleShowHighlighter}
        style={{
          float:"right",
        }}
        >
          <img alt="" src={highlighterButton} width="40px" height= "40px"/>
        </Button>



          <SwipeableDrawer
            anchor="top"
            open={state.left}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
            style={{
              marginLeft: "0px",
            }}
          >

          <div
          className="horizontal-center2"

          style={{
            margin: 0,
            paddingTop: 0,
            paddingRight: 0,
            backgroundColor: "lightgray",
            //height: "100%",
            //width: "400px",
          }}

          >

          <div
          className="horizontal-center2"

          style={{
            margin: 0,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 15,
            backgroundColor: "white",
            //height: "140px",

          }}

          >
          <MyMicrophone/>
          </div>


          </div>


          </SwipeableDrawer>



      <SwipeableDrawer
        anchor="top"
        open={state.top}
        onClose={toggleDrawer('top', false)}
        onOpen={toggleDrawer('top', true)}
      >

      <div style={{height:"80px"}}>

      {props.trophies.map(item => (

        <div key={item.sceneId}
        style={{
          float: "right",
          display: "inlineBlock",
          backgroundImage: `url(${item.trophy})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          height: '40px',
          width: '40px',
          marginTop: '20px',
          marginLeft: '3px',
          marginRight: '3px',
        }}

        >
        </div>


      ))}

      </div>

      </SwipeableDrawer>
    </div>
  );
}
