import React from 'react';

import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import axios from 'axios';
import * as actionTypes from '../store/actions/actionTypes';

import '../css/myCss.css'
import OpenTop from './OpenTop';
import OpenTopMenu from './OpenTopMenu';
import SceneClass from './SceneClass';
import MyMap from './MyMap'
import ResetButton from './ResetButton'
import Statistics from './Statistics'
import InformationTabs from './InformationTabs'
import Directions from './Directions'
import ProfileForm from './ProfileForm'
import ChangePasswordForm from './ChangePasswordForm'

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import mapmenu from '../img/scenes/MapMenu.png';
import flaskefinal from '../img/scenes/flaskefinal.png';
import hovedfinal from '../img/scenes/hovedfinal.png';
import skolensportfinal from '../img/scenes/skolensportfinal.png';
import megazinfinal from '../img/scenes/megazinfinal.png';
import cafeirisfinal from '../img/scenes/cafeirisfinal.png';
import ledigtcafebordfinal from '../img/scenes/ledigtcafebordfinal.png';
import dagligstuenfinal from '../img/scenes/dagligstuenfinal.png';
import skolegardenfinal from '../img/scenes/skolegardenfinal.png';
import klasseværelsetfinal from '../img/scenes/klassevaerelsetfinal.png';
import nonfoodfinal from '../img/scenes/nonfoodfinal.png';
import spisgodtfinal from '../img/scenes/spisgodtfinal.png';
import dulcefinal from '../img/scenes/dulcefinal.png';
import værskofinal from '../img/scenes/vaerskofinal.png';
import superchikfinal from '../img/scenes/superchikfinal.png';
import spisestuenfinal from '../img/scenes/spisestuenfinal.png';
import kokkenetfinal from '../img/scenes/kokkenetfinal.png';
import kassenfinal from '../img/scenes/kassenfinal.png';
import proverumfinal from '../img/scenes/proverumfinal.png';
import vaerelset from '../img/scenes/vaerelsetScene.jpg';
import kastrup from '../img/scenes/kastrupScene.jpg'
import trophyFinishedGame from '../img/trophyFinishGameFinal.gif'
import mapUpphafMedHring from '../img/icons/iconsButtons/mapUpphafMedHring5.png'

import spisestuenTrophy from '../img/newTrophies/spisestuenImage.png';

import path1 from '../img/trophyFinishSceneFinal.gif'
import supermarkednew from '../img/scenes/supermarkedNew.jpg'

import kastrupTrophy from '../img/trophies/kastrupTrophy.png'
import dagligstuenTrophy from '../img/trophies/dagligstuenTrophy.png'
import vaerelsetTrophy from '../img/trophies/vaerelsetTrophy.png'
import kokkenetTrophy from '../img/trophies/kokkenetTrophy.png'
import klassevaerelsetTrophy from '../img/trophies/klassevaerelsetTrophy.png'
import spisgodtTrophy from '../img/trophies/spisgodtTrophy.png'
import cafeirisTrophy from '../img/trophies/cafeirisTrophy.png'
import ledigtcafebordTrophy from '../img/trophies/ledigtcafebordTrophy.png'
import superchikTrophy from '../img/trophies/superchikTrophy.png'
//import danmark from '../img/denmark.png'
//import faroeIslands from '../img/faroeIslands.png'
//import greenland from '../img/greenland.png'
//import island from '../img/iceland.png'
import danmark from '../img/languages/DK.png'
import faroeIslands from '../img/languages/FO.png'
import greenland from '../img/languages/GL.png'
import island from '../img/languages/IS.png'
import tipButton from '../img/tipYellow.png'

//vantar værelset
//vantar kastrup
//vantar mapmenu

//tip for each scene
const kastrup_tip = "Mindst 3 transportformer."
//1: kort
//2: Kastrup
//3: Familien Skov
//4: Københavns Gymnasium
//5: Supermarked
//6: Megazin
//7: Café Kult
//8: Ledigt bord
//9: Dagligstuen
//10: Skolegården
//11: Klasseværelset
//12: Non-food
//13: Mad
//14: Dulce e Caramba
//15: Værsko
//16: Supertøj
//17: Idas værelse
//18: Spisestuen
//19: Køkkenet
//20: Flaskeautomat
//21: Kasse
//22: Prøverum

//----------

//1: kort
//2: Kastrup

//3: Familien Skov [1, 9, 17, 18, 19]
//9: Dagligstuen [3, 17, 18, 19]
//17: Idas værelse [3, 9, 18, 19]
//18: Spisestuen [3, 9, 17, 19]
//19: Køkkenet [3, 9, 17, 18]

//4: Københavns Gymnasium [1, 10, 11]
//10: Skolegården [4, 11]
//11: Klasseværelset [4, 10]

//5: Supermarked [1, 12, 13, 20]
//12: Non-food [5, 13, 20]
//13: Mad [5, 12, 20]
//20: Flaskeautomat [5, 12, 13]

//6: Megazin [1, 14, 15, 16, 21, 22]
//14: Dulce e Caramba [6, 15, 16, 21, 22]
//15: Værsko [6, 14, 16, 21, 22]
//16: Supertøj [6, 14, 15, 21, 22]
//21: Kasse [6, 14, 15, 16, 22]
//22: Prøverum [6, 14, 15, 16, 21]


//7: Café Kult [1, 8]
//8: Ledigt bord [7]




const sceneDict = [
	{
  "sceneTitle": "Talerum",
	"scene": "Kort",
	"id": 1,
	"navigateTo": [2, 3, 4, 5, 6, 7],
	"image": mapmenu,
	},
	{
	"scene": "Kastrup Lufthavn",
	"id": 2,
	"conversation": 1,
	"pinNeeded": 0,
	"navigateTo": [1, ],
	"image": kastrup,
	"trophy": kastrupTrophy,
	"tip": kastrup_tip,
	},
	{
	"scene": "Familien Skov",
	"id": 3,
	"navigateTo": [1, 9, 17, 18, 19],
	"image": hovedfinal,
	},
	{
	"scene": "Københavns Gymnasium",
	"id": 4,
	"navigateTo": [1, 10, 11],
	"image": skolensportfinal,
	},
	{
	"scene": "Supermarked",
	"id": 5,
	"navigateTo": [1, 12, 13, 20],
	"image": supermarkednew,
	},
	{
	"scene": "Megazin",
	"id": 6,
	"navigateTo": [1, 14, 15, 16, 21, 22],
	"image": megazinfinal,
	},
	{
	"scene": "Café Kult",
	"id": 7,
	"conversation": 1,
	"pinNeeded": 1,
	"navigateTo": [1, 8],
	"image": cafeirisfinal,
	"trophy": cafeirisTrophy,
	},
	{
	"scene": "Ledigt Bord",
	"id": 8,
	"conversation": 1,
	"pinNeeded": 1,
	"navigateTo": [1, 7, 8], //not 1
	"image": ledigtcafebordfinal,
	"trophy": ledigtcafebordTrophy,
	},
	{
	"scene": "Dagligstuen",
	"id": 9,
	"conversation": 1,
	"pinNeeded": 0,
	"navigateTo": [1, 3, 17, 18, 19], //not 1
  "hiddenScenes": [17],
	"image": dagligstuenfinal,
	"trophy": dagligstuenTrophy,
	},
	{
	"scene": "Skolegården",
	"id": 10,
	"navigateTo": [1, 4, 11], //not 1
	"image": skolegardenfinal,
	},
	{
	"scene": "Klasseværelset",
	"id": 11,
	"conversation": 1,
	"pinNeeded": 0,
	"navigateTo": [1, 4, 10], //not 1
	"image": klasseværelsetfinal,
	"trophy": klassevaerelsetTrophy,
	},
	{
	"scene": "Non-Food",
	"id": 12,
	"navigateTo": [1, 5, 13, 20], //not 1
	"image": nonfoodfinal,
	},
	{
	"scene": "Mad",  //Vest Supermarked
	"id": 13,
	"conversation": 1,
	"pinNeeded": 1,
	"navigateTo": [1, 5, 12, 20], //not 1
	"image": spisgodtfinal,
	"trophy": spisgodtTrophy,
	},
	{
	"scene": "Dulce e Caramba",
	"id": 14,
	"navigateTo": [1, 6, 15, 16, 21, 22], //not 1
	"image": dulcefinal,
	},
	{
	"scene": "Værsko",
	"id": 15,
	"navigateTo": [1, 6, 14, 16, 21, 22], //not 1
	"image": værskofinal,
	},
	{
	"scene": "Supertøj",
	"id": 16,
	"conversation": 1,
	"pinNeeded": 1,
	"navigateTo": [1, 6, 14, 15, 21, 22], //not 1
	"image": superchikfinal,
	"trophy": superchikTrophy,
	},
	{
	"scene": "Idas værelse",
	"id": 17,
	"conversation": 1,
	"pinNeeded": 1,
	"pinNeededForButton": 1,
	"navigateTo": [1, 3, 9, 18, 19],  //not 1
	"image": vaerelset,
	"trophy": vaerelsetTrophy,
	},
	{
	"scene": "Ved morgenbordet",
	"id": 18,
	"conversation": 1,
	"navigateTo": [1, 3, 9, 17, 19],
	"image": spisestuenfinal,
	"trophy": spisestuenTrophy,
	},
	{
	"scene": "Køkkenet",
	"id": 19,
	"conversation": 1,
	"pinNeeded": 0,
	"navigateTo": [1, 3, 9, 17, 18],  //not 1
	"image": kokkenetfinal,
	"trophy": kokkenetTrophy,
	},
	{
	"scene": "Flaskeautomat",  //not 1
	"id": 20,
	"navigateTo": [1, 5, 12, 13],
	"image": flaskefinal,
	},
	{
	"scene": "Kasse",
	"id": 21,
	"navigateTo": [1, 6, 14, 15, 16, 22],  //not 1
	"image": kassenfinal,
	},
	{
	"scene": "Prøverum",
	"id": 22,
	"navigateTo": [1, 6, 14, 15, 16, 21],  //not 1
	"image": proverumfinal,
	},
]

const theme = createMuiTheme({
	overrides: {
		MuiPaper: {
			root: {
				backgroundColor: 'rgba(105, 105, 105, 0.8)',
				paddingBottom: "15px",
			}
		}
	}
});

class Game extends React.Component {

	constructor(props) {
		super(props);
		this.child = React.createRef();
	}

	state = {
		userHasProfile: null,
		currentScene: sceneDict[0],
		userTrophies: [],
		shouldShowTip: false,
		shouldShowTrophy: false,
		shouldShowFinishedGame: false,
		//gameResetButton: false,
    showStatistics: false,
		showDirections: false,
    showProfile: false,
		showChangePassword: false,
		showInformation: false,
		showQuestionnaire: false,
		showHighlighter: false,

		trophytext: "",
		trophyUniqueKey: 0,
		lastSentUserText: "",
		computerAnswer: "",
		tipForQuestion: "",
		countryId: 1,
		hideTextBox: false,
		showStartIKastrup: false,
		clickedCloseStartIKastrup: false,
	};

  componentWillUnmount() {
    // console.log("GameBasic componentWillUnMount")

  }

	componentDidMount() {

    if(this.props.isAuthenticated && this.props.hasProfile) {
      //console.log("going to get trophies and pin status")
			this.props.getInfoPinTrophiesAndGameFinished()
			this.props.getProfileCountry()
    }
		//console.log("profileCountry0")
		//console.log(this.props.profileCountry)
	}


  UNSAFE_componentWillReceiveProps(newProps) {
		// console.log("game componentWillReceiveProps")
		//console.log(newProps)
		let noTrophy = this.noTrophyFinished(newProps.trophies)
		//console.log("showStartIKastrup...")
		//console.log(noTrophy)
    this.setState({
			showStartIKastrup: noTrophy,
      userTrophies: newProps.trophies,
    })
	}

	dictionaryCountry = () => {
		//console.log("props.profileCountry0")
		//console.log(this.props.profileCountry)
		if (this.props.profileCountry == null) {
			return false
		}

		let f = this.props.profileCountry.includes("Færøerne")
		let g = this.props.profileCountry.includes("Grønland")
		let i = this.props.profileCountry.includes("Island")
		if (f || g || i) {
			return true
		}
		return false
	}


	showTrophy = (trophytext) => {
		//console.log("trophytext gamebasic")
		//console.log(trophytext)
		this.setState({
			shouldShowTrophy: true,
			trophytext: trophytext,
		})
	}

  updatePinAndTrophies = () => {
    //console.log("updatePinAndTrophies after finishing dagligstuen")
    this.props.getPinAndTrophies()
		this.showTrophy()
  }

	finishedGame = () => {
		this.props.getIfGameFinished()
		//this.getT(this.props)
    //console.log("finishedGame GameBasic")
		this.setState({
			shouldShowFinishedGame: true,
		})
	}

	updateLanguage = () => {
    //console.log("updateLanguage GameBasic")
    this.props.updateLanguage();
	}


	updateTrophies = (update, show, trophytext) => {
		//this.getT(this.props)
    //console.log("updateTrophies GameBasic")
    this.props.getTrophies();
		if(show) {
			this.showTrophy(trophytext)
		}
	}



	showDictionary = () => {
		let country = this.props.profileCountry
		//console.log("state country")
		//console.log(country)
		var dictionaryPath = ""
		if (country.includes("Færøerne")) {
			dictionaryPath = "https://sprotin.fo/dictionaries"
		}
		else if (country.includes("Island")) {
			dictionaryPath = "https://islex.arnastofnun.is/is/"
		}
		else if (country.includes("Grønland")) {
			dictionaryPath = "http://www.ilinniusiorfik.gl/oqaatsit/daka"
		}

		if (dictionaryPath != "") {
			window.open(dictionaryPath, '_blank');
		}

	}


	showTip = () => {
		this.setState({
			shouldShowTip: true
		})
	}

	hideTip = () => {
		this.setState({
			shouldShowTip: false
		})
	}

	setLastSentUserText = (text) => {
		this.setState({
			lastSentUserText: text,
		})
	}

	setComputerAnswer = (c) => {
		this.setState({
			computerAnswer: c,
		})
	}

	setTipForQuestion = (tip) => {
		this.setState({
			tipForQuestion: tip,
		})
	}

	changeScene = (sceneId) => {
		this.setTipForQuestion("")
		this.setComputerAnswer("")
		this.setLastSentUserText("")
		//this.setBeginningText("")
		if (sceneId === 4) {
			//console.log("sceneid is 4 int")
			//call server and check if user is in welcomeAgain
			//this.sendLeftKlassevaerelset()
		}
		else if (sceneId === "4") {
			//console.log("sceneid is 4 string")
			//call server and check if user is in welcomeAgain
			//this.sendLeftKlassevaerelset(sceneId)
		}

		this.conversationBeginning(sceneId)
		this.setState({
			shouldShowTip: false,
			shouldShowTrophy: false,
			shouldShowFinishedGame: false,
      showStatistics: false,
			showDirections: false,
      showProfile: false,
			showInformation: false,
			beginningText: "",
		})
		let sceneIdStr = sceneId.toString()
		for (var s in sceneDict) {
			let id = sceneDict[s].id
			id = id.toString()
			//console.log(id)
			//console.log(id)
			//console.log(sceneId)
			if(sceneIdStr === "100") {
				//console.log("is 100")
				this.setState({
					showInformation: true,
				})
			}
			else if (id === sceneIdStr) {
				//console.log("same")
				//console.log("setScene:")
				//console.log(sceneDict[s])
				this.setState({
					currentScene: sceneDict[s]
				})

			}
		}
		this.forceUpdate()
	}


  conversationBeginning = (sceneId) => {
    //console.log("beginning")

    //console.log("id:")
    //console.log(sceneId)
    if(sceneId === null || sceneId === undefined ||
      this.props.token === null || this.props.token === undefined) {
    }
    else {
      //console.log("sending0..")
      //console.log(this.props.token)
      axios.defaults.headers = {
         "Accept": "application/json",
         "Content-Type": "application/json",
         'Authorization': "Token " + this.props.token
      }

     	axios.get(actionTypes.API_URL + 'api/beginning?id=' + sceneId).then(res => {
				//console.log("res is")
				//console.log(res.data)
     		let bt = res.data.beginningText
				let tipForQuestion = res.data.tipForQuestion
        //console.log("bt: ")
        //console.log(bt)
				//console.log(tipForQuestion)
				//setBeginningText(bt)
        //return bt
        //if(isMounted) {

				if (bt !== "") {
					//console.log("setBeginningText hide")
					this.hideTextBox(false)
				}
				//console.log("bt")
				//console.log("." + bt + ".")
				if(bt === "Du er færdig med scenen.") {
					//console.log("inside")
					this.hideTextBox(true)
				}


          this.setState({
        		beginningText: bt,
						tipForQuestion: tipForQuestion,
          })
        //}
    	})
    }
  }

	toggleShowFinishedGame = () => {
		let t = this.state.shouldShowFinishedGame
		//console.log("toggle finishedGame button")
		this.setState({
			shouldShowFinishedGame: !t,
		})
	}

	toggleShowTip = () => {
		let t = this.state.shouldShowTip
		//console.log("toggle tip button")
		this.setState({
			shouldShowTip: !t,
		})
	}

	toggleShowInformation = () => {
		//console.log("trophies:")
		//for (var i in this.props.trophies) {
		//	console.log(this.props.trophies[i])
		//}

		let t = this.state.showInformation
		this.setState({
			showInformation: !t,
		})
	}


	toggleShowHighlighter = () => {
		let t = this.state.showHighlighter
		this.setState({
			showHighlighter: !t,
		})
	}

	toggleShowQuestionnaire = () => {
		let t = this.state.showQuestionnaire
		this.setState({
			showQuestionnaire: !t,
		})
	}

	toggleShowTrophy = () => {

		let t = this.state.shouldShowTrophy
		//console.log("toggle trophy button")
		//this.reloadGif()

		let u = this.state.trophyUniqueKey
		this.setState({
			shouldShowTrophy: !t,
			trophyUniqueKey: u + 1
		})
	}


	toggleShow = () => {
		let t = this.state.show
		this.setState({
			show: !t,
		})
	}


	toggleShowStatistics = () => {
		let t = this.state.showStatistics
		this.setState({
			showStatistics: !t
		})
	}

	toggleShowChangePassword = () => {
		let t = this.state.showChangePassword
		this.setState({
			showChangePassword: !t
		})
	}

	toggleShowProfile = () => {
		let t = this.state.showProfile
		this.setState({
			showProfile: !t
		})
	}

	toggleShowDirections = () => {
		let t = this.state.showDirections
		this.setState({
			showDirections: !t
		})
	}

	toggleShowStartIKastrup = () => {
		let t = this.state.clickedCloseStartIKastrup
		this.setState({
			clickedCloseStartIKastrup: !t
		})
	}

	renderProfile(item) {
		//console.log("renderProfile")
		//console.log(item)
		if (!item) {
			return null;
		}

		return (
			<div
				className="all-center4"
				style={{
					position:"absolute",
					backgroundColor:"white",
					zIndex:100,
					height: "60%",
					width: "60%",
					//overflow: "auto",
				}}
			>
				<div>
					<ProfileForm
						toggleClosePopup={true}
						toggleShow={this.toggleShowProfile}
					/>
				</div>
			</div>
		);


	}


	renderChangePassword(item) {
		if (!item) {
			return null;
		}

		return (
			<div
				className="all-center4"
				style={{
					position:"absolute",
					backgroundColor:"white",
					zIndex:100,
					height: "60%",
					width: "60%",
					//overflow: "auto",
				}}
			>
				<div>
					<ChangePasswordForm
						toggleClosePopup={true}
						toggleShow={this.toggleShowChangePassword}
					/>
				</div>
			</div>
		);


	}


		renderStatistics(item) {
      //console.log("renderStatistics")
      //console.log(item)
				if (!item) {
					return null;
				}
				return(
					<div
						onClick={this.toggleShowStatistics}
						className="all-center2"
						style={{
							position:"absolute",
							backgroundColor:"darkgray",
              zIndex:10054,
							height: "70%",
							width: "70%",
							overflow: "auto",
						}}
            >

						<h3 style={{color:"white"}}>Statistics</h3>

            <Statistics/>

					</div>
				);
		}


		renderDirections(item) {
      //console.log("renderStatistics")
      //console.log(item)
				if (!item) {
					return null;
				}
				return(
					<div
						onClick={this.toggleShowDirections}
						className="all-center2"
						style={{
							position:"absolute",
							backgroundColor:"darkgray",
              zIndex:10054,
							height: "95%",
							width: "95%",
							overflow: "auto",
						}}
            >

						<h3 style={{color:"white"}}>Anvisninger</h3>

            <Directions/>

					</div>
				);
		}


    //show tip as popup view
    //button is used to close view
		renderConditionalTip(item) {
		    if (!item) {
		      return null;
		    }
				let listi = this.state.tipForQuestion.split("\n")

		    return(

					    <div
								onClick={this.toggleShowTip}
								className=" all-center2"
					    	style={{
					    		display: "flex",
							 		position:"absolute",
									background: "white",
	 								zIndex:10056,
									padding: 5,
					     }}>
						    <div style={{
						      width: "60px",
						      display: "flex",
									background:"#384151",
									padding: 10,
						    }}
								>
						      <img style={{
						        width: "50px",
						        height: "50px",
						        flexGrow: 1,
						        marginRight: 10,
						      }}
						        alt=""
						        src={tipButton}
						      />
						    </div>

					       <div
					         style={{
					           display: "inline",
					           textAlign: "left",
										 background:"#384151",
										 padding: 15,
					         }}>

								 	<h2 style={{color: "white",}}>
								 		Hjælp
									</h2>

								 {listi.map((x, i) =>
										<h3
										style={{color: "white",}}
										key={i}
										>
											{x}
										</h3>
								 )}


					       </div>
					     </div>

				);
	  }

		resetGame = () => {
			//console.log("resetting game:")
			//reset states
			//call redux whats needed
			//this.props.getInfoPinTrophiesAndGameFinished()
			//console.log(this.props)
			//this.props.getInfoPinTrophiesAndGameFinished()
			//this.changeScene(1)
			//this.forceUpdate()


			//this.props.getIfGameFinished()

      window.location.reload();
		}

		setBeginningText = (text) => {
			//console.log("setBeginningText...")
			if(text === "Du er færdig med scenen.") {
				this.hideTextBox(true)
			}

			this.setState({
        beginningText: text,
      })
		}

		toStatistics = () => {
      //console.log("toStatistics2")
      this.setState({
        showStatistics: true,
      })
      //this.forceUpdate()
			//<Redirect to="/login/"/>
			//this.props.history.push("/statistics/")
		}

		toDirections = () => {
      //console.log("toStatistics2")
      this.setState({
        showDirections: true,
      })
      //this.forceUpdate()
			//<Redirect to="/login/"/>
			//this.props.history.push("/statistics/")
		}

		hideTextBox = (hide) => {
			//console.log("hideTextBox")
			//console.log(hide)
			this.setState({
				hideTextBox: hide,
			})
		}



		toProfile = () => {
      //console.log("toProfile")
      this.setState({
        showProfile: true,
      })

			//this.props.history.push("/profile/")

		}

		toChangePassword = () => {
			this.setState({
				showChangePassword: true,
			})
			//this.props.history.push("/changePassword/")
		}

		handleChange = () => {
		}

		countryClicked = (id) => {
			this.setState({
				countryId: id,
			})
		}

		//check if trophy is finished
		isTrophyFinished = (item) => {
			let imageName = item.trophy
			//console.log("item image: " + imageName)
			if (imageName.startsWith("/static/")) {
				return true
			}
			else {
				return false
			}
		}

		noTrophyFinished(trophies) {
			//var foundFinishedTrophy = false
			for (var s in trophies) {
				//console.log("this.props.trophies[s]: " + trophies[s].trophy)
				var finished = this.isTrophyFinished(trophies[s])
				if (finished === true) {
					//console.log("returning false")
					return false
				}
			}
			//console.log("returning true")
			if (trophies.length == 0) {
				return false
			}
			//console.log("returning true")
			return true
		}

		renderConditionalStartIKastrup(item) {
			if (!item) {
				return null;
			}
			if (this.state.clickedCloseStartIKastrup) {
				return null;
			}
			//console.log("this.state.showStartIKastrup render")
			//console.log(this.state.showStartIKastrup)
			if (this.state.showStartIKastrup) {

					return(
						<div
							className="all-center3"
							style={{
								overflow: "hidden",
								position:"absolute",
								backgroundColor:"white",
								overflowY: "scroll",
								zIndex:10052,
								cursor: "pointer",
								marginTop: "10px",
								marginBottom: "10px",
								width:  "auto",
								height: "auto",
							}}
							onClick={this.toggleShowStartIKastrup}
							>

							<div
							style={{
								overflow: "hidden",
								backgroundImage: `url(${mapUpphafMedHring})`,
					      backgroundRepeat: "no-repeat",
					      backgroundSize: "100% 100%",
								width: "500px",
								height: "300px",
							}}
							>

							</div>
						</div>
					)

			}
			else {
				return null
			}


		}


		renderShowInformation(item) {
			if (!item) {
				return null;
			}

			let country = this.props.profileCountry
			let f = this.props.profileCountry.includes("Færøerne")
			let g = this.props.profileCountry.includes("Grønland")
			let i = this.props.profileCountry.includes("Island")
			let countryImage = danmark
			let countryId = 1
			if(f) {
				countryImage = faroeIslands
				countryId = 2
			}
			else if(g) {
				countryImage = greenland
				countryId = 3
			}
			else if(i) {
				countryImage = island
				countryId = 4
			}

			return(
				<div
					className="all-center3"
					style={{
						position:"absolute",
						backgroundColor:"white",
						height:"70%",
						width:"90%",
						overflow: "auto",
						overflowY: "scroll",
						zIndex:10052,
						cursor: "auto",
						marginTop: "10px",
						marginBottom: "10px",

					}}
>

	      <img
					style={{
						marginLeft:"10px",
						marginRight:"10px",
						//opacity: this.state.countryId === 1 ? 0.4 : 1.0
					}}
					alt="" src={countryImage} width="40px" height= "30px"/>

					<div
					style={{
						cursor: "pointer",
					}}

					onClick={this.toggleShowInformation}
					>
					<InformationTabs
						//countryId={this.state.countryId}
						countryId={countryId}
					/>
					</div>
				</div>
			)
		}

		renderShowHighlighter(item) {
			if (!item) {
				return null;
			}

			return(

				<div
					className="all-center3"
					style={{
						position:"absolute",
						backgroundColor:"white",
						overflow: "auto",
						overflowY: "scroll",
						zIndex:10052,
						cursor: "auto",
						marginTop: "10px",
						marginBottom: "10px",

					}}
					>

					<div
					style={{
						cursor: "pointer",
					}}

					onClick={this.toggleShowHighlighter}
					>
						<h3>
							Hvis du markerer et ord eller en tekst, bliver det læst op med Talerums stemme. Den lyder ikke altid 100 % perfekt, men er oftest ganske tæt på dansk udtale. Ved hjælp af stemmen kan du blive bedre til at tale dansk.
						</h3>


					</div>
				</div>
			)
		}



		renderShowQuestionnaire(item) {
			if (!item) {
				return null;
			}

			return(

				<div
					className="horizontalCenterVerticalTop"
					onClick={this.toggleShowQuestionnaire}
					style={{
						position:"absolute",
						backgroundColor:"white",
						overflow: "auto",
						overflowY: "scroll",
						zIndex:10052,
						cursor: "auto",
						marginTop: "30px",
						marginBottom: "10px",

					}}
					>

		          <a
		            rel="noopener noreferrer"
		            href="https://docs.google.com/forms/d/e/1FAIpQLSda9wv5khNsLrQ8yCjsmwUglJv-KXJTxnxMYs8SyvPTJUGVFA/viewform"
		            target="_blank">

		            <button className="btn btn-sm btn-success">
		              Questionnaire DK
		            </button>

		         </a>
						 <br/>
						 <br/>

	          <a
	              rel="noopener noreferrer"
	              href="https://docs.google.com/forms/d/e/1FAIpQLSeT20CgmGbpZ39tVqudrynj6fEciAzT39aAYVNbGDlubUBQFg/viewform"
	              target="_blank">

	              <button className="btn btn-sm btn-success">
	                Questionnaire IS
	              </button>
            </a>

						<br/>
						<br/>

            <a
              rel="noopener noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLScgRykZbnuoV_mIufWpLj0Lkfttuc09nQxoH1WMhQanQG5Q7Q/viewform"
              target="_blank">

              <button className="btn btn-sm btn-success">
                Questionnaire GL
              </button>

           </a>

					 <br/>
					 <br/>

           <a
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdrlz4bv78R1FC4yO75Vmjt6XqA--6VQ2wL4ptyYgsbAUTAKA/viewform"
            target="_blank">

            <button className="btn btn-sm btn-success">
              Questionnaire FO
            </button>
         	</a>

				</div>
			)
		}

    //show trophy user just earned as popup view
    //button is used to close view
		renderConditionalTrophy(item) {
				let correctGif = path1
				var toDisplay = {
					position:"absolute",
					backgroundColor:"white",
					zIndex:10051,
				}

				if (!item) {
					correctGif = ""
					toDisplay = {display: "none"}
		    }

		    return(
					<div
						onClick={this.toggleShowTrophy}
						className="all-center2"
						style={toDisplay}>

						<h3 style={{color:"#2A313E", margin:0, marginTop:"10px",}}>{this.state.currentScene.scene}</h3>
						<img visibility="false" id={this.state.trophyUniqueKey} alt="" src={correctGif} width="250px" height="250px"/>
						<h3 style={{color:"#2A313E", margin:0, marginTop:"10px",}}>{this.state.trophytext}</h3>

					</div>

				);
	  }
		renderConditionalFinishedGame(item) {
				//console.log("conditionalTrophy1")
				//console.log(item)
				//console.log("conditionalTrophy2")

				if (!item) {
					return null;
				}
				return(
					<div
						onClick={this.toggleShowFinishedGame}
						className=" all-center2"
						style={{
							position:"absolute",
							backgroundColor:"white",
							zIndex:10053,
						}}>

						<h3 style={{color:"#2A313E", margin:0, marginTop:"10px",}}>Tillykke, du er færdig med spillet!</h3>
						<img alt="" src={trophyFinishedGame} width="250px" height= "250px"/>

						<br/>
						<ResetButton
							changeScene={this.props.changeScene}
							resetGame={this.resetGame}
							btnClass= "btn-lg"
						/>

					</div>
				);
		}

	render() {
		return (
			<div style={{
			 width:"100%",
			 height:"100%",
       display: "flex",
       flexFlow: "column",
			 backgroundColor:"#333942",
			}}>
			<br/>
      {/* div height 65 */}
      <div
       style={{
        height:"65px",
        width:"100%",
        backgroundColor:"#333942",
       }}
      >
        {/* element1 Trophies */}
        <div
           style={{
             width: "275px",
             height:"100%",
             float:"right",
             display: "inlineBlock",
             margin:"0",
             padding:"0",
             verticalAlign: "top",
            }}
            >
          	<OpenTop
							trophies={this.props.trophies}
              props={this.props}
              currentScene={this.state.currentScene}
              showTip={this.showTip}
							toggleShowHighlighter={this.toggleShowHighlighter}
							toggleShowInformation={this.toggleShowInformation}

              />
        </div>
        {/* element1 ended */}

        {/* element2 */}
        <div style={{
          width: "275px",
          height:"100%",
          float: "left",
          display: "inlineBlock",
          margin:"0",
          padding:"0",
          verticalAlign: "top",
        }}
        >
        <OpenTopMenu
          props={this.props}
          currentScene={this.state.currentScene}
          showTip={this.showTip}
					trophies={this.props.trophies}
					//gameResetButton={this.props.gameInstanceFinished}
					gameResetButton={true}
					changeScene={this.changeScene}
					resetGame={this.resetGame}
					toProfile={this.toProfile}
					toChangePassword={this.toChangePassword}
					toStatistics={this.toStatistics}
					toDirections={this.toDirections}
					toggleShowQuestionnaire={this.toggleShowQuestionnaire}
          />
          </div>
          {/* todo: gameResetButton={this.props.props.gameInstanceFinished} element2 ended */}

          {/* element3 */}
          <div style={{
  					width: 'calc(100% - 550px)',
	          height:"100%",
            float: "left",
            display: "inlineBlock",
            margin:"0",
            padding:"0",
            verticalAlign: "top",
          }}
          >
          <p className="h1"
            style={{
              textAlign:"center",
              width:"100%",
              float:"left",
              display: "inlineBlock",
              color:"white",
            }}
          >
					{
						this.state.currentScene.id === 1 ?
							this.state.currentScene.sceneTitle
					:
						this.state.currentScene.scene
					}
					</p>
          </div>
          {/* element3 ended */}

      </div>
      {/* div height 65px of total ended */}

      {/*div height rest of total */}
       <div
       style={{width:"100%", flex: 1,
        overflow: "auto",}}
       >
       { this.state.currentScene.id === 1 ?
         <div style={{width:"100%", height:"100%", overflow: "hidden"}}>
          <MyMap
            changeScene={this.changeScene}
          />
        </div>
         :
         <div style={{width:"100%", height:"100%"}}>

				 <ThemeProvider theme={theme}>
	         <SceneClass
					 		lastSentUserText={this.state.lastSentUserText}
							setLastSentUserText={this.setLastSentUserText}
	           	changeScene={this.changeScene}
							currentScene={this.state.currentScene}
							sceneDict={sceneDict}
							props={this.props}
							userText=""
							updateTrophies={this.updateTrophies}
							updatePinAndTrophies={this.updatePinAndTrophies}
							finishedGame={this.finishedGame}
							showTip={this.showTip}
							showDictionary={this.showDictionary}
							dictionaryCountry={this.dictionaryCountry}
							hideTip={this.hideTip}
							conversationBeginning={this.conversationBeginning}
							beginningText={this.state.beginningText}
							setBeginningText={this.setBeginningText}
							setComputerAnswer={this.setComputerAnswer}
							computerAnswer={this.state.computerAnswer}
							tipForQuestion={this.state.tipForQuestion}
							setTipForQuestion={this.setTipForQuestion}
							hideTextBox={this.hideTextBox}
							hideTextBoxState={this.state.hideTextBox}
							/>
						</ThemeProvider>

       </div>
       }

			 {this.renderChangePassword(this.state.showChangePassword)}
			 {this.renderProfile(this.state.showProfile)}
			 {this.renderShowHighlighter(this.state.showHighlighter)}
       {this.renderShowQuestionnaire(this.state.showQuestionnaire)}
		   {this.renderStatistics(this.state.showStatistics)}
			 {this.renderDirections(this.state.showDirections)}
			 {this.renderShowInformation(this.state.showInformation)}
			 {this.renderConditionalTip(this.state.shouldShowTip)}
			 {this.renderConditionalTrophy(this.state.shouldShowTrophy)}
			 {this.renderConditionalFinishedGame(this.state.shouldShowFinishedGame)}

			 {this.renderConditionalStartIKastrup(this.state.currentScene.id === 1)}
	     </div>
       {/* div height rest of total ended */}
			</div>
	)
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.token !== null,
		token: state.token,
    trophies: state.trophies,
    hasPin: state.hasPin,
		gameInstanceFinished: state.gameInstanceFinished,
		profileCountry: state.profileCountry
	}
}

const mapDispatchToProps = dispatch => {
    //console.log("before updatePinAndTrophies")
    return {
				getProfileCountry: () => dispatch(actions.updateProfileCountry()),
        getIfGameFinished: () => dispatch(actions.gameFinishedCheck()),
        logout: () => dispatch(actions.logout()),
        getPinAndTrophies: () => dispatch(actions.updatePinAndTrophies()),
				getInfoPinTrophiesAndGameFinished: () => dispatch(actions.getInfoPinTrophiesAndGameFinished()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Game);

/*

	      <img onClick={() => this.countryClicked(1)}
							style={{
								marginLeft:"10px",
								marginRight:"10px",
								opacity: this.state.countryId === 1 ? 0.4 : 1.0
							}}
							alt="" src={danmark} width="40px" height= "30px"/>

				<img onClick={() => this.countryClicked(4)}
					   style={{
							 marginLeft:"10px",
							 marginRight:"10px",
							 opacity: this.state.countryId === 4 ? 0.4 : 1.0
						 }}
						 alt="" src={island} width="40px" height= "30px"/>

				<img onClick={() => this.countryClicked(2)}
						 style={{
							 marginLeft:"10px",
							 marginRight:"10px",
							 opacity: this.state.countryId === 2 ? 0.4 : 1.0
						 }}
						 alt="" src={faroeIslands} width="40px" height= "30px"/>


				<img onClick={() => this.countryClicked(3)}
						 style={{
							 marginLeft:"10px",
							 marginRight:"10px",
							 opacity: this.state.countryId === 3 ? 0.4 : 1.0
						 }}
						 alt="" src={greenland} width="40px" height= "30px"/>

*/
