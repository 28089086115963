import React from 'react';
import { withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';
//import 'antd/dist/antd.css';
import * as actions from '../store/actions/auth';

class CustomLayout extends React.Component {
  render() {
    return (

      <div
      style={{
        width:"100%",
        height: "100%",
      }}
      >
        {this.props.children}
      </div>

    );
  }
}


const mapStateToProps = state => {
	return {
		isAuthenticated: state.token !== null,
	}
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout())
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomLayout));
