import React from 'react';

//import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
//import menuButton from '../img/menuYellow3.png'
//import settingsButton from '../img/wheel.png'
//import questionnaireButton from '../img/questionnaire2.png'

import menuButton from '../img/icons/iconsButtons/iconMenu5.png'
import settingsButton from '../img/icons/iconsButtons/iconSettings5.png'
import questionnaireButton from '../img/icons/iconsButtons/iconQuestionnaire5.png'

import { Link } from 'react-router-dom';
import ResetButton from './ResetButton'
import LeftMap from './LeftMap'

export default function SwipeableTemporaryDrawer(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawerNoEvent = (side, open) => {
    setState({ ...state, [side]: open });
  }

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };


  const toProfile = () => {
    toggleDrawerNoEvent('top', false)
    props.toProfile()
  }

  const toLogout = () => {
    toggleDrawerNoEvent('top', false)
    props.props.logout()

  }

  const toStatistics = () => {
    toggleDrawerNoEvent('top', false)
    props.toStatistics()

  }

  const toChangePassword = () => {
    toggleDrawerNoEvent('top', false)
    props.toChangePassword()
  }

  const rowClicked = (id) => {
    toggleDrawerNoEvent('left', false)
    props.changeScene(id)
  }

  return (


    <div
    style={{
      backgroundColor: "clear",
      height:"100%",
      margin:"20",
      padding:"0",
    }}
    >

    <Button
    onClick={toggleDrawer('left', true)}
    style={{
      float:"left",
    }}
    >
      <img alt="" src={menuButton} width="40px" height= "40px"/>

    </Button>

    <Button
    onClick={toggleDrawer('top', true)}
    style={{
      float:"left",
    }}
    >
      <img alt="" src={settingsButton} width="45px" height= "45px"/>
    </Button>

    <Button
    onClick={props.toggleShowQuestionnaire}
    style={{
      float:"left",
    }}
    >
      <img alt="" src={questionnaireButton} width="40px" height= "40px"/>
    </Button>




      <SwipeableDrawer
        anchor="top"
        open={state.top}
        onClose={toggleDrawer('top', false)}
        onOpen={toggleDrawer('top', true)}
      >


      <div>
      {props.props.isAuthenticated}

      {
        props.props.isAuthenticated ?

        <div
        style={{margin: 10, }}
        >


          <button className="btn btn-sm btn-success"
            onClick={toProfile}
            style={{marginLeft: "10px", }}
          >
            Profil
          </button>


          <button className="btn btn-sm btn-success"
            onClick={toStatistics}
            style={{marginLeft: "10px", }}
          >
            Statistik
          </button>


          <button className="btn btn-sm btn-warning"
            onClick={toChangePassword}
            style={{marginLeft: "10px", }}
          >
            Skift adgangskode
          </button>


          { props.gameResetButton ?
              <ResetButton
                changeScene={props.changeScene}
                resetGame={props.resetGame}
                btnClass= "btn-sm"
                style={{marginLeft: "10px", }}

              />
            :
              null
          }

          <button className="btn btn-sm btn-warning"
            onClick={toLogout}
            style={{marginLeft: "10px", }}
          >
            Log ud
          </button>
        </div>

        :

        <div>
          <Link to="/login"> Login </Link>
        </div>
      }
      </div>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="left"
        open={state.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
        style={{
          marginLeft: "0px",
        }}
      >

      <div
      className="horizontal-center2"

      style={{
        margin: 0,
        paddingTop: 0,
        paddingRight: 0,
        backgroundColor: "lightgray",
        height: "100%",

      }}
      >

      <LeftMap
        rowClicked={rowClicked}
        trophies={props.trophies}
      />

      </div>

      </SwipeableDrawer>

    </div>
  );
}
