import React from 'react';
//import clsx from 'clsx';
//import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
//import CssBaseline from '@material-ui/core/CssBaseline';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
//import List from '@material-ui/core/List';
//import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
//import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import chatImage from '../img/chatImage.png'
//import closeImage from '../img/closeImage.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Dialog from './Dialog'
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";


//const drawerWidth = 240;

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: 'rgba(105, 105, 105, 0.8)',
        paddingBottom: "15px",
      }
    }
  }
});

/*const useStyles = makeStyles((theme1) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    height: `calc(100% - ${drawerWidth}px)`,
    marginBottom: drawerWidth,
    transition: theme.transitions.create(['margin', 'height'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    backgroundcolor: '#166191',
    height: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    height: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginBottom: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginBottom: 0,
  },
}));*/

export default function PersistentDrawerLeft(props) {
  //const classes = useStyles();
  //const theme1 = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>

    <div
      className=""
      style={{
        margin: 0,
        paddingTop: 0,
        paddingRight: 0,
      }}
    >

    {open === false ?

      <Button
        onClick={handleDrawerOpen}
        style={{
          position: "absolute",
          height: "100px",
          bottom: "0px",
          right: "0px",
          marginBottom: "20px",
          marginRight: "5px",
        }}
        >
        <img alt="" src={chatImage} width="100px" height= "100px"/>
      </Button>
      :
      null
    }
        <Drawer
          variant="persistent"
          anchor="bottom"
          open={open}
          classes={{
          }}
          style={{
          }}
        >

          <FontAwesomeIcon
            className="fa fa-flag fa-3x"
            icon={faChevronDown}
            onClick={handleDrawerClose}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              marginTop: "5px",
              marginRight: "5px",
            }}
          />

          <Divider />

            <Dialog
              dictionaryCountry={props.dictionaryCountry}
              lastSentUserText={props.lastSentUserText}
              setLastSentUserText={props.setLastSentUserText}
              props={props}
              beginningText={props.beginningText}
              updateTrophies={props.updateTrophies}
              updatePinAndTrophies={props.updatePinAndTrophies}
              noNeedForBeginningText={props.noNeedForBeginningText}
              setComputerAnswer={props.setComputerAnswer}
              computerAnswer={props.computerAnswer}
              finishedGame={props.finishedGame}
              showTip={props.showTip}
              hideTip={props.hideTip}
              currentScene={props.currentScene}
              setTipForQuestion={props.setTipForQuestion}
              tipForQuestion={props.tipForQuestion}
              hideTextBox={props.hideTextBox}
              hideTextBoxState={props.hideTextBoxState}
              showDictionary={props.showDictionary}

             />

        </Drawer>

    </div>
    </ThemeProvider>
  );
}
