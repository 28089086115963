import React from 'react';
import ImageMapper from 'react-image-mapper';


import mainMap from '../img/map130819/mapUpphaf.png'
import mapSchool from '../img/map130819/skolen.png'
import mapIndkobscenter from '../img/map130819/super.png'
import mapMegazin from '../img/map130819/megazin.png'
import mapHome from '../img/map130819/home.png'
import mapCafe from '../img/map130819/cafe.png'
import mapKastrup from '../img/map130819/kastrup.png'
import information from '../img/map130819/info.png'

const areaDict = {
	"2": mapKastrup,
	"3": mapHome,
	"4": mapSchool,
	"5": mapIndkobscenter,
	"6": mapMegazin,
	"7": mapCafe,
	"100": information,
};

let isMounted = true
const topBarHeight = 85
class MyMap extends React.Component {

	 state = {
		 width: null,
		 height: null,
		 areas: {},
		 currentImage: mainMap,
   }

	 constructor(props) {
		 super(props)
		 //console.log("mymap constructor")
		 //must, annars crash
		 this.state = {
			 currentImage: mainMap,
	   };
	 }

	areaStuff() {
		 window.addEventListener("resize", this.resize.bind(this));
		 this.resize();
		 const w = window.innerWidth
		 const h = window.innerHeight - topBarHeight
		 this.setArea(w, h)
 	}

 	UNSAFE_componentWillMount() {
		isMounted = true
    this.areaStuff()

		let imList = [mainMap, mapSchool, mapIndkobscenter, mapMegazin, mapHome, mapCafe, mapCafe, mapKastrup, information]

		imList.forEach((picture) => {
	    const img = new Image();
	    img.src = picture;

	 	});
 	}

	componentDidMount() {
		isMounted = true

 	  this.areaStuff()
 	}


	setArea(w, h) {
			const aspectRatio = 1919/1079  // w/h

			const originalX = 1920
			const originalY = originalX/aspectRatio

			const x1List = [230, 230, 643, 643]
			const y1List = [152, 340, 340, 152]

			const x2List = [565, 565, 804, 804]
			const y2List = [575, 692, 692, 575]

      const x3List = [910, 910, 1275, 1275]
			const y3List = [105, 312, 312, 105]

			const x4List = [667, 667, 859, 859]
			const y4List = [885, 995, 995, 885]

			const x5List = [1150, 1150, 1249, 1249]
			const y5List = [444, 558, 558, 444]

			const x6List = [1174, 1174, 1555, 1555]
			const y6List = [783, 995, 995, 783]

      const x7List = [1770, 1770, 1873, 1873]
			const y7List = [930, 1032, 1032, 930]

			const allXList = [x1List, x2List, x3List, x4List, x5List, x6List, x7List]
			const allYList = [y1List, y2List, y3List, y4List, y5List, y6List, y7List]


			let ollHnit = []
			let listCount = 0
			while(listCount < allXList.length) {
				let ollHnitCurrentList = []
				let count = 1
				while(count < 5) {
					const i = count - 1
					let xHnit = allXList[listCount][i]
					let yHnit = allYList[listCount][i]
					//console.log(xHnit)
					//console.log(yHnit)
					let xHlutfall = xHnit/originalX
					let yHlutfall = yHnit/originalY
					let nyttX = Math.round(w * xHlutfall)
					let nyttY = Math.round(h * yHlutfall)
					ollHnitCurrentList.push(nyttX)
					ollHnitCurrentList.push(nyttY)

					count++
				}
				ollHnit.push(ollHnitCurrentList)
				listCount++
			}

		//name is based on scene id
		let a = {
			name: 'my-map',
			areas: [
				{name: '4', shape: 'poly', coords: ollHnit[0], fillColor: "rgba(255, 255, 255, 0.0)"},
				{name: '5', shape: 'poly', coords: ollHnit[1], fillColor: "rgba(255, 255, 255, 0.0)"},
				{name: '6', shape: 'poly', coords: ollHnit[2], fillColor: "rgba(255, 255, 255, 0.0)"},
				{name: '3', shape: 'poly', coords: ollHnit[3], fillColor: "rgba(255, 255, 255, 0.0)"},
				{name: '7', shape: 'poly', coords: ollHnit[4], fillColor: "rgba(255, 255, 255, 0.0)"},
				{name: '2', shape: 'poly', coords: ollHnit[5], fillColor: "rgba(255, 255, 255, 0.0)"},
        {name: '100', shape: 'poly', coords: ollHnit[6], fillColor: "rgba(255, 255, 255, 0.0)"},
			]
		}

		if(isMounted) {
			this.setState({
				areas: a,
			})
		}
	}

 	resize() {
		if(isMounted) {
			this.setState({
				width: window.innerWidth,
				height: window.innerHeight - topBarHeight,
			});
		}
		this.setArea(window.innerWidth, window.innerHeight - topBarHeight)
 	}

	componentWillUnmount() {
		isMounted = false
	}

	clicked(area) {
    this.props.changeScene(area.name)
	}


	mouseEnter(area) {
		let newImage = areaDict[area.name]
		this.setState({
			currentImage: newImage,
		})
	}


	mouseLeave(area) {
		if(isMounted){
			this.setState({
				currentImage: mainMap,
			})
		}
	}

	render () {

		return (
				<div>
					<ImageMapper src={this.state.currentImage} map={this.state.areas} width={this.state.width} height={this.state.height}
						onClick={area => this.clicked(area)}
						onMouseEnter={evt => this.mouseEnter(evt)}
						onMouseLeave={evt => this.mouseLeave(evt)}
						strokeColor= "rgba(255, 255, 255, 1.0)"
						style={{
						}}

					/>

				</div>

		);
	}
}

export default MyMap;
