export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const PROFILE_START = 'PROFILE_START';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';

export const GET_PROFILE_COUNTRY= 'GET_PROFILE_COUNTRY';

export const GET_TROPHIES = 'GET_TROPHIES';
export const GET_PIN = 'GET_PIN';
export const GET_PIN_AND_TROPHIES = 'GET_PIN_AND_TROPHIES';
export const GET_IF_GAME_FINISHED = 'GET_IF_GAME_FINISHED';
export const GET_PIN_TROPHIES_AND_IF_GAME_FINISHED = 'GET_PIN_TROPHIES_AND_IF_GAME_FINISHED';

//export const API_URL = 'http://127.0.0.1:8000/'
export const API_URL = 'https://api.talerum.is/'

export const FRONT_END_URL = 'https://talerum.is/'
