
import React, { useState, useEffect, useRef } from 'react';
//import { forwardRef, useImperativeHandle } from 'react'

import '../css/myCss.css'
import axios from 'axios'
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';
//import { withRouter } from "react-router-dom";
import SingleInputField from './SingleInputField';
//import RoundedButton from './RoundedButton'
import * as actionTypes from '../store/actions/actionTypes'
import tipButton from '../img/tipYellow.png'
import dictionaryButton from '../img/icons/iconsButtons/dictionaryButton3.png'

import Button from '@material-ui/core/Button'
//import useScript from './useScript';

export default function Dialog(props) {
  //useScript('https://code.responsivevoice.org/responsivevoice.js?key=1aK7b2PY');

  //"https://code.responsivevoice.org/responsivevoice.js?key=1aK7b2PY"
  //const [showBeginningText, setShowBeginningText] = useState("");
  //const [beginningText, setBeginningText] = useState("");
  //const [computerAnswer, setComputerAnswer] = useState("");
  const [userText, setUserText] = useState("");


  //const [urlid, setUrlid] = useState("");
  //const [lastSentUserText, setLastSentUserText] = useState("");
  //const [currentPosition, setCurrentPosition] = useState(0)

  const ref = useRef();

  const sendChat = () => {
    //setShowBeginningText("no")
    //responsiveVoice.speak("hello world");
    //window.responsiveVoice.speak('The Internet is a series of tubes!');

    //window.responsiveVoice.speak("hej, god dag", "Danish Female", {pitch: 2});
    //window.responsiveVoice.speak("hej, god dag", "Danish Female");
    //window.responsiveVoice.speak("nej det kan jeg ikke", "Danish Male");
    //window.responsiveVoice.speak("nej det kan jeg ikke", "Danish Female");
    props.hideTip()

    setUserText("")
    props.noNeedForBeginningText()

    let sceneId = props.props.currentScene.id
    //console.log("propsDialog:")
    //console.log(sceneId)
    //console.log("texti:")
    //console.log(userText)
    //console.log("sending..")
    //console.log(props.props.props.token)
    axios.defaults.headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
      'Authorization': "Token " + props.props.props.token
    }

    axios.get(actionTypes.API_URL + 'api/conversation?id=' + sceneId + '&text=' + userText).then(res => {
         //this.setState({
         //  articles: res.data
         //});
         //console.log("result:")
         //console.log(res.data)

         //setComputerAnswer(res.data.computerResponse)
         props.setComputerAnswer(res.data.computerResponse)
         let userTextWithUsername = res.data.userTextWithUsername
         props.setLastSentUserText(userTextWithUsername)
         let trophytext = res.data.urlid

         let tipForQuestion = res.data.tipForQuestion
         props.setTipForQuestion(tipForQuestion)
         //setUrlid(url)
         //console.log("trophytext:")
         //console.log(trophytext)
         //console.log(props.tipForQuestion)
         let finishedTrophy = res.data.finishedTrophy
         let finished = res.data.finishedGame
         if(finished) {
           finishedGame()
         }

         /*let showGotInstructionsTrophy = res.data.showGotInstructionsTrophy
         console.log("showGotInstructionsTrophy: ")
         console.log(showGotInstructionsTrophy)
         if(showGotInstructionsTrophy) {
           console.log("got showGotInstructionsTrophy")
           let show = true
           if(finished) {
             show = false
           }
           let update = true
           updateTrophies(update, show)
         }*/

         if(finishedTrophy) {
           props.props.hideTextBox(true)
           //if dagligstuen we can update the pin
           //was dagligstuen, is now spisestuen
           if(props.props.currentScene.id === 18) {
             updatePinAndTrophies()

             //console.log("update:")
             //console.log(trophytext)
             let update = true
             let show = true
             updateTrophies(update, show, trophytext)
           }
           else {
             //the following logic is to make sure a trophy is not shown when the finishedGame trophy will be shown
             let show = true
             if(finished) {
               show = false
             }
             let update = true
             // console.log(update)
             // console.log("show:")
             // console.log(show)
             updateTrophies(update, show, trophytext)
           }
         }
         else {
           props.props.hideTextBox(false)
         }
         //setBeginningText(userText)
       })
    }



    useEffect(() => {
      // Update the document title using the browser API
      //conversationBeginning()

    });


    const labelChanged = (text) => {
      //console.log("changing label text")
      //console.log(text)
      //console.log(position)
      setUserText(text)
      //setCurrentPosition(position)
    }


    const addChar = e => {
      //console.log("parent method called1")
      let char = e.target.innerHTML
      //console.log("adding char:")
      //console.log(char)
      ref.current.addCharToInput(char)
    }


    const updatePinAndTrophies = () => {
      //console.log("updatePinAndTrophies1")
      props.updatePinAndTrophies()
    }

    const updateTrophies = (update, show, trophytext) => {
      //console.log("updateTrophies1")
      //console.log(trophytext)
      props.updateTrophies(update, show, trophytext)
    }

    const finishedGame = () => {
      //console.log("finishedGame1")
      props.finishedGame()
    }


    const setNewPosition = (p) => {
      //console.log("setNewPosition2")
      //console.log(p)
      //setCurrentPosition(p)
    }

    const selectedText = () => {
        let str = window.getSelection().toString() //? console.log(window.getSelection().toString()) : null;
        //console.log("selected: ")
        //console.log(str)
        //console.log(str.length)

        if(str.length === 0) {
          //console.log("0");
        }
        else {
          window.responsiveVoice.speak(str, "Danish Female");
        }
    }

    return (
      <div onPointerUp={selectedText}>
      {
        props.props.currentScene.conversation ?

        <div className="horizontal-center"
          style={{
            width:"60%",
          }}
        >
        {
          props.beginningText !== "" ?

          <h5 className="" style={{opacity: props.props.hideTextBoxState === false ? 1.0 : 0.0, marginTop:"10px", color: 'rgba(255,255,255,1.0)' }}> {props.beginningText} </h5>

          :

          <h5 className="" style={{opacity: props.props.hideTextBoxState === false ? 1.0 : 0.0, color: "white", marginTop:"10px", textDecoration: "underline", }}>{props.lastSentUserText}</h5>
        }

{/*
        <a target="_blank" rel="noopener noreferrer" href={urlid}>
        </a>
        "nonSelectable"
*/}


          <button
          style={{opacity: props.props.hideTextBoxState === false ? 1.0 : 0.0}}

          type="button" className="btn btn-light charButton" onClick={addChar}>å</button>
          <button
          style={{opacity: props.props.hideTextBoxState === false ? 1.0 : 0.0}}
          type="button" className="btn btn-light charButton" onClick={addChar}>æ</button>

          <button
          style={{opacity: props.props.hideTextBoxState === false ? 1.0 : 0.0}}
          type="button" className="btn btn-light charButton" onClick={addChar}>ø</button>


          <h5 className="" style={{opacity: props.props.hideTextBoxState === false ? 1.0 : 0.0, color: "white", marginTop:"5px", }}>{props.computerAnswer}</h5>

          <div className="container"
          style={{
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
          }}
          >

          { props.props.hideTextBoxState === false && props.dictionaryCountry() ?

              <div
                style={{
                  width:"auto",
                  display: "inlineBlock",
                  //visibility: "hidden"
                }}
              >
              <Button
                onClick={props.showDictionary}
              >
              <img alt="" src={dictionaryButton} width="40px" height= "40px"/>
              </Button>


              </div>
              :
              null
          }


          {	props.props.hideTextBoxState === false ?

            <div className="center"
            style={{width: "75%", }}
            >

              <SingleInputField labelChanged={labelChanged}
                currentText={userText}
                submitInput={sendChat}
                setNewPosition={setNewPosition}
                ref={ref}
                style={
                  { display: "inlineBlock",
                    float:"center",
                    width:"100%",
                  }}
              />

            </div>
            : null

          }

          {	props.props.hideTextBoxState === false ?

            <div style={{display:"inlineBlock", float: "left", width:"auto"}}>

            { props.tipForQuestion !== "" ?

              <Button

                onClick={props.showTip}
              >
              <img alt="" src={tipButton} width="40px" height= "40px"/>
              </Button>

              :
              <Button
                style={{visibility:"hidden"}}
              >

              <img alt="" src={tipButton} width="40px" height= "40px"/>
              </Button>

              }

            </div>

              : null
          }

          </div>


          {	props.props.hideTextBoxState === false ?

          <div
          style={{

          }}
          >
          <button
            className="btn btn-light charButton"
            onClick={sendChat}
            style={{marginTop: "10px",}}
          >
          Send
          </button>
          </div>
        :
        null
        }


          </div>

        :
        null
      }

      </div>

      );
}



/*
(
  <div onPointerUp={selectedText}>
  {
    props.props.currentScene.conversation ?

    <div className="horizontal-center"
      style={{
        width:"60%",
      }}
    >
    {
      props.beginningText !== "" ?

      <h5 className="" style={{marginTop:"10px", color: 'rgba(255,255,255,1.0)' }}> {props.beginningText} </h5>

      :

      <h5 className="" style={{color: "white", marginTop:"10px", textDecoration: "underline", }}>{props.lastSentUserText}</h5>
    }

      <button
      style={{opacity: props.props.hideTextBoxState === false ? 1.0 : 0.0}}

      type="button" className="btn btn-light charButton" onClick={addChar}>å</button>
      <button
      style={{opacity: props.props.hideTextBoxState === false ? 1.0 : 0.0}}
      type="button" className="btn btn-light charButton" onClick={addChar}>æ</button>

      <button
      style={{opacity: props.props.hideTextBoxState === false ? 1.0 : 0.0}}
      type="button" className="btn btn-light charButton" onClick={addChar}>ø</button>


      <h5 className="" style={{color: "white", marginTop:"5px", }}>{props.computerAnswer}</h5>

      <div className="container"
      style={{
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
      }}
      >

      {	props.props.hideTextBoxState === false ?

      <div
        style={{width:"auto", display: "inlineBlock", }}
      >
      <Button

        onClick={props.showDictionary}
      >
      <img alt="" src={dictionaryButton} width="40px" height= "40px"/>
      </Button>


      </div>
      :
      null
    }

{	props.props.hideTextBoxState === false ?



        <div className="center"
        style={{width: "75%", }}
        >

          <SingleInputField labelChanged={labelChanged}
            currentText={userText}
            submitInput={sendChat}
            setNewPosition={setNewPosition}
            ref={ref}
            style={
              { display: "inlineBlock",
                float:"center",
                width:"100%",
              }}
          />

        </div>
        : null

      }

      {	props.props.hideTextBoxState === false ?

        <div style={{display:"inlineBlock", float: "left", width:"auto"}}>

        { props.tipForQuestion !== "" ?

          <Button

            onClick={props.showTip}
          >
          <img alt="" src={tipButton} width="40px" height= "40px"/>
          </Button>

          :
          <Button
            style={{visibility:"hidden"}}
          >

          <img alt="" src={tipButton} width="40px" height= "40px"/>
          </Button>

          }

        </div>

          : null
      }

      </div>


      {	props.props.hideTextBoxState === false ?

      <div
      style={{

      }}
      >
      <button
        className="btn btn-light charButton"
        onClick={sendChat}
        style={{marginTop: "10px",}}
      >
      Send
      </button>
      </div>
    :
    null
    }
      </div>
    :
    null
  }
  </div>
  )
*/
