import React from 'react';
import { Form, Icon, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import TalerumTopImage from './TalerumTopImage'
import axios from 'axios'
import * as actionTypes from '../store/actions/actionTypes';

const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;


class ResetPasswordForm extends React.Component {

  state = {
    message: "",
  }

  handleSubmit = e => {
    e.preventDefault();
    //console.log("handleSubmit:")
    //console.log(e)

    this.props.form.validateFieldsAndScroll((err, values) => {
      if(!err) {
        //console.log("values:")
        //console.log(values)
        //console.log(values.email)
        this.sendFormData(values.email)
      }
    });

  };


  componentDidMount() {
    this.setState({
      message: "",
    })
  }


  sendFormData = (email) => {
    axios.post(actionTypes.API_URL + 'rest-auth/password/reset/', {
      email: email
    })
    .then(response => {
      //console.log("response:")
      //console.log(response)
      this.setState({
        message: "We have sent you an email!",
      })
    }).catch((err) => {
      //console.log("err reset")
      //console.log(err.response)
      this.setState({
        message: "Not able to send an email!",
      })
    });

  }

  render() {

    const { getFieldDecorator } = this.props.form;
    return (
        <div
        style={{
          width:"100%",
          height:"100%",
        }}
        >
            {
                this.props.loading ?

                <Spin indicator={antIcon} />

                :

              <div
              style={{
                width:"100%",
                height:"100%",
              }}
              >

              <TalerumTopImage/>

                <Form onSubmit={this.handleSubmit} className="login-form"

                  style={{
                    width:"50%",
                    position: 'absolute', left: '50%',
                    transform: 'translate(-50%)'
                  }}
                >


                <p className="horizontal-center2"> Please enter your email, we will send a link to your email address. Clicking the link will allow you to reset your password.  </p>

                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [{
                      type: 'email', message: 'The input is not valid E-mail!',
                    }, {
                      required: false, message: 'Please input your E-mail!',
                    }],
                  })(
                    <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                  )}
                </Form.Item>

                    <FormItem className="horizontal-center3"
                    style={{

                    }}
                    >

                    {this.state.message}
                    <button
                      type="button"
                      style={{marginRight:15}}
                      onClick={this.props.toggleShowForgotPassword}
                      className="btn btn-warning"
                    >
                      Luk
                    </button>


                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      Sende
                    </button>


                    <br/>
                    <br/>

                    </FormItem>

                </Form>
        </div>


            }
      </div>
    );
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPasswordForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password) => dispatch(actions.authLogin(username, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedResetPasswordForm);
