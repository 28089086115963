import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import '../css/myCss.css'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { Row, Col } from 'antd';

import kastrupTrophy from '../img/newTrophies/airplane.png'
import dagligstuenTrophy from '../img/newTrophies/sofa.png'
import vaerelsetTrophy from '../img/newTrophies/room.png'
import kokkenetTrophy from '../img/newTrophies/food-and-restaurant.png'
import klassevaerelsetTrophy from '../img/newTrophies/teacher.png'
import spisgodtTrophy from '../img/newTrophies/shopping-basket.png'
import cafeirisTrophy from '../img/newTrophies/coffee.png'
import ledigtcafebordTrophy from '../img/newTrophies/dinner-table.png'
import superchikTrophy from '../img/newTrophies/clothing-shop.png'

import skolegardenfinal from '../img/scenes/skolegardenfinal.png';



import rightArrow from '../img/right-arrowWhite2.png'


class Directions extends React.Component {

  render() {
    return (
     <div style={{
       width:"100%",
       height:"100%",
     }}>

       <div>


       {/* 1 */}
       <Row style={{width: "100%", marginBottom: 10}}>
        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
           <img alt="" style={{width:"33%"}} src={kastrupTrophy}/>
           <div>Kastrup Lufthavn</div>
         </Col>

         <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
          <img alt="" style={{width:"33%"}} src={rightArrow}/>
          <div> Gå til </div>
         </Col>

         <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
          <img alt="" style={{width:"33%"}} src={dagligstuenTrophy}/>
          <div>Dagligstuen </div>
         </Col>
      </Row>


      {/* 2 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={dagligstuenTrophy}/>
         <div>Dagligstuen </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={klassevaerelsetTrophy}/>
         <div>Klasseværelset </div>
        </Col>
     </Row>


      {/* 3 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={klassevaerelsetTrophy}/>
         <div>Klasseværelset </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={skolegardenfinal}/>
         <div>Skolegården </div>
        </Col>
     </Row>

      {/* 4 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={skolegardenfinal}/>
         <div>Skolegården </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={klassevaerelsetTrophy}/>
         <div>Klasseværelset </div>
        </Col>
     </Row>

      {/* 5 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={klassevaerelsetTrophy}/>
         <div> Klasseværelset </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={cafeirisTrophy}/>
         <div> Café Kult </div>
        </Col>
      </Row>

      {/* 6 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={cafeirisTrophy}/>
         <div> Café Kult </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={ledigtcafebordTrophy}/>
         <div> Ledigt Bord </div>
        </Col>
      </Row>

      {/* 7 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={ledigtcafebordTrophy}/>
         <div> Ledigt Bord </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={vaerelsetTrophy}/>
         <div> Idas værelse </div>
        </Col>
      </Row>

      {/* 8 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={vaerelsetTrophy}/>
         <div> Idas værelse </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={superchikTrophy}/>
         <div> Supertøj </div>
        </Col>
      </Row>

      {/* 9 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={superchikTrophy}/>
         <div> Supertøj </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={vaerelsetTrophy}/>
         <div> Idas værelse </div>
        </Col>
      </Row>


      {/* 10 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={vaerelsetTrophy}/>
         <div> Idas værelse </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={kokkenetTrophy}/>
         <div> Køkkenet </div>
        </Col>
      </Row>

      {/* 11 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={kokkenetTrophy}/>
         <div> Køkkenet </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={spisgodtTrophy}/>
         <div> Mad </div>
        </Col>
      </Row>


      {/* 12 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={spisgodtTrophy}/>
         <div> Mad </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={kokkenetTrophy}/>
         <div> Køkkenet </div>
        </Col>
      </Row>




      {/* 13 */}
      <Row style={{width: "100%", marginBottom: 10}}>

      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={kokkenetTrophy}/>
         <div> Køkkenet </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={spisgodtTrophy}/>
         <div> Mad </div>
        </Col>

      </Row>



      {/* 14 */}
      <Row style={{width: "100%", marginBottom: 10}}>
      <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={spisgodtTrophy}/>
         <div> Mad </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={rightArrow}/>
         <div> Gå til </div>
        </Col>

        <Col xs={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 4 }}>
         <img alt="" style={{width:"33%"}} src={kokkenetTrophy}/>
         <div> Køkkenet </div>
        </Col>
      </Row>


      {/*
        1 Kastrup Lufthavn
        2 Familien Skov – dagligstuen
        3 Københavns Gymnasium - klasseværelset
        4 Københavns Gymnasium - skolegården
        5 Københavns Gymnasium - klasseværelset
        6 Cafe Kult
        7 Ledigt bord
        8 Familien Skov - Idas værelse
        9 Megazin - Supertøj
        10 Familien Skov - Idas værelse
        11 Familien Skov - Køkkenet
        12 Vest supermarked - Mad

        13 Familien Skov - Køkkenet
        14 Vest supermarked - Mad
        15 Familien Skov - Køkkenet


        1 Familien Skov - dagligstuen
        2 Københavns Gymnasium - klasseværelset
        3 Københavns Gymnasium - skolegården
        4 Københavns Gymnasium - klasseværelset
        5 Cafe Kult
        6 Ledigt bord
        7 Familien Skov - Idas værelse
        8 Megazin - Supertøj
        9 Familien Skov - Idas værelse
        10 Familien Skov - Køkkenet
        11 Vest supermarked - Mad
        12 Familien Skov - Køkkenet

        13 Vest supermarked - Mad
        14 Familien Skov - Køkkenet


        */}

       </div>

      {/*
        <img alt="" src={directionsImage} />
      */}



     </div>

      );

  }
}


const mapStateToProps = state => {
  //console.log("scene mapStateToProps")
  //let st = state.token === null ? null : state.token
  //console.log(st)
  return {
    isAuthenticated: state.token !== null,
    token: state.token === null ? null : state.token,
  }
}

const mapDispatchToProps = dispatch => {
  //console.log("scene mapDispatchToProps:")
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Directions);
