
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import Login from './containers/Login';
//import Script from "./containers/useScript";

import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

import thunk from 'redux-thunk';

import reducer from './store/reducers/auth';
import "./css/index.css";
import 'antd/dist/antd.css';
// import './css/myCss.css'
//var {registerObserver} = require('react-perf-devtool')
//setja þetta í index.html innan í public folder:
//<script src="https://code.responsivevoice.org/responsivevoice.js?key=1aK7b2PY"></script>


const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhances(
  applyMiddleware(thunk)
));

const routing = (
  <Provider store={store}>
    <App/>
  </Provider>

);

//    <Script src='https://code.responsivevoice.org/responsivevoice.js?key=1aK7b2PY'/>
// const bla = (
//   const script = document.createElement("script");
//   script.src = "https://code.responsivevoice.org/responsivevoice.js?key=1aK7b2PY";
//   script.async = true;
//   //document.body.appendChild(script);
// );

//registerObserver()

ReactDOM.render(routing, document.getElementById("root"));



/*import React from 'react';
import ReactDOM from 'react-dom';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'antd/dist/antd.css';
import myCss from './myCss.css';

//import '@fortawesome/fontawesome-free/css/all.min.css';
//import 'bootstrap-css-only/css/bootstrap.min.css';
//import 'mdbreact/dist/css/mdb.css';
//import 'font-awesome/css/font-awesome.min.css';

import App from './App';


//import { library } from '@fortawesome/fontawesome-svg-core'
//import { fab } from '@fortawesome/free-brands-svg-icons'
//import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
//library.add(fab, faCheckSquare, faCoffee)


//import './index.css';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import reducer from './store/reducers/auth';

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhances(
  applyMiddleware(thunk)
));

const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

*/
