
import React from 'react';

const om1 = [
  "N.B. Talerum fungerer bedst, når Chrome bruges som browser.",
  "Gev gætur, at Talerum riggar best, tá ið brúkar Chrome sum kaga.",
  "Chrome browserigalugu Talerumip ingerlanera ajunnginnerusarpoq.",
  "Athugaðu að Talerum virkar best í Chrome-vafranum."
]


const om2 = [
  "Talerum er et interaktivt computerspil, hvor elever med andre modersmål end dansk får lejlighed til at udtrykke sig på dansk i hverdagssituationer. Spillet er udviklet for færøske, grønlandske og islandske elever og har til formål at fremme deres produktive brug af dansk.",
  "Talerum er eitt samvirkið telduspæl, har næmingar við øðrum móðurmáli enn donskum kunnu læra seg betur danskt við at nýta tað í gerandisstøðum.",

  "Talerum computerspiliuvoq akissutinnut akisartoq atuartut qallunaatut oqaaseqanngitsut ulluinnarni pisartut aallaavigalugit qallunaatut ilinniarfigisinnaasaat. Spili Savalimmiuni, Kalaallit Nunaanni Islandimilu atuartunut sanaajuvoq, atuartut qallunaat oqalussinnaanerat allassinnaanerallu pitsanngorsarniarlugu sanaajulluni.",

  "Talerum er gagnvirkur tölvuleikur sem gefur nemendum með annað móðurmál en dönsku tækifæri til að nota málið í daglegum aðstæðum. Leikurinn var gerður fyrir færeyska, grænlenska og íslenska nemendur með það að markmiði að auka tækifæri þeirra til að tjá sig á dönsku.",
]

const om3 = [
  "Historien er, at spilleren kommer til København og skal bo hos en værtsfamilie, familien Skov. Værtsfamilien og andre sætter spilleren til at løse en række opgaver. Programmet stiller spørgsmål og reagerer på elevernes svar, og det tilpasser sig deres niveau. Brugerne kan skrive fri tekst og formulere deres svar forholdsvis frit inden for Talerums univers. Spillet består af et kort over København, som indeholder en række steder og scener, hvor man skal kommunikere på dansk i dialog. Programmet vejleder spilleren om spillets gang, og om hvordan man kan komme fra en situation til en anden. Under menuen længst til venstre over kortet kan man følge med i, hvor langt man er nået i spillet.",

  "Søgan er tann, at næmingurin/spælarin kemur til Keypmannahavnar og skal búgva hjá einum vertshúski, húskinum Skov. Húskið Skov setur næmingin/spælaran/gestin at loysa fleiri uppgávur, men næmingurin kann eisini sjálvur finna sær nýggjar samrøður. Forritið spyr spurningar og hoyrir svarini frá næmingunum, og tað lagar seg eftir teirra búningarstigi. Brúkararnir kunnu skriva frían tekst og orða svarið rættiliga frítt innan fyri heimin hjá Talerum. Spælið hevur eitt kort, sum vísir Keypmannahavn, og sum hevur mong støð og mangar leikmyndir, har prátað verður á donskum í samrøðu. Tú kanst flyta teg, sum tú vilt, men tú varnast skjótt, at ávísar leikmyndir skulu gerast lidnar, áðrenn tú kanst halda fram við øðrum.",

  "Pisuusaartitaq tassaavoq spilertoq Københavnimut pisoq ilaqutarsiaqartussaasorlu, ilaqutariit Skovikkut ilaqutarsiaralugit. Ilaqutariit Skovikkut spilertumut suliassiisassapput, spilertorli nammineq aamma oqaluuserisassanik nassaartorsinnaasassalluni. Atuisut allakkusutaminnik allassinnaapput Talerumillu silarsuaani akissutigerusutatik aallaavigalugit allassinnaallutik. Spilimi sumiiffik tassaavoq København assigiinngitsunik ornitassartalik assigiinngitsunillu pisuusaartitanik misiliiffissartalik, taakkunanilu qallunaatut oqaloqatigiinneq allaqatigiinnikkut pisariaqarpoq. Spilertoq aqqutissiorneqartassaaq. Menumi saamiup tungaaniittumi sumut killinnerlutit takusinnaavat.",

  "Atburðarásin er þannig að spilarinn kemur til Kaupmannahafnar og á að búa á heimili gestgjafa sinna, Skov-fjölskyldunnar. Gestgjafarnir fela spilaranum að leysa ýmis verkefni. Forritið spyr spurninga og bregst við svörum nemenda, og það lagar sig að getustigi þeirra. Spilararnir geta skrifað eigin texta og orðað svör sín tiltölulega frjálst innan ramma Talerums. Leikurinn sýnir kort af Kaupmannahöfn, þar sem er að finna nokkra staði og aðstæður, þar sem spilararnir þurfa að taka þátt í samtali á dönsku. Forritið leiðbeinir spilaranum, hvernig hann kemst í gegnum leikinn með því að fara af einum stað á annan. Hægt er að fylgjast með gangi leiksins í yfirlitinu sem sýnt er lengst til vinstri á skjámyndinni.",
]


const om4 = [
  "Spillerne har adgang til online ordbøger mellem grønlandsk og dansk, færøsk og dansk og islandsk og dansk. Talerum tilbyder også talesyntese og lydoptagelser. Ved hjælp af talesyntese kan eleven få læst ord og sætninger op blot ved at markere dem. Man skal dog være opmærksom på, at talesyntese på dansk stadig har visse begrænsninger. Eleverne kan også indtale ord og sætninger for at høre, hvordan deres dansk lyder.",
  "Talerum hevur eisini talusyntesu og ljóðupptøkur. Við talusyntesu til hjálpar kann næmingurin fáa orð og setningar lisin upp bara við at frámerkja tey. Tó verður havt í huga, at talusyntesa á donskum framvegis hevur ávísar avmarkingar. Næmingarnir kunnu eisini tala orð og setningar inn fyri at hoyra, hvussu teirra danska ljóðar.",
  "Spilertut nittartakkakkut ordbogit uku atorsinnaavaat: kalaallisuumiit qallunaatuumut, savalimmiormiusuumiit qallunaatuumut islandimiusiimiillu qallunaatuumut (kingulliit marluk ISLEX aqqutigalugu).",
  "Spilararnir hafa aðgang að veforðabók á milli íslensku og dönsku (ÍSLEX) auk þess sem Talerum býður upp á að nota talgervil og hljóðupptökur. Með því að auðkenna textann geta spilararnir heyrt talgervilinn lesa yfirstrikaða textann á dönsku. Mikilvægt er þó að átta sig á því, að framburður talgervilsins er ekki alveg í samræmi við dönsku eins og hún hljómar í munni Dana. Spilararnir geta einnig tekið upp orð og setningar til að heyra hvernig þeir tala dönsku.",
]

const om5 = [
  "Programmet Talerum er udviklet på Vigdís Finnbogadóttir Instituttet for Fremmedsprog ved Islands Universitet i samarbejde med dansklærere og elever i færøske, grønlandske og islandske skoler. Leder af projektet er professor i dansk Auður Hauksdóttir. Programmør på projektet er it-ingeniør Björn Þorvarðarson. Sabine Kirchmeier, fhv. direktør for Dansk Sprognævn, er sprogteknologisk rådgiver. Samtlige billeder er designet af MA Sinéad McCarron. Andre bidragsydere er folkeskolelærer Sine Dyrbye, cand.mag. Oskar Larsen og seniorforsker i Dansk Sprognævn Peter Juel Henrichsen. Talesyntesen er leveret af Responsive Voice under ikkekommerciel licens. Projektet har modtaget støtte fra Nordplus Sprog og Kultur, det danske og det islandske undervisningsministerium (samarbejdsaftalen om danskundervisning) og Islands Universitets Forskningsfond.",
  "Spælið er gjørt til føroyskar, grønlendskar og íslendskar næmingar og hevur til endamáls at læra teir betur at tosa danskt. Forritið er ment á Háskóla Íslands í samstarvi við dansklærarar og næmingar í føroyskum, grønlendskum og íslendskum skúlum. Á odda fyri verkætlanini stendur Auður Hauksdóttir, professari. Forritari í verkætalini er Björn Þorvarðarson, kt-verkfrøðingur. Sabine Kirchmeier, fyrrv. stjóri í Dansk Sprognævn, er máltøkniligur ráðgevi. Sinéad McCarron, MA, hevur sniðgivið allar myndirnar. Talusyntesuna hevur Responsive Voice latið undir ikki-handilsligum loyvi. Aðrir stuðulsveitarar eru fólkaskúlalærarin Sine Dyrbye, Oskar Larsen, cand. mag., og Peter Juel Henrichsen, granskari í Dansk Sprognævn. Verkætlanin hevur fingið stuðul frá Nordplus Sprog og Kultur, danska og íslendska undirvísingarmálaráðnum (samstarvsavtalan um danskundirvísing) og Rannsóknasjóði Háskóla Íslands.",
  "Talerumimi atuffassissuteqarpoq aamma nipinik immiussissuteqarluni. Atuartup oqaatsit oqaaseqatigiillu markererlugit atuffassissut atuartissinnaavaa. Ilisimaneqassaarli atuffassissutip qallunaatoorsinnaanera aamma killeqarmat. Atuartut qallunaatut qanoq oqalloritsiginertik tusaaniarlugu oqaatsinik oqaaseqatigiinnillu aamma immiussisinnaapput. Programi Vigdís Finnbogadóttir Instituttet for Fremmedsprog ved Islands Universitetimi qallunaatut ilinniartitsisunik Savalimmiuni, Kalaallit Nunaanni Islandimilu atuarfinni atuartunik suleqateqarluni suliaavoq. Qallunaatut oqaatsinut professori Auður Hauksdóttir suliamik aqutsisuuvoq. IT-ingeniøri Björn Þorvarðarson suliami programmøriuvoq. Qarasaasiaq atorlugu oqaasilerinermi Sabine Kirchmeier, Dansk Sprognævnimi direktøriusimasoq, siunnersuisuuvoq. Assit tamarmik MA Sinéad McCarronimit suliarineqarput. Atuffassissut Responsive Voicemit ikkekommerciel licensilerlugu tunniunneqarpoq. Suliamut suleqataasut allat tassaapput, ilinniartitsisoq Sine Dyrbye, cand.mag. Oskar Larsen Dansk Sprognævnimilu siunnersortaaneq Peter Juel Henrichsen. Suliaq Nordplus Sprog og Kullturimit, Danmarkimi Islandimilu ilinniartitaanermut naalakkersuisoqarfimmit (qallunaatut oqaatsit pillugit suleqatigiinnissamik isumaqatigiissut) Islands Universitets Forskingsfondimiillu aningaasaliiffigineqarpoq.",
  "Tölvuleikurinn Talerum var þróaður innan Stofnunar Vigdísar Finnbogadóttur í erlendum tungumálum við Háskóla Íslands í samvinnu við færeyska, grænlenska og íslenska dönskukennara og nemendur þeirra. Auður Hauksdóttir, prófessor í dönsku, hefur farið fyrir verkefninu. Björn Þorvarðarson tölvuverkfræðingur annast alla forritun, en Sabine Kirchmeier, fv. forstöðumaður Dönsku málnefndarinnar, hefur veitt ráðgjöf við máltæknilegar lausnir. Allar myndir eru unnar af Sinéad McCarron, MA í hönnun. Aðrir sem lagt hafa verkefninu lið eru Sine Dyrbye grunnskólakennari, Oskar Larsen cand. mag. og Peter Juel Henrichsen, fræðimaður hjá Dönsku málnefndinni. Þróun talgervilsins var í höndum Responsive Voice, sem góðfúslega heimilaði notkun hans, þar sem verkefnið er ekki unnið í hagnaðarskyni. Verkefnið hefur hlotið styrki frá Nordplus-áætluninni fyrir tungumál og menningu, Rannsóknasjóði Háskóla Íslands og frá danska og íslenska menntamálaráðuneytinu (samstarfssamningur um stuðning við dönskukennslu).",
]

const om6 = [
  "En stor tak til Undervisningsmiddelforlaget i Grønland og Sprogsekretariet samme sted, forlaget Sprotin på Færøerne og Árni Magnússon-instituttet for islandske studier i Island  for at stille deres ordbøger til rådighed for projektet.",
  "Vit takka hjartaliga Forlagnum fyri undirvísingartilfar og Málavgreiðslustovuni í Grønlandi, forlagnum Sprotanum í Føroyum og Árna Magnússon-stovninum fyri islandskt nám í Íslandi fyri at hava orðabøkurnar tøkar til verkætlanina.",
  "Kalaallit Nunaanni Ilinniusiorfik Oqaasileriffillu, Savalimmiuni Sprotin Islandimilu Árni Magnússon-institutsi suliamut ordbogiutitik artorsinnaatimmatigit qujaffigaagut.",
  "Sérstakar þakkir fær Stofnun Árna Magnússsonar fyrir að heimila notkun ÍSLEX-orðabókarinnar milli íslensku og dönsku. Þá er Námsgagnastofnuninni í Grænlandi og Málstöðinni þar í landi, ásamt forlaginu Sprotanum í Færeyjum, færðar sérstakar þakkir fyrir að heimila notkun orðabóka þeirra milli grænlensku og dönsku annars vegar og færeysku og dönsku hins vegar.",
]



export default function SimpleTabs(props) {

  return (
    <div className=""
    style={{
      //backgroundColor: "darkgray",
      width: "100%",
      height: "100%",
    }}
    >

      <h2 style={{fontWeight:"bold"}}> {om1[props.countryId-1]} </h2>
      <h3>
        {om2[props.countryId-1]}
      </h3>
      <br/>
      <h3>
        {om3[props.countryId-1]}
      </h3>

      <h3>
        {om4[props.countryId-1]}
      </h3>

      <h3>
        {om5[props.countryId-1]}
      </h3>

      <h3>
        {om6[props.countryId-1]}
      </h3>
      

    </div>
  );
}
