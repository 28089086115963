import axios from 'axios';
import * as actionTypes from './actionTypes';
import unfinishedTrophy from '../../img/trophies/graycup_031.png'

import kastrupTrophy from '../../img/newTrophies/airplane.png'
import dagligstuenTrophy from '../../img/newTrophies/sofa.png'
import klassevaerelsetTrophy from '../../img/newTrophies/teacher2.png'
import cafeirisTrophy from '../../img/newTrophies/coffee.png'
import ledigtcafebordTrophy from '../../img/newTrophies/dinner-table.png'


import matvorubudTrophyEitt from '../../img/newTrophies/shopping-basket1.png'
import spisgodtTrophy from '../../img/newTrophies/shopping-basket2.png'

import vaerelsetTrophyEitt from '../../img/newTrophies/room11.png'
import vaerelsetTrophy from '../../img/newTrophies/room22.png'

import superchikTrophy from '../../img/newTrophies/clothing-shop.png'
import spisestuenTrophy from '../../img/newTrophies/spisestuenImage.png'

import kokkenetTrophyEitt from '../../img/newTrophies/food-and-restaurant1.png'
import kokkenetTrophyTvo from '../../img/newTrophies/food-and-restaurant2.png'
import kokkenetTrophy from '../../img/newTrophies/food-and-restaurant3.png'

import klassevaerelsetTrophyEitt from '../../img/newTrophies/teacher1.png'




export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (token, hasProfile) => {
  //console.log("authSuccess before1")
  //console.log(token)
  //console.log(hasProfile)
  //console.log("authSuccess before2")

    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        hasProfile: hasProfile,
    }
}


export const getPinTrophiesAndGameFinished = (hasPin, trophies, gameFinished) => {
    //console.log("getPinTrophiesAndGameFinished3")
    //console.log(trophies)
    //console.log(hasPin)
    //console.log(gameFinished)

    return {
        type: actionTypes.GET_PIN_TROPHIES_AND_IF_GAME_FINISHED,
        trophies: trophies,
        hasPin: hasPin,
        gameInstanceFinished: gameFinished,
    }
}


export const getPinAndTrophies = (hasPin, trophies, hasProfile) => {
    //console.log("getPinAndTrophies3")
    //console.log(trophies)
    //console.log(hasPin)

    return {
        type: actionTypes.GET_PIN_AND_TROPHIES,
        trophies: trophies,
        hasProfile: hasProfile,
        hasPin: hasPin,
    }
}




export const getIfGameFinished = (gameInstanceFinished) => {
    //console.log("gameInstanceFinished3")
    //console.log(gameInstanceFinished)
    return {
        type: actionTypes.GET_IF_GAME_FINISHED,
        gameInstanceFinished: gameInstanceFinished,
    }
}

export const getProfileCountry = (profileCountry) => {
    //console.log("getProfileCountry1")
    //console.log(profileCountry)
    return {
        type: actionTypes.GET_PROFILE_COUNTRY,
        profileCountry: profileCountry,
    }
}


export const getTrophies = (trophies, hasProfile) => {
    //console.log("getTrophies3")
    //console.log(trophies)
    return {
        type: actionTypes.GET_TROPHIES,
        trophies: trophies,
        hasProfile: hasProfile,
    }
}


export const getPin = (hasPin) => {
    //console.log("getPin hasPin")
    //console.log(hasPin)
    return {
        type: actionTypes.GET_PIN,
        hasPin: hasPin,
    }
}


export const pinInfo = () => {
      //console.log("hasPin?")
      return dispatch => {
          //dispatch(profileStart());
          const token = localStorage.getItem('token');
          axios.defaults.headers = {
             "Accept": "application/json",
             "Content-Type": "application/json",
             'Authorization': "Token " + token
          }

         axios.get(actionTypes.API_URL + 'api/hasPin')
         .then(res => {
             const hasPin = res.data[0].hasPin
             //console.log("hasPin auth.js: ")
             //console.log(hasPin)
             if (hasPin) {
               //console.log("setting pin")
               localStorage.setItem('hasPin', true)
             }
             //const h = localStorage.getItem('hasPin');
             //console.log("h pin: ")
             //console.log(h)
             dispatch(getPin(hasPin));
         })
         .catch(err => {
           //console.log("profileFail")
             dispatch(authFail(err))
         })
      }
  }



export const authFail = error => {
  //console.log("fail2")
  //console.log(error)
  //console.log(error.response)
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
        loading: false,
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('hasProfile')
    return {
        type: actionTypes.AUTH_LOGOUT
    };
}

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
}

export const authLogin = (username, password) => {
    return dispatch => {
        console.log("authLogin:")
        dispatch(authStart());
        axios.post(actionTypes.API_URL + 'rest-auth/login/', {
            username: username,
            password: password
        })
        .then(res => {
            console.log("res:")
            console.log(res)
            const token = res.data.key;
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
            localStorage.setItem('token', token);
            localStorage.setItem('expirationDate', expirationDate);

            dispatch(hasProfile())

            //dispatch(authSuccess(token, hasProfile));
        })
        .catch(err => {
          console.log("err login")
          console.log(err)
          // if (err.response) {
          //   console.log("data")
          //   console.log(err.response.data);
          //   console.log("status")
          //   console.log(err.response.status);
          //   console.log("headers")
          //   console.log(err.response.headers);
          // }
          dispatch(authFail(err))
        })
    }
}

export const authSignup = (username, email, password1, password2) => {
    return dispatch => {
        //console.log("authSignup:")
        dispatch(authStart());
        axios.post(actionTypes.API_URL + 'rest-auth/registration/', {
            username: username,
            email: email,
            password1: password1,
            password2: password2
        })
        .then(res => {
            //console.log("registration")
            //console.log(res)
            const token = res.data.key;
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
            localStorage.setItem('token', token);
            localStorage.setItem('expirationDate', expirationDate);

            //console.log("token register")
            //console.log(token)

            dispatch(hasProfile())

            //done in hasProfile
            //dispatch(authSuccess(token, hasProfile));
            //dispatch(checkAuthTimeout(3600));


        })
        .catch(err => {
          // console.log("got error1:")
          // console.log(err)
          dispatch(authFail(err))


        })
    }
}

export const authCheckState = () => {
    return dispatch => {
      //console.log("authCheckState:")
        const token = localStorage.getItem('token');
        const hasProfile = localStorage.getItem('hasProfile');

        if (token === undefined || hasProfile === undefined) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if ( expirationDate <= new Date() ) {
                dispatch(logout());
            } else {
                //done in hasProfile
                //console.log("dispatching authSuccess2")
                dispatch(authSuccess(token, hasProfile));
                //dispatch(checkAuthTimeout( (expirationDate.getTime() - new Date().getTime()) / 1000) );
            }
        }
    }
}


export const profileStart = () => {
    return {
        type: actionTypes.PROFILE_START
    }
}

export const profileFail = error => {
    return {
        type: actionTypes.PROFILE_FAIL,
        error: error
    }
}






export const gameFinishedCheck = () => {
  return dispatch => {

  		axios.defaults.headers = {
  			 "Accept": "application/json",
  			 "Content-Type": "application/json",
  			 'Authorization': "Token " + localStorage.getItem('token')
  		}

  		axios.get(actionTypes.API_URL + 'api/gameInstanceFinished').then(res => {

        let finished = res.data.gameInstanceFinished
        //console.log("finishedGameInstance:")
        //console.log(finished)
        localStorage.setItem('gameInstanceFinished', finished)
        dispatch(getIfGameFinished(finished));

  		})
    }
}



export const getInfoPinTrophiesAndGameFinished = () => {
  return dispatch => {

    let token = localStorage.getItem('token')
    axios.all(
      [
        axios({
          method: 'get',
          url: actionTypes.API_URL + 'api/hasPin',
          withCredentials: true,
          headers: {
            "Authorization": 'Token ' + token,
          }
        }),
        axios({
          method: 'get',
          url: actionTypes.API_URL + 'api/finishedTrophies',
          withCredentials: true,
          headers: {
            "Authorization": 'Token ' + token,
          }
        }),
        axios({
          method: 'get',
          url: actionTypes.API_URL + 'api/gameInstanceFinished',
          withCredentials: true,
          headers: {
            "Authorization": 'Token ' + token,
          }
        })
      ]
    )
    .then(axios.spread((pinResponse, trophiesResponse, gameInstanceFinished) => {

      //console.log("results getInfoPinTrophiesAndGameFinished:")
      //console.log(pinResponse.data)
      //console.log(trophiesResponse.data)
      //console.log(gameInstanceFinished.data)
      let finishedTrophyIds = trophiesResponse.data.finishedTrophies
      let correctTrophies = []
      for (var t in trophies) {
        let inArray = finishedTrophyIds.indexOf(trophies[t].sceneId) > -1

        if(inArray) {
          correctTrophies.push(trophies[t])
          //console.log(trophies[t].sceneId)
          //console.log("finished")
        }
        else {
          correctTrophies.push({"sceneId": trophies[t].sceneId, "trophy": unfinishedTrophy})
          //console.log(trophies[t].sceneId)
          //console.log("not finished")
        }

      }

      //console.log("trophies")
      //console.log(trophies)
      //console.log("correctTrophies")
      //console.log(correctTrophies)
      //let hasP = localStorage.getItem('hasProfile')

      localStorage.setItem('trophies', correctTrophies)


      const hasPin = pinResponse.data.hasPin
      //console.log("hasPin auth.js: ")
      //console.log(hasPin)

      if (hasPin) {
        //console.log("setting pin")
        localStorage.setItem('hasPin', true)
      }

      const isFinished = gameInstanceFinished.data.gameInstanceFinished
      if (isFinished) {
        //console.log("setting gameInstanceFinished")
        localStorage.setItem('gameInstanceFinished', true)
      }


      dispatch(getPinTrophiesAndGameFinished(hasPin, correctTrophies, isFinished));



  }))

    }
}


export const updatePinAndTrophies = () => {
  return dispatch => {

    let token = localStorage.getItem('token')
    axios.all(
      [
        axios({
          method: 'get',
          url: actionTypes.API_URL + 'api/hasPin',
          withCredentials: true,
          headers: {
            "Authorization": 'Token ' + token,
          }
        }),
        axios({
          method: 'get',
          url: actionTypes.API_URL + 'api/finishedTrophies',
          withCredentials: true,
          headers: {
            "Authorization": 'Token ' + token,
          }
        })
      ]
    )
    .then(axios.spread((pinResponse, trophiesResponse) => {

      //console.log(pinResponse.data)
      //console.log(trophiesResponse.data)
      let finishedTrophyIds = trophiesResponse.data.finishedTrophies
      let correctTrophies = []
      for (var t in trophies) {
        let inArray = finishedTrophyIds.indexOf(trophies[t].sceneId) > -1
        if(inArray) {
          correctTrophies.push(trophies[t])
        }
        else {
          correctTrophies.push({"sceneId": trophies[t].sceneId, "trophy": unfinishedTrophy})
        }
      }

     //console.log("correctTrophies")
     //console.log(correctTrophies)
     let hasP = localStorage.getItem('hasProfile')

     localStorage.setItem('trophies', correctTrophies)


     const hasPin = pinResponse.data.hasPin
     //console.log("hasPin auth.js: ")
     //console.log(hasPin)

     if (hasPin) {
       //console.log("setting pin")
       localStorage.setItem('hasPin', true)
     }

     dispatch(getPinAndTrophies(hasPin, correctTrophies, hasP));



  }))

    }
}


const trophies = [
	{"sceneId": 2, "trophy": kastrupTrophy},
	{"sceneId": 7, "trophy": cafeirisTrophy},
	{"sceneId": 8, "trophy": ledigtcafebordTrophy},
	{"sceneId": 9, "trophy": dagligstuenTrophy},
	{"sceneId": 11, "trophy": klassevaerelsetTrophy},


  {"sceneId": 15, "trophy": klassevaerelsetTrophyEitt},

  {"sceneId": 16, "trophy": superchikTrophy},

  {"sceneId": 16.5, "trophy": matvorubudTrophyEitt},
  {"sceneId": 13, "trophy": spisgodtTrophy},

  {"sceneId": 18, "trophy": spisestuenTrophy},

  {"sceneId": 19, "trophy": kokkenetTrophy},
  {"sceneId": 20, "trophy": kokkenetTrophyTvo},
  {"sceneId": 21, "trophy": kokkenetTrophyEitt},


  {"sceneId": 17, "trophy": vaerelsetTrophy},
  {"sceneId": 24, "trophy": vaerelsetTrophyEitt},



]

export const updateProfileCountry = () => {
  return dispatch => {

    axios.defaults.headers = {
      "Accept": "application/json",
  		"Content-Type": "application/json",
  		'Authorization': "Token " + localStorage.getItem('token')
  	}

  	axios.get(actionTypes.API_URL + 'api/profileInfo').then(res => {
      //console.log("profileInfo:")
      //console.log(res.data.hjemsted)

      let profileCountry = res.data.hjemsted
      localStorage.setItem('profileCountry', profileCountry)
      dispatch(getProfileCountry(profileCountry));
  		})
    }
}

/*
export const updateLanguage = () => {
  return dispatch => {

    axios.defaults.headers = {
      "Accept": "application/json",
  		"Content-Type": "application/json",
  		'Authorization': "Token " + localStorage.getItem('token')
  	}

  	axios.get(actionTypes.API_URL + 'api/getLanguage').then(res => {
      let language = res.data.language
      console.log("language: ")
  	  console.log(language)

      localStorage.setItem('language', language)
      dispatch(getLanguage(language));
  		})
    }
}*/

export const updateTrophies = () => {
  return dispatch => {

    axios.defaults.headers = {
      "Accept": "application/json",
  		"Content-Type": "application/json",
  		'Authorization': "Token " + localStorage.getItem('token')
  	}

  	axios.get(actionTypes.API_URL + 'api/finishedTrophies').then(res => {
      let finishedTrophyIds = res.data.finishedTrophies
      //console.log("trophies")
      //console.log(res.data)
      //console.log("finishedTrophies: ")
  	  //console.log(finishedTrophyIds)

      let correctTrophies = []
  		for (var t in trophies) {
  	    //console.log("t: ")
        //console.log(t)
        let inArray = finishedTrophyIds.indexOf(trophies[t].sceneId) > -1
        if(inArray) {
          correctTrophies.push(trophies[t])
        }
        else {
          correctTrophies.push({"sceneId": trophies[t].sceneId, "trophy": unfinishedTrophy})
        }
      }

      //console.log("correctTrophies")
      //console.log(correctTrophies)
      let hasP = localStorage.getItem('hasProfile')
      localStorage.setItem('trophies', correctTrophies)
      dispatch(getTrophies(correctTrophies, hasP));
  		})
    }
}


export const hasProfile = () => {
  //console.log("hasProfile?")
    return dispatch => {
        //dispatch(profileStart());
        const token = localStorage.getItem('token');
        //console.log("token hasProfile")
        //console.log(token)

        axios.defaults.headers = {
           "Accept": "application/json",
           "Content-Type": "application/json",
           'Authorization': "Token " + token
        }

       axios.get(actionTypes.API_URL + 'api/hasProfile')
       .then(res => {

           const hasProfile = res.data[0].hasProfile
           //console.log("hasProfile auth.js: ")
           //console.log(hasProfile)

           if (hasProfile) {
             //console.log("setting1")
             localStorage.setItem('hasProfile', true)
           }
           const h = localStorage.getItem('hasProfile');
           //console.log("h: ")
           //console.log(h)
           dispatch(authSuccess(token, h));
           dispatch(checkAuthTimeout(3600));
       })
       .catch(err => {
         //console.log("profileFail")
         dispatch(authFail(err))
       })
    }
}
