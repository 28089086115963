import React, {Component}          from 'react';
//import { render }                  from 'react-dom';
import start                from '../img/start.svg';
import stop               from '../img/stop.svg';
import pause                   from '../img/pause.svg';
import { ReactMic } from 'react-mic';
//require ('../recStyles.css');
import start1 from '../img/start1.png'
import stop1 from '../img/stop1.png'

class MyMicrophone extends Component {
  constructor(props){
    super(props);
    this.state = {
      blobObject: null,
      isRecording: false,
      isPaused: false
    }
  }

  componentDidMount() {

  }

  startOrStopRecording= () => {
    const { isRecording } = this.state

    if(isRecording) {
      this.setState({ isRecording: false })
    }
    else {
      this.setState({ isRecording: true })
    }
  }

  startOrPauseRecording= () => {
    const { isPaused, isRecording } = this.state

    if(isPaused) {
      this.setState({ isPaused: false })
    } else if(isRecording) {
      this.setState({ isPaused: true })
    } else {
      this.setState({ isRecording: true })
    }
  }

  stopRecording= () => {
    this.setState({ isRecording: false });
  }

  onSave=(blobObject) => {
  }

  onStart=() => {
    //console.log('You can tap into the onStart callback');
  }

  onStop= (blobObject) => {
    this.setState({ blobURL : blobObject.blobURL });
  }

  onData(recordedBlob){
    //console.log('ONDATA CALL IS BEING CALLED! ', recordedBlob);
  }

  render() {
    const { blobURL, isRecording, isPaused } = this.state;

    /*const getImage = () => {
      if(isRecording && !isPaused) {
        return (`url(${pause})`)
      }
      else {
        return (`url(${start})`)
      }
    }*/
//visualSetting="false"
/*
  <ReactMic
    record={isRecording}
    pause={isPaused}
    visualSetting="sinewave" // defaults -> "sinewave".  Other option is "frequencyBars"            audioBitsPerSecond= {128000}
    onStop={this.onStop}
    onStart={this.onStart}
    onSave={this.onSave}
    strokeColor="#000000" />
*/
    return(
        <div
        style={{
          marginLeft: 15,
          width: "100%",
        }}
        >

          <ReactMic
            record={isRecording}
            pause={isPaused}
            visualSetting="sinewave" // defaults -> "sinewave".  Other option is "frequencyBars"            audioBitsPerSecond= {128000}
            onStop={this.onStop}
            onStart={this.onStart}
            onSave={this.onSave}
            className=""

            strokeColor="#000000"
            backgroundColor="#f0f0f0"
            mimeType="audio/mp3"     // defaults -> "audio/webm".  Set to "audio/wav" for WAV or "audio/mp3" for MP3 audio format (available in React-Mic-Gold)
            echoCancellation={true} // defaults -> false
            autoGainControl={true}  // defaults -> false
            noiseSuppression={true} // defaults -> false
            channelCount={2}     // defaults -> 2 (stereo).  Specify 1 for mono.
            bitRate={256000}          // defaults -> 128000 (128kbps).  React-Mic-Gold only.
            sampleRate={96000}        // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000 (available in React-Mic-Gold)
            timeSlice={3000}          // defaults -> 4000 milliseconds.  The interval at which captured audio is returned to onData callback (available in React-Mic-Gold).
            />

          <br/>

          <audio ref="" controls="controls" src={blobURL}></audio>

          <br/>
          <br/>

          <button
            className=""
            style={{
              border: 0,
              backgroundSize: "100%",
              backgroundImage: `url(${isRecording ? stop1 : start1})`,
              width:40,
              height:40,
            }}
            onClick={this.startOrStopRecording}>
          </button>
{/*
  <button
    className="btn btn-light recButton"
    style={{
      backgroundImage: `url(${isRecording && !isPaused? pause : start})`,
      width:40,
      height:40,
    }}
    onClick={this.startOrPauseRecording}>
  </button>

  <button
    className="btn btn-light recButton"
    disabled={!isRecording}
    style={{
      backgroundImage: `url(${stop})`,
      width:40,
      height:40,
    }}
    onClick={this.stopRecording}>
  </button>
*/}
          <br />
        </div>
    );
  }
}

export default MyMicrophone;
