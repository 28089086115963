import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import axios from 'axios';
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';
import * as actionTypes from '../store/actions/actionTypes';

class ResetButton extends React.Component {


  state = {

  }

  resetGame = () => {
    //console.log("resetting game clicked:")
    axios.defaults.headers = {
       "Accept": "application/json",
       "Content-Type": "application/json",
       'Authorization': "Token " + this.props.token
    }
    axios.get(actionTypes.API_URL + 'api/resetGameInstance').then(res => {
      let created = res.data.created
      //console.log("created new game instance:")
      //console.log(created)
      //send user to map, new game started
      if(created) {
        this.props.resetGame()
      }

    })
  }


  render() {



    return (
      <button className={`btn btn-danger ${this.props.btnClass}`}
        onClick={this.resetGame}
        style={{marginLeft:10, }}
      >
        Begynd forfra
      </button>
    );

  }
}
/*

*/

const mapStateToProps = state => {
  //console.log("scene mapStateToProps")
  //let st = state.token === null ? null : state.token
  //console.log(st)
  return {
    isAuthenticated: state.token !== null,
    token: state.token === null ? null : state.token,
    hasPin: state.hasPin,
  }
}

const mapDispatchToProps = dispatch => {
  //console.log("scene mapDispatchToProps:")
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetButton);
