import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    error: null,
    loading: null,
    hasProfile: null,
    trophies: [],
    hasPin: null,
    profileCountry: null,
}

const getPinTrophiesAndGameFinished = (state, action) => {
  //console.log("getPinTrophiesAndGameFinished2")
  //console.log(action.trophies)
  //console.log(action.pin)
  //console.log(action.gameInstanceFinished)
  return updateObject(state, {
      ...state,
      trophies: action.trophies,
      gameInstanceFinished: action.gameInstanceFinished,
      hasPin: action.hasPin,
  });
}


const getPinAndTrophies = (state, action) => {
  //console.log("getPinAndTrophies2")
  //console.log(action.trophies)
  //console.log(action.pin)
    return updateObject(state, {
        trophies: action.trophies,
        hasProfile: action.hasProfile,
        hasPin: action.hasPin,
    });
}



const getIfGameFinished = (state, action) => {
  //console.log("getIfGameFinished2")
  //console.log(action.gameInstanceFinished)
    return updateObject(state, {
      ...state,
      gameInstanceFinished: action.gameInstanceFinished,
    });
}



const getPin = (state, action) => {
  //console.log("getPin2")
  //console.log(state.hasPin)
    return updateObject(state, {
        ...state,
        hasPin: action.hasPin,
    });
}

const getTrophies = (state, action) => {
  //console.log("getTrophies2")
  //console.log(state.trophies)
    return updateObject(state, {
        ...state,
        trophies: action.trophies,
        hasProfile: action.hasProfile,
    });
}


const getProfileCountry = (state, action) => {
  //console.log("getProfileCountry")
  //console.log(action.profileCountry)
  return updateObject(state, {
      ...state,
      profileCountry: action.profileCountry,
  });
}


const authStart = (state, action) => {
  //console.log("authStart1")
    return updateObject(state, {
        error: null,
        loading: true
    });
}

const authSuccess = (state, action) => {
  //console.log("success1")
  //console.log(action.token)
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false,
        hasProfile: action.hasProfile,
    });
}

const authFail = (state, action) => {
    //console.log("fail1")
    //console.log(action)
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const authLogout = (state, action) => {
    //console.log("logout1")

    return updateObject(state, {
        token: null
    });
}



const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.GET_TROPHIES: return getTrophies(state, action);
        case actionTypes.GET_PROFILE_COUNTRY: return getProfileCountry(state, action);

        case actionTypes.GET_PIN: return getPin(state, action);
        case actionTypes.GET_PIN_AND_TROPHIES: return getPinAndTrophies(state, action);
        case actionTypes.GET_PIN_TROPHIES_AND_IF_GAME_FINISHED: return getPinTrophiesAndGameFinished(state, action);
        case actionTypes.GET_IF_GAME_FINISHED: return getIfGameFinished(state, action);

        default:
            return state;
    }
}

export default reducer;
