import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {connect} from 'react-redux';
//import MyMap2 from './containers/MyMap2'
//import MyMap from './containers/MyMap'

import * as actions from './store/actions/auth';
import GameLayout from './containers/GameLayout';
import MainPage from './containers/MainPage'
import ProfileForm from './containers/ProfileForm'
import ChangePasswordForm from './containers/ChangePasswordForm'
import ResetPassword from './containers/ResetPassword'
import PasswordRecovery from './containers/PasswordRecovery'
import ConfirmPassword from './containers/ConfirmPassword'


import {
  Route,
  Switch
} from "react-router-dom";
import Signup from './containers/Signup';
class App extends Component {


  componentDidMount() {
    this.props.onTryAutoSignup();
  }


  render() {
    return (

      <div className="App"
      style={{
        minHeight: 750,
      }}

      >

      <Router>

      <GameLayout {...this.props}>
          <div
            className="verdanaFont"

            style={{
              width:"100%",
              height:"100%",

          }}>
            <Switch>
              <Route exact path="/" component={MainPage} />
              <Route exact path='/signup/' component={Signup}/>
              <Route exact path='/profile/' component={ProfileForm}/>
              <Route exact path='/changePassword/' component={ChangePasswordForm}/>
              <Route exact path='/resetPassword/' component={ResetPassword}/>
              <Route exact path='/password-reset/:uid/:token' component={PasswordRecovery}/>

              <Route exact path='/verify-email/:token' component={ConfirmPassword}/>



            </Switch>
          </div>
      </GameLayout>
      </Router>

      </div>

    );
  }
}

/*

<Helmet
    encodeSpecialCharacters={true}
>
    <html lang="en" amp />

    <body className="root" />
    <title itemProp="name" lang="en">Talerum</title>
    <meta name="description" content="Helmet application" />
    <meta property="og:type" content="article" />



    <style type="text/css">{`
        body {
        }

        html, body {
          overflow: hidden;

        }
    `}</style>
</Helmet>

<Route exact path='/statistics/' component={Statistics}/>
<Route exact path='/map1/' component={MyMap}/>
<Route exact path='/map/' component={MyMap2}/>
<Route exact path='/originalMap/' component={OriginalMap}/>
<Route exact path='/originalMap2/' component={OriginalMap2}/>
<Route exact path='/mic/' component={MyMicrophone}/>

*/
/*
<Route path="/login" component={Login} />
<Route exact path='/profile/' component={Profile}/>
<Route exact path='/rec/' component={Rec}/>
<Route exact path='/logout/' component={Logout}/>
*/

const mapStateToProps = state => {
  //console.log("mapStateToPropsApp:")
  //if null then false, otherwise true
  return {
    isAuthenticated: state.token !== null,
    hasProfile: state.hasProfile,
  }
}

const mapDispatchToProps = dispatch => {
  //console.log("mapDispatchToPropsApp:")
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
/*
<Router>
<Game {...this.props}>
  <BaseRouter/>
  </Game>
  </Router>



<CustomLayout {...this.props}>
  <BaseRouter/>
  </CustomLayout>
  background-color: #EAF4F9;

*/
/*



*/
