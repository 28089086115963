import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import '../css/myCss.css'

import LoginScreen from './LoginScreen'
import GameBasic from './GameBasic'
import ProfileForm from './ProfileForm'

class MainPage extends React.Component {
	state = {
		userHasProfile: null,
	};

  componentWillUnmount() {
    // console.log("MainPage componentWillUnMount")
  }

	componentDidMount() {
		// console.log("mainPage componentDidMount")
    //console.log(this.props)
	}



	render() {
		return (
			  <div style={{
          width:"100%",
          height:"100%",
	      }}>

        { this.props.isAuthenticated ?
          (
            <div style={{
             width:"100%",
             height:"100%",
            }}>
            {
              this.props.hasProfile ?
              <div style={{
                width:"100%",
                height:"100%",
              }}>
                <GameBasic {...this.props}/>
              </div>

              :
              <div>
                <ProfileForm
								toggleClosePopup={false}
								{
									...this.props
								}/>
              </div>
            }

            </div>
          )

          :
          (
            <div
						style={{
						  width:"100%",
						  height:"100%",
						}}

						>
            <LoginScreen/>
            </div>
          )

        }

		    </div>
	   )
	}
}


const mapStateToProps = state => {
	return {
		isAuthenticated: state.token !== null,
		token: state.token,
    trophies: state.trophies,
    hasProfile: state.hasProfile,
    hasPin: state.hasPin,
	}
}


const mapDispatchToProps = dispatch => {
    //console.log("getTrophies1")
    return {
        getTrophies: () => dispatch(actions.updateTrophies()),
        getPin: () => dispatch(actions.getPin())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
