import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

/*
import kastrupTrophy from '../img/newTrophies/airplane.png'
import dagligstuenTrophy from '../img/newTrophies/sofa.png'
import vaerelsetTrophy from '../img/newTrophies/room.png'
import kokkenetTrophy from '../img/newTrophies/food-and-restaurant.png'
import klassevaerelsetTrophy from '../img/newTrophies/teacher.png'
import spisgodtTrophy from '../img/newTrophies/shopping-basket.png'
import cafeirisTrophy from '../img/newTrophies/coffee.png'
import ledigtcafebordTrophy from '../img/newTrophies/dinner-table.png'
import superchikTrophy from '../img/newTrophies/clothing-shop.png'
*/

import spisestuenTrophy from '../img/newTrophies/spisestuenImage.png'

import kastrupTrophy from '../img/icons/iconsScenes/iconKastrup.png'
import dagligstuenTrophy from '../img/icons/iconsScenes/iconDagligstuen.png'
import vaerelsetTrophy from '../img/icons/iconsScenes/iconVaerelset.png'
import kokkenetTrophy from '../img/icons/iconsScenes/iconKokkenet.png'
import klassevaerelsetTrophy from '../img/icons/iconsScenes/iconSkole.png'
import spisgodtTrophy from '../img/icons/iconsScenes/iconSupermarked.png'
import cafeirisTrophy from '../img/icons/iconsScenes/iconCafe.png'
import ledigtcafebordTrophy from '../img/icons/iconsScenes/iconLedigtBord.png'
import superchikTrophy from '../img/icons/iconsScenes/iconSupertoj.png'
import skolegardenImage from '../img/icons/iconsScenes/iconSkolegarden.png'

import trophyButton from '../img/trophyGold.png'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(4),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function LeftMenu(props) {
  const classes = useStyles();


  const getTrophyImage = (pk) => {
    //console.log('getTrophyImage');
    //console.log(pk)
    //console.log(props.trophies)
    let imageName = props.trophies[pk].trophy
    //console.log(imageName)
    if (imageName.startsWith("/static/")) {
      return trophyButton
    }
    else {
      return imageName
    }

  }


  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">

        </ListSubheader>
      }
      className={classes.root}
    >

      {/*1 Kastrup Lufthavn*/}
      <ListItem
        //onClick={() => rowClicked(2)}
      >
        <ListItemIcon>
          <img alt="" src={kastrupTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Kastrup Lufthavn" />
        <img alt="" src={getTrophyImage(0)} width="40px" height= "40px"/>
      </ListItem>


      {/*2 Dagligstuen */}
      <ListItem
        //onClick={() => rowClicked(9)}
      >
        <ListItemIcon>
          <img alt="" src={dagligstuenTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Dagligstuen" />
        <img alt="" src={getTrophyImage(3)} width="40px" height= "40px"/>
      </ListItem>

      {/*2 Spisestuen */}
      <ListItem
        //onClick={() => rowClicked(9)}
      >
        <ListItemIcon>
          <img alt="" src={spisestuenTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Ved morgenbordet" />
        <img alt="" src={getTrophyImage(9)} width="40px" height= "40px"/>
      </ListItem>


      {/*3 Klasseværelset*/}
      <ListItem
        //onClick={() => rowClicked(11)}
      >
        <ListItemIcon>
          <img alt="" src={klassevaerelsetTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Klasseværelset" />
        <img alt="" src={getTrophyImage(5)} width="40px" height= "40px"/>
      </ListItem>
      {/*4 Skolegården */}
      <ListItem
        //onClick={() => rowClicked(10)}
      >
        <ListItemIcon>
          <img alt="" src={skolegardenImage} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Skolegården" />
      </ListItem>

      {/*5 Klasseværelset*/}
      <ListItem
        //onClick={() => rowClicked(11)}
      >
        <ListItemIcon>
          <img alt="" src={klassevaerelsetTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Klasseværelset" />
        <img alt="" src={getTrophyImage(4)} width="40px" height= "40px"/>
      </ListItem>

      {/* 6 cafe cult */}
      <ListItem
        //onClick={() => rowClicked(7)}
      >
        <ListItemIcon>
          <img alt="" src={cafeirisTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Café Cult" />
        <img alt="" src={getTrophyImage(1)} width="40px" height= "40px"/>
      </ListItem>

      {/*7 ledigt bord*/}
      <ListItem
        //onClick={() => rowClicked(8)}
      >
        <ListItemIcon>
          <img alt="" src={ledigtcafebordTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Ledigt Bord" />
        <img alt="" src={getTrophyImage(2)} width="40px" height= "40px"/>
      </ListItem>


      {/*8 Idas værelse */}
      <ListItem
        //onClick={() => rowClicked(17)}
      >
        <ListItemIcon>
          <img alt="" src={vaerelsetTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Idas værelse" />
        <img alt="" src={getTrophyImage(14)} width="40px" height= "40px"/>
      </ListItem>


      {/*9 Supertoj */}
      <ListItem
        //onClick={() => rowClicked(16)}
      >
        <ListItemIcon>
          <img alt="" src={superchikTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Supertøj" />
        <img alt="" src={getTrophyImage(6)} width="40px" height= "40px"/>
      </ListItem>



      {/*10 Idas værelse */}
      <ListItem
        //onClick={() => rowClicked(17)}
      >
        <ListItemIcon>
          <img alt="" src={vaerelsetTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Idas værelse" />
        <img alt="" src={getTrophyImage(13)} width="40px" height= "40px"/>
      </ListItem>

      {/*11 Kokkenet */}
      <ListItem
        //onClick={() => rowClicked(19)}
      >
        <ListItemIcon>
          <img alt="" src={kokkenetTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Køkkenet" />
        <img alt="" src={getTrophyImage(12)} width="40px" height= "40px"/>
      </ListItem>

      {/*12 Mad */}
      <ListItem
        //onClick={() => rowClicked(13)}
      >
        <ListItemIcon>
          <img alt="" src={spisgodtTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Vest Supermarked" />
        <img alt="" src={getTrophyImage(7)} width="40px" height= "40px"/>
      </ListItem>


      {/*13 Kokkenet */}
      <ListItem
        //onClick={() => rowClicked(19)}
      >
        <ListItemIcon>
          <img alt="" src={kokkenetTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Køkkenet" />
        <img alt="" src={getTrophyImage(11)} width="40px" height= "40px"/>
      </ListItem>

      {/*14 Mad */}
      <ListItem
        //onClick={() => rowClicked(13)}
      >
        <ListItemIcon>
          <img alt="" src={spisgodtTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Vest Supermarked" />
        <img alt="" src={getTrophyImage(8)} width="40px" height= "40px"/>
      </ListItem>


      {/*15 Kokkenet */}
      <ListItem
        //onClick={() => rowClicked(19)}
      >
        <ListItemIcon>
          <img alt="" src={kokkenetTrophy} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Køkkenet" />
        <img alt="" src={getTrophyImage(10)} width="40px" height= "40px"/>
      </ListItem>

</List>
  );
}
/*
{
<ListItem button
  onClick={() => rowClicked(1)}
>
  <ListItemIcon>
    <img alt="" src={myMap} width="40px" height= "40px"/>
  </ListItemIcon>
  <ListItemText primary="Kort" />
</ListItem>

      <ListItem button
        onClick={() => rowClicked(18)}
      >
        <ListItemIcon>
          <img alt="" src={spisestuenImage} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Spisestuen" />
      </ListItem>

      <ListItem button
        onClick={() => rowClicked(12)}
      >
        <ListItemIcon>
          <img alt="" src={nonFoodImage} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Non-food" />
      </ListItem>


      <ListItem button
        onClick={() => rowClicked(20)}
      >
        <ListItemIcon>
          <img alt="" src={automatImage} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Flaskeautomat" />
      </ListItem>

      <ListItem button
        onClick={() => rowClicked(14)}
      >
        <ListItemIcon>
          <img alt="" src={dulceImage} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Dulce e Caramba" />
      </ListItem>


      <ListItem button
        onClick={() => rowClicked(21)}
      >
        <ListItemIcon>
          <img alt="" src={kasseImage} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Kasse" />
      </ListItem>


      <ListItem button
        onClick={() => rowClicked(15)}
      >
        <ListItemIcon>
          <img alt="" src={værskoImage} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Værsko" />
      </ListItem>


      <ListItem button
        onClick={() => rowClicked(22)}
      >
        <ListItemIcon>
          <img alt="" src={proverumImage} width="40px" height= "40px"/>
        </ListItemIcon>
        <ListItemText primary="Prøverum" />
      </ListItem>

*/
