import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//const useStyles = makeStyles(theme => ({
//}));

export default function MyButton(props) {
  //const classes = useStyles();

  const changeScene = event => {
    //console.log("changing scene from RoundedButton")
    //console.log(event.currentTarget.id)
    props.changeScene(event.currentTarget.id);
  }

  return (
    <div>

    <button
      id={props.buttonId}
      key={props.buttonId}
      type="button"
      className={`btn btn-lg ${props.btnClass}`}
      onClick={(e) => {
       changeScene(e)
      }}
      style={{
        marginLeft:15,
        marginBottom:10,
        color:"white",
        fontSize: "25px",
      }}
    >
      {props.buttonName}
    </button>

    </div>
  );
}
