import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';

import '../css/myCss.css'
import axios from 'axios';
import * as actionTypes from '../store/actions/actionTypes';
import RoundedButton from './RoundedButton'
import BottomSlider from './BottomSlider'

class SceneClass extends React.Component {

  constructor(props) {
     super(props);
     this.child = React.createRef();
   }

   emptyStartText = () => {
    this.child.current.emptyStartText()
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  UNSAFE_componentWillReceiveProps(newProps) {
  }

  updateTrophies = (update, show, trophytext) => {
    //console.log("updateTrophies2")
    this.props.updateTrophies(update, show, trophytext)
  }

  finishedGame = () => {
    //console.log("finishedGame2")
    this.props.finishedGame()
  }

  updatePinAndTrophies = () => {
    //console.log("updatePinAndTrophies after finishing dagligstuen")
    this.props.updatePinAndTrophies()
  }

  getSceneNameFromId = (scene) => {
     for (var s in this.props.sceneDict) {
       let id = this.props.sceneDict[s].id
       if (id === scene) {
         return this.props.sceneDict[s].scene
       }
     }
   }

  sendLeftKlassevaerelset = (sceneId) => {
    //let sceneId = this.props.currentScene.id
    axios.defaults.headers = {
     "Accept": "application/json",
     "Content-Type": "application/json",
     'Authorization': "Token " + this.props.token
    }

    axios.get(actionTypes.API_URL + 'api/conversation?id=' + sceneId + '&text=bla').then(res => {
       //console.log("result sendLeftKlassevaerelset:")
       //console.log(res.data)
     })
  }

  changeScene = sceneId => {
    this.props.changeScene(sceneId);
  }


   noNeedForBeginningText = () => {
     this.props.setBeginningText("")
   }

   renderIfHasPinAndHiddenScenes() {
       if(this.props.currentScene.hiddenScenes) {
         return(
           <div>
           {
             this.props.currentScene.hiddenScenes.map(item =>(
               <RoundedButton
                 key={item}
                 buttonName={this.getSceneNameFromId(item)}
                 buttonId={item}
                 changeScene={this.changeScene}
                 btnClass= "btn-info"
               />
             ))
           }
           </div>
         )
       }
   }

  render() {
    //console.log("propsScene:")
    //console.log(this.props)
    return (
     <div style={{
       width:"100%",
       height:"100%",
     }}>

         <div
         style={{
           width:"100%",
           height:"100%",
         }}
         >

           <div
           style={{
             width:"100%",
             height:"100%",
             backgroundImage: `url(${this.props.currentScene.image})`,
             backgroundRepeat: "no-repeat",
             backgroundSize: "100% 100%",
           }}
           >
             <div className="top-part-25"
             style={{
               textAlign: "left",
             }}
             >
               {this.props.currentScene.navigateTo.map(item => (
                   <RoundedButton
                     key={item}
                     buttonName={this.getSceneNameFromId(item)}
                     buttonId={item}
                     changeScene={this.changeScene}
                     btnClass= "btn-info"
                   />
               ))}

               {this.renderIfHasPinAndHiddenScenes()}
             </div>

             <div style={{
             }}
             >

             {
               this.props.currentScene.conversation ?

                 <BottomSlider
                     dictionaryCountry={this.props.dictionaryCountry}
                     lastSentUserText={this.props.lastSentUserText}
                     setLastSentUserText={this.props.setLastSentUserText}
                     props={this.props}
                     beginningText={this.props.beginningText}
                     updateTrophies={this.updateTrophies}
                     updatePinAndTrophies={this.updatePinAndTrophies}
                     noNeedForBeginningText={this.noNeedForBeginningText}
                     setComputerAnswer={this.props.setComputerAnswer}
                     computerAnswer={this.props.computerAnswer}
                     finishedGame={this.finishedGame}
                     showTip={this.props.showTip}
                     showDictionary={this.props.showDictionary}
                     hideTip={this.props.hideTip}
                     currentScene={this.props.currentScene}
                     tipForQuestion={this.props.tipForQuestion}
         						 setTipForQuestion={this.props.setTipForQuestion}
                     hideTextBox={this.props.hideTextBox}
                     hideTextBoxState={this.props.hideTextBoxState}
                   />
                  :
                  null
                }

               </div>
            </div>
         </div>
     </div>
      );

  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null,
    token: state.token === null ? null : state.token,
    hasPin: state.hasPin,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SceneClass);
