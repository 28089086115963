import React from 'react';
import talerumImage from '../img/talerumImage.png'

export default function TalerumTopImage() {
    return (
      <div
        style={{
          width: "400px",
          height: "200px",
          backgroundImage: `url(${talerumImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          left: "auto",
        }}
        >
      </div>
    );
}

/*
width:"250px",
height:"100px",
*/
