import React from 'react';

import Button from '@material-ui/core/Button';
//import teacherButton from '../img/teacher4.png'
import iconTeacher from '../img/icons/iconsButtons/teacher2.png'

export default function MenuLoginScreenRightPart(props) {

  const toOmTalerum = () => {
    props.toOmTalerum()
  }

  return (
    <div
    style={{
      backgroundColor: "clear",
      height:"100%",
      margin:"20",
      padding:"0",
    }}
    >


    <Button
    onClick={toOmTalerum}
    style={{
      float:"right",
    }}
    >
      <img alt="" src={iconTeacher} width="40px" height= "40px"/>
    </Button>


    </div>
  );
}
