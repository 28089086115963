import React from 'react';
import { Form, Icon, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';

const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class NormalLoginForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //console.log("login:")
        //console.log(values)
        this.props.onAuth(values.userName, values.password);
      }
    });
  }

  render() {
    let errorMessage = null;
    if (this.props.error) {
      if (this.props.error.response) {
          //console.log("data")
          //console.log(this.props.error.response.data);
          //console.log("items:")
          let msg = ""
          for (var key in this.props.error.response.data) {
              //console.log(this.props.error.response.data[key])
              msg = msg + this.props.error.response.data[key]
          }
          //console.log("msg")
          //console.log(msg)
          errorMessage = (
              <p>{msg}</p>
          );
      }
      else {
        errorMessage = (
            <p>{this.props.error.message}</p>
        );
      }

    }

    const { getFieldDecorator } = this.props.form;
    return (
      <div
      style={{
        width:"100%",
        height:"100%",
        position:"relative",
      }}
      >

        <div
        style={{
          width:"100%",
          height:"100%",
          position:"relative",
        }}
        >
            {errorMessage}
            {
                this.props.loading ?

                <Spin indicator={antIcon} />

                :

              <div
              style={{
                width:"50%",
                position: 'absolute', left: '50%',
                transform: 'translate(-50%)'
              }}
              >

              {/*<TalerumTopImage/>*/ }
              <br/>
              <br/>
              <h1>Kom i gang</h1>
              <br/>

                <Form onSubmit={this.handleSubmit} className="login-form"

                  style={{
                  }}
                >


                    <FormItem>
                    {getFieldDecorator('userName', {
                        rules: [{ required: true, message: 'Please input your username!' }],
                    })(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
                    )}
                    </FormItem>

                    <FormItem>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input your Password!' }],
                    })(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                    )}
                    </FormItem>

                    <FormItem className="horizontal-center3"
                    style={{

                    }}
                    >

                    <button
                      type="button"
                      style={{
                        marginRight: 15,
                        background: "#eaca79",
                        color: "#384151",
                      }}
                      onClick={this.props.toggleShowLogin}
                      className="btn"
                    >
                      Luk
                    </button>

                    <button
                      type="submit"
                      className="btn"
                      style={{
                        background: "#384151",
                        color: "white",
                      }}
                    >
                      Log ind
                    </button>

                    <br/>

                    </FormItem>

                </Form>

                <br/>
                <br/>
                <br/>

                <button
                  style={{
                    background: "#dd5d5a",
                    color: "white",
                  }}
                  type="button"
                  onClick={this.props.toggleShowSignup}
                  className="btn"
                >
                  Ny bruger
                </button>

                <br/>
                <br/>

                <button
                    style={{
                      background: "#384151",
                      color: "white",
                    }}
                    type="button"
                    onClick={this.props.toggleShowForgotPassword}
                    className="btn"
                >
                Glemt adgangskode
                </button>

              </div>

            }

            </div>


        </div>
    );
  }
}

/*
Colour: #384151 yellow
Colour: #dd5d5a red
Colour: #eaca79 blue
*/
const WrappedNormalLoginForm = Form.create()(NormalLoginForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password) => dispatch(actions.authLogin(username, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm);
