import React from 'react';
import { useState, forwardRef, useImperativeHandle } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import reactTriggerChange from 'react-trigger-change'

const BootstrapInput = withStyles(theme => ({

  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },


  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));




////

function CustomizedSelects(props, inputRef) {
  const classes = useStyles();
  const [position, setPosition] = useState(0);

  let last = ""
  //let position = 0

  const updateText = event => {
    //console.log("updated value")
    //let s = event.target.selectionStart
    let e = event.target.selectionEnd
    setPosition(e)
    //console.log("start end currentPosition:")
    //console.log(props.currentPosition)
    //console.log(s)
    //console.log(e)
    //console.log("lastCharPressed")
    //console.log(last)
    if(last !== "") {

      let t1 = event.target.value.substr(0, position)
      let t2 = event.target.value.substr(position, event.target.value.length)
      let n = t1 + last + t2
      //console.log("new text")
      //console.log(n)
      //console.log("t1 t2")
      //console.log(t1)
      //console.log(t2)

      setPosition(n.length)


      //let newP = position + 1
      // event.target.selectionEnd = newP
      // event.target.selectionStart = newP
      // event.target.setSelectionRange(newP, newP)
      //setPosition(newP)

      props.labelChanged(n)
      //event.target.setSelectionRange(s, e)

      clearLastCharPressedFunction()
      //rerender()


    }
    else {
      //console.log(event.target.value)
      //props.handleSearchTextChange(event.target.value);
      //console.log("changing label text:")
      //console.log(event.target.value)
      //console.log("cursor position:")

      props.labelChanged(event.target.value)

    }



    //this.refs.input.selectionStart = this.refs.input.selectionEnd = start + 1

    //event.target.selectionEnd = event.target.selectionEnd + 1
    //event.target.selectionStart = event.target.selectionStart + 1

    //console.log("currentPosition props:")
    //console.log(props.currentPosition)
  }

  const clearLastCharPressedFunction = () => {
    last = ""
  }

  /*
  const setPosition = (p) => {
    position = p
  }*/

  const handleMouseClick = (e) => {
    //console.log("mouse clicked")
    //this.inputElement.click();
    let end = e.target.selectionEnd
    //let start = e.target.selectionStart
    //console.log("start end is:")
    //console.log(start)
    //console.log(end)
    setPosition(end)
  }

  const onkeydown = e => {
    //console.log("arrow key")
    //console.log(e.key)
    //let cursorStart = e.target.selectionStart
    //let cursorEnd = e.target.selectionEnd
    if(e.key === 'ArrowLeft') {
      //e.target.selectionEnd = props.currentPosition-1
      //e.target.selectionStart = props.currentPosition-1
      //console.log("end, start")
      //console.log(e.target.selectionEnd)
      //console.log(e.target.selectionStart)

      if(position !== 0) {
        //e.target.selectionEnd = e.target.selectionEnd - 1
        //e.target.selectionStart = e.target.selectionStart - 1
        //e.target.setSelectionRange(e.target.selectionEnd, e.target.selectionEnd)
        //console.log("left1")
        //console.log(e.target.selectionStart)
        //console.log(e.target.selectionEnd)
        let newP = position-1
        //console.log("newP")
        //console.log(newP)
        //e.target.setSelectionRange(cursorStart, cursorEnd)
        setPosition(newP)

      }

    }
    else if(e.key === 'ArrowRight') {
      let lengd = e.target.value.length
      //console.log("lengd0")
      //console.log(lengd)
      //console.log(e.target.selectionEnd)
      if(position !== lengd) {
        //e.target.selectionEnd = e.target.selectionEnd + 1
        //e.target.selectionStart = e.target.selectionStart + 1
        //e.target.setSelectionRange(e.target.selectionEnd, e.target.selectionEnd)

        //console.log("right1")
        //console.log(e.target.selectionStart)
        //console.log(e.target.selectionEnd)
        let newP = position+1
        //console.log("newP")
        //console.log(newP)

        setPosition(newP)
      }

      //e.target.selectionEnd = props.currentPosition+1
      //e.target.selectionStart = props.currentPosition+1
      //console.log("end, start")
      //console.log(e.target.selectionEnd)
      //console.log(e.target.selectionStart)
      //setPosition(props.currentPosition+1)

    }


  }



  useImperativeHandle(inputRef, () => ({
    addCharToInput(char) {
      //console.log("called child method1")
      //console.log("addChar inside input")
      //console.log(char)
      last = char
      const input = document.querySelector('#inputBox')
      //let event = new Event('change', { bubbles: true });
      //let s = event.target.selectionStart
      //let e = event.target.selectionEnd
      //console.log("start, end inputRef: ")
      //console.log(s)
      //console.log(e)

      //console.log("before trigger")
      reactTriggerChange(input);
      //console.log("after trigger")
      //input.dispatchEvent(event);

    }
  }));


  function rerender() {

return(
<div
  style={{

        width:"100%",
        justifyContent: "center",
        }}
>

<form className={classes.root} autoComplete="off">
    <InputLabel
    ref={input => input && input.focus()}
    htmlFor="inputBox"
    id="bla"
    onChange={updateText}

    style=
    {{
      width:"100%",
      marginTop:"0",
      justifyContent: "center"

    }}

    >

    </InputLabel>


    <BootstrapInput
      id="inputBox"
      onChange={updateText}

      placeholder={props.placeholderName}
      style={{
        width:"100%",
        marginTop:"0",
        justifyContent: "center"
      }}
      value={props.currentText}
      onKeyPress={(e) => submitInput(e, props)}
      onClick={handleMouseClick}
      onKeyDown={(e) => onkeydown(e)}
    />

</form>

</div>)


  }

  //setInterval(rerender, 1000);


  const submitInput = e => {
    if(e.key === 'Enter') {
      e.preventDefault();
      //console.log("going to submit")
      props.submitInput()
    }
    //console.log("onkeyPress")
    //console.log(e.key)

  }


  return (
    rerender()
  );
}
export default forwardRef(CustomizedSelects);
