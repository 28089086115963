import React from 'react';

import Button from '@material-ui/core/Button';
//import studentButton from '../img/studentIcon2.png'
//import profileButton from '../img/profileIconWhite2.png'
import iconStudent from '../img/icons/iconsButtons/student2.png'


export default function MenuLoginScreenLeftPart(props) {

  const toInfo = () => {
    props.toInfo()
  }

  return (
    <div
    style={{
      backgroundColor: "clear",
      height:"100%",
      margin:"20",
      padding:"0",
    }}
    >
{/*
    <Button
    onClick={toLogin}
    style={{
      float:"left",
    }}
    >
      <img alt="" src={studentButton} width="40px" height= "40px"/>
    </Button>
*/}
    <Button
    onClick={toInfo}
    style={{
      float:"left",
    }}
    >
      <img alt="" src={iconStudent} width="40px" height= "40px"/>
    </Button>


    </div>
  );
}
